import {NetworkManager, API} from "network/core";

export const useIncludedGroupModel = () => {
    const createNewIncludedGroup = async (body) => {
        const instance = NetworkManager(API.INCLUDED_GROUPS.POST);
        const response = await instance.request(body);
        return response;
    };
    const getSingleIncludedGroup = async (body) => {
        const instance = NetworkManager(API.INCLUDED_GROUPS.GETBYITEMID(body));
        const response = await instance.request();
        return response;
    };
    const updateIncludedGroup = async (id, body) => {
        const instance = NetworkManager(API.INCLUDED_GROUPS.PUT(id));
        const response = await instance.request(body);
        return response;
    };
    return {
        createNewIncludedGroup,
        getSingleIncludedGroup,
        updateIncludedGroup
    };
};
