import {Box, Grid, Stack, Link, InputAdornment, Switch} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "commonComponent/BlackTooltip";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {useSearchParams, useNavigate} from "react-router-dom";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import React, {useEffect, useState} from "react";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import Typography from "@mui/material/Typography";
import {CreateOrderQuoteValidaion} from "utils/validations/validations";
import Loader from "ui-component/Loader";
import styles from "./payment.module.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Visa from "assets/images/card/visa.svg";
import Master from "assets/images/card/master.svg";
import Discover from "assets/images/card/discover.svg";
import American from "assets/images/card/american.svg";
import PayPal from "assets/images/card/PayPal.svg";
import ApplePay from "assets/images/card/ApplePay.svg";
import Other from "assets/images/card/other.svg";

import AddNewCardForm from "./NewCard";
import AddNewHouseCardForm from "./NewHouseCard";
import StorageManager from "storage/StorageManager";
import {FormatCurrency, checkforPerfectNumber, validateInputForPercentage} from "utils/helperFunctions/helpers";
import useToggleDilog from "hooks/useToggleDilog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {setCustomerPaymentProfileId} from "store/slices/card";
import {height} from "@mui/system";

const formSchema = {
    paymentMethod: "Credit Card",
    creditCard: "",
    houseAccount: "",
    deposit: "",
    depositEnable: false
};

const PaymentForm = (props) => {
    const {
        handleSelectedRow,
        customerId,
        orderId,
        name,
        company,
        Parentformik,
        handleReset,
        extraAmount,
        refundAmount,
        chargedBy,
        orderStatus,
        paidAlready,
        customerCardId,
        maininitialValues,
        remainingAmountToPaid,
        saveAdvanceDeposit,
        setCartName,
        orderPlaced,
        setOrderPlaced,
        customerPaymentProfileId,
        setIsPaymentButtonDisabled
    } = props;
    const [newCardFlag, setNewCardFlag] = useState(false);
    const [newHouseCardFlag, setNewHouseCardFlag] = useState(false);
    const [searchparam] = useSearchParams();
    const navigate = useNavigate();
    const {toggleDrawer} = useTempDrawer();
    const {toggleDilog} = useToggleDilog();
    const [initialValues, setInitialValues] = React.useState(formSchema);
    const userID = customerId;
    const [searchParams] = useSearchParams();

    const OrderId = searchParams.get("orderId");

    const [cardList, setCardList] = React.useState([]);
    const [houseAccount, setHouseAccount] = React.useState([]);
    const [customerCardIdNew, setCustomerCardId] = useState("");

    const [cardListArr, setCardListArr] = React.useState([]);
    const orderItemPrice = StorageManager.get("orderItemPrice") || null;
    const serviceTax = StorageManager.get("ServiceTax") || null;
    const preferredPrice = StorageManager.get("preferredPrice") || null;
    const salestax = StorageManager.get("salesTax") || null;
    const orderTotal = StorageManager.get("orderTotal") || null;
    const perPrice1 = StorageManager.get("orderPrice1") || null;
    const perPrice2 = StorageManager.get("orderPrice2") || null;
    const priceType = StorageManager.get("priceType") || null;
    const discountPrice = StorageManager.get("discountPrice") || null;
    const discountTypeValue = StorageManager.get("discountTypeValue") || null;

    const getHouseAccountById = async () => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(userID));
        const response = await instance.request();
        if (response.success) {
            if (response.data && response.data?.is_active) {
                setHouseAccount(response?.data);
            }
            setCustomerCardId(response?.data?.customer_card_id);
        }
    };
    const getCardsById = async () => {
        const instance = NetworkManager(API.CARD.LISTING(userID));
        const response = await instance.request();
        if (response.success) {
            const cardsList = response.data?.profile?.paymentProfiles;
            if (cardsList) {
                setCardList([...cardsList]);
            }
        }
    };

    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(null);

    const cardLogo = (cardType) => {
        switch (cardType) {
            case "Visa":
                return Visa;
            case "AmericanExpress":
                return American;
            case "Discover":
                return Discover;
            case "MasterCard":
                return Master;
            case "PayPal":
                return PayPal;
            case "ApplePay":
                return ApplePay;
            // case "DinersClub":
            //     return Discover;
            default:
                return Other;
        }
    };

    const dispatch = useDispatch();
    const handleMultiCards = () => {
        const cardListArr1 = [];
        const processedIds = new Set();
        cardList.forEach((item) => {
            const cardId = item.customerPaymentProfileId;
            const isSelected = Parentformik.values.customerCardId === cardId;

            cardListArr1.push({
                defaultCard: item.defaultPaymentProfile,
                id: cardId,
                label: (
                    <div style={{display: "flex", alignItems: "center", gap: "2px"}}>
                        <img
                            src={cardLogo(item?.payment?.creditCard?.cardType)}
                            style={{
                                width: 60,
                                height: 33.508,
                                objectFit: "cover",
                                marginRight: 5
                            }}
                            alt={item?.payment?.creditCard?.cardType}
                        />
                        <div>
                            <div>{item?.billTo?.firstName}</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div>••••</div>
                                <div>{item?.payment?.creditCard?.cardNumber.slice(-4)}</div>
                            </div>
                        </div>
                    </div>
                )
            });
            // If the customerPaymentProfileId is already processed, skip the rest of the logic
            if (processedIds.has(customerPaymentProfileId)) return;

            if (customerPaymentProfileId === item.customerPaymentProfileId) {
                setInitialValues({
                    paymentMethod: "Credit Card",
                    creditCard: item.customerPaymentProfileId,
                    houseAccount: ""
                });
                processedIds.add(customerPaymentProfileId);
                return;
            }

            if (item.defaultPaymentProfile && !processedIds.has(customerPaymentProfileId)) {
                setInitialValues({
                    paymentMethod: "Credit Card",
                    creditCard: item.customerPaymentProfileId,
                    houseAccount: ""
                });
            } else if (isSelected && !processedIds.has(customerPaymentProfileId)) {
                setInitialValues({
                    paymentMethod: "Credit Card",
                    creditCard: cardId,
                    houseAccount: ""
                });
            }
        });
        setCardListArr([...cardListArr1]);
    };

    useEffect(() => {
        if (orderStatus === "Accepted" || orderStatus === "Active" || orderStatus === "Driver assigned" || orderStatus === "ON_THE_WAY") {
            const schema = {
                houseAccount: "",
                creditCard: "",
                paymentMethod: "Credit Card"
            };
            if (chargedBy === "HOUSE_ACCOUNT") {
                schema.paymentMethod = "House Account";
            } else if (chargedBy === "CREDIT_CARD") {
                schema.paymentMethod = "Credit Card";
                schema.creditCard = customerCardId;
            }
            setInitialValues(schema);
        }
    }, [orderStatus]);

    const handleBackOrder = async () => {
        toggleDilog(false)();
        Parentformik.resetForm({values: maininitialValues});
        handleReset();
        dispatch({
            type: "RemoveItem"
        });
        dispatch({
            type: "ORDER"
        });
    };

    const verifyCutOff = () => {
        return new Promise((resolve, reject) => {
            const instance = NetworkManager(API.ORDER.CUTOFFVERIFY);

            const payload = {
                delivery_date: Parentformik.values.headerData.when,
                latitude: Parentformik.values.headerData.latitude,
                longitude: Parentformik.values.headerData.longitude,
                brand_id: Parentformik.values.selectedBrand
            };
            resolve(instance.request(payload));

            // if (response.success) {
            //     resolve(response)
        });
    };

    const handlePlaceOrder = async (values, setSubmitting, e) => {
        setSubmitting(true);
        setLoading(true);

        const instance = NetworkManager(API.ORDER.PLACEORDER);

        const payload =
            values.paymentMethod === "House Account"
                ? {
                      orderId: orderId.order_id,
                      customerId: customerId,
                      isHouseAccount: values.paymentMethod !== "Credit Card",
                      url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
                      is_advance_deposit_enabled: !!values?.depositEnable
                  }
                : {
                      orderId: orderId.order_id,
                      customerId: customerId,
                      customerPaymentProfileId: values.creditCard,
                      isHouseAccount: values.paymentMethod !== "Credit Card",
                      url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
                      is_advance_deposit_enabled: !!values?.depositEnable
                  };

        if (values?.depositEnable) {
            payload.advance_deposit_percent = values.deposit;
        }

        const response = await instance.request(payload);
        if (response.success) {
            setCartName("");
            setOrderPlaced(true); // This will set orderPlaced when the order is successfully placed
            dispatch(setCustomerPaymentProfileId(""));

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            // if (values.depositEnable) {
            //     saveAdvanceDeposit(Parentformik.values, null, null, values)
            // }
            if (e) {
                toggleDrawer("right", false)(e);
                handleBackOrder();
                setTimeout(() => navigate("/orders?tab=scheduled"), 100);
            }
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    const handleSubmitOnEdit = async (values, setSubmitting, e) => {
        const instance = NetworkManager(API.ORDER.ORDERBYCARD);
        const payload = {
            orderId: orderId.order_id,
            customerId: customerId,
            customerPaymentProfileId: values.creditCard
        };

        const response = await instance.request(payload);
        if (response.success) {
            setCartName("");
            setOrderPlaced(true);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            if (e) {
                toggleDrawer("right", false)(e);
                handleBackOrder();
                setTimeout(() => navigate("/orders?tab=scheduled"), 100);
            }
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSubmitting(false);
        setLoading(false);
    };

    useEffect(() => {
        getCardsById();
        getHouseAccountById();
    }, [newCardFlag, newHouseCardFlag]);

    useEffect(() => {
        getCardsById();
        getHouseAccountById();
    }, []);

    useEffect(() => {
        handleMultiCards();
    }, [cardList]);

    const [isActive, setIsActive] = useState(false);
    const customerHouseAccountActivate = async () => {
        const payload = {
            customer_card_id: customerCardIdNew
        };
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTACTIVE);
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            // Fetch updated house account details
            await getHouseAccountById();
        } else {
            let errorMessage = response.message;

            if (errorMessage === '"customer_card_id" is required') {
                errorMessage = "Customer Card ID is required.";
            }

            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const deactivateHouseAccount = async () => {
        const payload = {
            customer_card_id: customerCardIdNew
        };
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDEACTIVE);
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
        } else {
            let errorMessage = response.message;

            if (errorMessage === '"customer_card_id" is required') {
                errorMessage = "Customer Card ID is required.";
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: errorMessage,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };
    const toggleHouseAccount = async (isChecked) => {
        const action = isChecked ? customerHouseAccountActivate : deactivateHouseAccount;
        const response = await action(customerCardId);

        if (response.success) {
            setIsActive(isChecked);
        } else {
            setIsActive(!isChecked);
        }
    };

    const handleSwitchChange = async (event) => {
        const isChecked = event.target.checked;
        setIsActive(isChecked);
        await toggleHouseAccount(isChecked);
    };

    const depositPercentage = (deposit) => {
        if (deposit === "" || Number(deposit) === 0) {
            return 0;
        } else if (Number(deposit) > 0) {
            return orderTotal * (Number(deposit) / 100);
        }
        return 0;
    };

    const placeOrderPrice = (deposit) => {
        return Number(orderTotal) - Number(depositPercentage(deposit));
    };

    const TooltipComponent = () => (
        <Grid container fontSize="12px !important">
            <Grid item xs={8} mb={0}>
                <Typography variant="subtitlebb">Order items</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign: "right"}}>
                <Typography variant="subtitlebb">{FormatCurrency(orderItemPrice)}</Typography>
            </Grid>
            <Grid item xs={8} mb={0} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Stack>
                    <Typography variant="subtitlebb">
                        Service fee{" "}
                        {Parentformik.values.serviceFees?.value && Parentformik.values.serviceFees?.unitType === "percent"
                            ? Math.floor(Number(Parentformik.values.serviceFees?.value))
                            : ""}
                        {Parentformik.values.serviceFees?.unitType === "percent" && "%"}
                    </Typography>
                </Stack>
                <Stack ml={0.8}>
                    <Tooltip
                        placement="top"
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: "#36454F !important",
                                    color: "#fff !important",
                                    boxShadow: 1
                                }
                            },
                            arrow: {
                                sx: {
                                    color: "#36454F !important",
                                    border: "none !important"
                                }
                            }
                        }}
                        title="Includes plates, napkins, eating and serving utensils, delivery, setup and gratuity."
                    >
                        <InfoOutlinedIcon sx={{width: "12px", height: "12px", marginTop: "3px", color: "#00ab3a"}} />
                    </Tooltip>
                    {/* <InfoOutlinedIcon sx={{ width: "12px", height: "12px", color: "#00ab3a" }} /> */}
                </Stack>
            </Grid>
            <Grid item xs={4} sx={{textAlign: "right"}}>
                <Typography variant="subtitlebb">{FormatCurrency(serviceTax)}</Typography>
            </Grid>
            {preferredPrice && Number(preferredPrice) > 0 && (
                <Grid item xs={8} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Stack>
                        <Typography variant="subtitlebb">
                            Preferred pricing{" "}
                            {Parentformik.values.headerData?.preferredDiscountValue &&
                            Parentformik.values.headerData?.preferredDiscountType === "PERCENTAGE"
                                ? Math.floor(Number(Parentformik.values.headerData?.preferredDiscountValue))
                                : ""}
                            {Parentformik.values.headerData?.preferredDiscountType === "PERCENTAGE" && "%"}
                        </Typography>
                    </Stack>
                    <Stack ml={0.8} mt={0.3}>
                        <Tooltip
                            placement="top"
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: "#36454F !important",
                                        color: "#fff !important",
                                        boxShadow: 1
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: "#36454F !important",
                                        border: "none !important"
                                    }
                                }
                            }}
                            title="Large, regular, or recurring orders may qualify for special pricing."
                        >
                            <InfoOutlinedIcon
                                sx={{
                                    width: "12px",
                                    height: "12px",
                                    color: "#00ab3a"
                                }}
                            />
                        </Tooltip>
                    </Stack>
                </Grid>
            )}
            {preferredPrice && Number(preferredPrice) > 0 && (
                <Grid item xs={4} sx={{textAlign: "right"}}>
                    <Typography variant="subtitlebb">{`-${FormatCurrency(preferredPrice)}`}</Typography>
                </Grid>
            )}

            {discountPrice && Number(discountPrice) > 0 && (
                <Grid item xs={8}>
                    <Typography variant="subtitlebb">
                        {priceType}
                        {discountTypeValue && ` ${discountTypeValue}%`}
                    </Typography>
                </Grid>
            )}
            {discountPrice && Number(discountPrice) > 0 && (
                <Grid item xs={4} sx={{textAlign: "right"}}>
                    <Typography variant="subtitlebb">
                        {priceType === "Fee" ? `${FormatCurrency(discountPrice)}` : `-${FormatCurrency(discountPrice)}`}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={8} mb={0.5}>
                <Typography variant="subtitlebb">Sales tax {checkforPerfectNumber(Parentformik.values.salesTax.value || "")}%</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign: "right"}}>
                <Typography variant="subtitlebb">{FormatCurrency(salestax)}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={CreateOrderQuoteValidaion}
                onSubmit={(val, {setSubmitting}) => {
                    if (
                        OrderId &&
                        !searchParams.get("reOrder") &&
                        (Parentformik?.values?.orderStatus === "Active" ||
                            Parentformik?.values?.orderStatus === "Accepted" ||
                            Parentformik?.values?.orderStatus === "ON_THE_WAY" ||
                            Parentformik?.values?.orderStatus === "Driver assigned")
                    ) {
                        handleSubmitOnEdit(val, setSubmitting, submitEvent);
                    } else {
                        handlePlaceOrder(val, setSubmitting, submitEvent);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideBarStateName="Payment"
                            sx={{zIndex: 1500, maxWidth: "430px"}}
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            touched={formik.touched}
                        >
                            <Stack sx={{height: "100%"}} justifyContent="flex-start">
                                {!newCardFlag && !newHouseCardFlag && (
                                    <Grid containter>
                                        {OrderId &&
                                        !searchParams.get("reOrder") &&
                                        (Parentformik?.values?.orderStatus === "Active" ||
                                            Parentformik?.values?.orderStatus === "Accepted" ||
                                            Parentformik?.values?.orderStatus === "ON_THE_WAY" ||
                                            Parentformik?.values?.orderStatus === "Driver assigned") ? (
                                            <Typography fontSize="1.625rem" color="#000000" fontWeight={700}>
                                                Update
                                            </Typography>
                                        ) : (
                                            <Typography fontSize="1.625rem" color="#000000" fontWeight={700}>
                                                Checkout
                                            </Typography>
                                        )}
                                        <Grid item mt={3} xs={12}>
                                            <Typography fontSize="0.9375rem" color="#26272A" fontWeight={700}>
                                                Payment method
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {!newCardFlag && !newHouseCardFlag && (
                                    <Box mt={1} sx={{height: "100%"}}>
                                        <Grid container>
                                            <Grid item xs={12} mb={1.2}>
                                                <CustomSelect
                                                    noNone
                                                    disabled={
                                                        OrderId &&
                                                        !searchParams.get("reOrder") &&
                                                        (Parentformik?.values?.orderStatus === "Active" ||
                                                            Parentformik?.values?.orderStatus === "Accepted" ||
                                                            Parentformik?.values?.orderStatus === "ON_THE_WAY" ||
                                                            Parentformik?.values?.orderStatus === "Driver assigned")
                                                    }
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 200
                                                            }
                                                        }
                                                    }}
                                                    fullWidth
                                                    menuItems={
                                                        Object.keys(houseAccount).length > 0
                                                            ? [
                                                                  {
                                                                      label: "Credit card",
                                                                      id: "Credit Card"
                                                                  },
                                                                  {
                                                                      label: "House account",
                                                                      id: "House Account"
                                                                  }
                                                              ]
                                                            : [
                                                                  {
                                                                      label: "Credit card",
                                                                      id: "Credit Card"
                                                                  }
                                                              ]
                                                    }
                                                    {...formik.getFieldProps("paymentMethod")}
                                                />
                                            </Grid>

                                            {formik.values.paymentMethod === "Credit Card" && cardListArr.length > 0 ? (
                                                <Grid item xs={12} mt={1} mb={2}>
                                                    <CustomSelect
                                                        noNone
                                                        numberValidation
                                                        label="Credit card"
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    maxHeight: 200,
                                                                    padding: "10.5px 14px"
                                                                }
                                                            }
                                                        }}
                                                        sx={{
                                                            "& .MuiInputBase-input": {
                                                                padding: "14.5px 14px"
                                                            }
                                                        }}
                                                        fontStyles={{height: "55px"}}
                                                        fullWidth
                                                        menuItems={[...cardListArr]}
                                                        {...formik.getFieldProps("creditCard")}
                                                    />
                                                </Grid>
                                            ) : (
                                                ""
                                            )}
                                            <ErrorMessage name="creditCard" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            {formik.values.paymentMethod === "Credit Card" ? (
                                                <Grid item xs={12} sx={{height: "1.87rem"}}>
                                                    <Button
                                                        className={styles.btn}
                                                        startIcon={<AddCircleIcon style={{fontSize: "1rem"}} />}
                                                        onClick={() => setNewCardFlag(!newCardFlag)}
                                                    >
                                                        Add new card
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                ""
                                            )}
                                            {houseAccount && !houseAccount?.is_active && customerCardIdNew ? (
                                                <Grid item xs={12}>
                                                    <Grid container sx={{alignItems: "center"}}>
                                                        <Grid item>
                                                            <Typography className={styles.btn} sx={{paddingLeft: "9px"}}>
                                                                Active house account
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Switch
                                                                color="primary"
                                                                width="36px"
                                                                checked={isActive}
                                                                onChange={handleSwitchChange}
                                                                name="sdm"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ) : (!OrderId || searchParams.get("reOrder")) &&
                                              Object.keys(houseAccount).length === 0 &&
                                              Parentformik?.values?.orderStatus !== "Accepted" &&
                                              Parentformik?.values?.orderStatus !== "ON_THE_WAY" &&
                                              Parentformik?.values?.orderStatus !== "Driver assigned" ? (
                                                <Grid item xs={12} sx={{height: "1.87rem"}} mt={0.3}>
                                                    <Button
                                                        className={styles.btn}
                                                        startIcon={<AddCircleIcon style={{fontSize: "1rem"}} />}
                                                        onClick={() => setNewHouseCardFlag(!newHouseCardFlag)}
                                                    >
                                                        Create house account
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                ""
                                            )}

                                            <ErrorMessage name="houseAccount" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            {formik.values.paymentMethod === "Credit Card" && (
                                                <Grid item mt={1} ml={0.4}>
                                                    <Typography className={styles.mm12}>
                                                        We will only use this card for authorization and saving, but not for transaction.
                                                        Transaction will be initiated when the order is delivered or cancellation charges if
                                                        applicable.
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {OrderId &&
                                            !searchParams.get("reOrder") &&
                                            (Parentformik?.values?.orderStatus === "Active" ||
                                                Parentformik?.values?.orderStatus === "Accepted" ||
                                                Parentformik?.values?.orderStatus === "ON_THE_WAY" ||
                                                Parentformik?.values?.orderStatus === "Driver assigned") ? null : (
                                                <Grid container mt={2}>
                                                    <Grid item xs={12} mt={1} mb={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                                        >
                                                            <Typography fontSize="0.9375rem" color="#26272A" fontWeight={700}>
                                                                Deposit
                                                            </Typography>
                                                            <CustomSwitch
                                                                checked={formik.values.depositEnable}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("depositEnable", e.target.checked);
                                                                    formik.setFieldValue("deposit", "");
                                                                }}
                                                            />
                                                        </Grid>
                                                        {formik.values.depositEnable && (
                                                            <>
                                                                <CustomTextField
                                                                    disabled={!formik.values.depositEnable}
                                                                    placeholder="Deposit"
                                                                    label="Deposit"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                                    }}
                                                                    {...formik.getFieldProps("deposit")}
                                                                    onChange={(e) => {
                                                                        if (validateInputForPercentage(e.target.value)) {
                                                                            formik.setFieldValue("deposit", e.target.value);
                                                                        }
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    name="deposit"
                                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                />
                                                                <Grid item mt={1} ml={0.4}>
                                                                    <Typography className={styles.mm12}>
                                                                        Advance will be debited instantly when placing the order. The final
                                                                        amount will be adjusted automatically incase of order edits or
                                                                        cancellations.
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                )}

                                {newCardFlag && !newHouseCardFlag && (
                                    <Box sx={{height: "100%"}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <AddNewCardForm
                                                    newCardFlag={newCardFlag}
                                                    setNewCardFlag={setNewCardFlag}
                                                    userID={customerId}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}

                                {!newCardFlag && newHouseCardFlag && (
                                    <Box mt={3.2} sx={{height: "100%"}}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <AddNewHouseCardForm
                                                    newCardFlag={newHouseCardFlag}
                                                    setNewHouseCardFlag={setNewHouseCardFlag}
                                                    userID={customerId}
                                                    UserName={name}
                                                    UserCompany={company}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {!newCardFlag && !newHouseCardFlag && <Box className={styles.hr1} />}

                                {!newCardFlag && !newHouseCardFlag && (
                                    <Box sx={{position: "sticky", bottom: 0, mt: 1, p: 0.5, bgcolor: "#fff", zIndex: 1}}>
                                        <Grid container mb={2}>
                                            <Grid item xs={8} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Stack>
                                                    <Typography fontSize="1.5rem" color="#26272A" fontWeight={700}>
                                                        Total
                                                    </Typography>
                                                </Stack>
                                                <Stack ml={0.8} mt={0.3}>
                                                    <Tooltip placement="top-start" title={<TooltipComponent />}>
                                                        <InfoOutlinedIcon
                                                            sx={{
                                                                width: "12px",
                                                                height: "12px",
                                                                color: "#00ab3a"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={4} sx={{textAlign: "right"}}>
                                                <Typography fontSize="1.5rem" color="#26272A" fontWeight={700}>
                                                    {FormatCurrency(orderTotal)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {formik.values.depositEnable && Number(formik.values.deposit) > 0 && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    borderRadius: "4px",
                                                    background: "rgba(2, 180, 108, 0.10)",

                                                    padding: "8px"
                                                }}
                                            >
                                                <Typography color="#00AB3A" fontWeight={500} fontSize="13px">
                                                    Deposit
                                                </Typography>
                                                <Typography color="#26272A" fontWeight={500} fontSize="15px">
                                                    -{FormatCurrency(Number(depositPercentage(formik.values.deposit)))}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box sx={{display: "flex", mt: 1, justifyContent: "flex-end", alignItems: "center"}}>
                                            <Button
                                                onClick={(e) => {
                                                    setIsPaymentButtonDisabled((prevState) => {
                                                        return false;
                                                    });
                                                    dispatch(setCustomerPaymentProfileId(""));
                                                    formik.resetForm();
                                                    toggleDrawer("right", false)(e);
                                                    handleSelectedRow();
                                                }}
                                                sx={(t) => {
                                                    return {
                                                        color: t.palette.error.dark,
                                                        fontSize: "0.81rem",
                                                        mr: 1,
                                                        pl: 0,
                                                        height: "36px",
                                                        minWidth: 0,
                                                        width: "60px"
                                                    };
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: "36px",
                                                    width: "200px"
                                                }}
                                                disabled={formik.isSubmitting}
                                                onClick={(e) => {
                                                    setIsPaymentButtonDisabled((prevState) => {
                                                        return false;
                                                    });
                                                    setSubmitEvent(e);
                                                    formik.handleSubmit();
                                                    formik.validateForm().then((res) => {
                                                        // to close the sidebar if no field has validation error
                                                        if (Object.keys(res).length === 0) {
                                                            setTimeout(() => {
                                                                formik.resetForm();
                                                            }, 8000);
                                                        }
                                                    });
                                                }}
                                            >
                                                {OrderId &&
                                                !searchParams.get("reOrder") &&
                                                (Parentformik?.values?.orderStatus === "Active" ||
                                                    Parentformik?.values?.orderStatus === "Accepted" ||
                                                    Parentformik?.values?.orderStatus === "ON_THE_WAY" ||
                                                    Parentformik?.values?.orderStatus === "Driver assigned") ? (
                                                    <Stack direction="row" justifyContent="center" sx={{width: "100%"}}>
                                                        <Typography
                                                            fontSize="0.81rem"
                                                            fontWeight={600}
                                                            sx={{color: "#FCFCFC", mr: 1, fontFamily: "Inter"}}
                                                        >
                                                            Update
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <Stack direction="row" justifyContent="center" sx={{width: "100%"}}>
                                                        <Typography
                                                            fontSize="0.81rem"
                                                            fontWeight={600}
                                                            sx={{color: "#FCFCFC", mr: 1, fontFamily: "Inter"}}
                                                        >
                                                            Place order
                                                        </Typography>
                                                        <Typography
                                                            fontSize="0.81rem"
                                                            fontWeight={600}
                                                            sx={{color: "#FCFCFC", fontFamily: "Inter"}}
                                                        >
                                                            {FormatCurrency(placeOrderPrice(formik.values.deposit))}
                                                        </Typography>
                                                    </Stack>
                                                )}
                                            </Button>
                                        </Box>

                                        <Box sx={{display: "flex", mt: 1, justifyContent: "flex-end"}}>
                                            {/* <ButtonGrouping
                                                btnprops={[
                                                    {
                                                        btnTitle: "Cancel",
                                                        sx: (t) => ({ color: t.palette.error.dark, mt: 0.5, pl: 0, minWidth: 0, width: '60px' }),
                                                        onClick: (e) => {
                                                            formik.resetForm();
                                                            toggleDrawer("right", false)(e);
                                                            handleSelectedRow();
                                                        }
                                                    },

                                                    // {
                                                    //     btnTitle: handlePaymentText(),
                                                    //     variant: "contained",
                                                    //     disabled: formik.isSubmitting,
                                                    //     sx: (t) => ({ color: t.palette.background.paper, width: "150px", fontSize: "1rem" }),
                                                    //     onClick: (e) => {
                                                    //         setSubmitEvent(e);
                                                    //         formik.handleSubmit();
                                                    //         formik.validateForm().then((res) => {
                                                    //             // to close the sidebar if no field has validation error
                                                    //             if (Object.keys(res).length === 0) {
                                                    //                 setTimeout(() => {
                                                    //                     formik.resetForm();
                                                    //                 }, 1500);
                                                    //             }
                                                    //         });
                                                    //     }
                                                    // }
                                                ]}
                                            /> */}
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    flexDirection: "row",
                                                    alignItems: "right"
                                                }}
                                            >
                                                <Grid item xs={8} className={styles.detail}>
                                                    Per person order items {FormatCurrency(perPrice1)}
                                                </Grid>
                                                <Grid item xs={0.5} className={styles.detail}>
                                                    |
                                                </Grid>

                                                <Grid item xs={7.5} className={styles.detail} display="flex" justifyContent="flex-end">
                                                    Per person total {FormatCurrency(perPrice2)}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )}
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PaymentForm;
