import {Grid} from "@mui/material";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useState, useEffect} from "react";
// import Createmarket from "./CreateMarket/Createmarket";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CommonTable from "commonComponent/TableSection/CommonTable";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {useDispatch, useSelector} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import Createpromotion from "./Createpromotions/CreatePromotion";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import Filter from "commonComponent/FilterAccoridan";
import {usePromotionController} from "./promotion.controller";
import {setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";

const PromotionsListing = () => {
    const {toggleDrawer, state} = useTempDrawer();
    const {getMarketList, getBrandList, filterState} = usePromotionController();
    const [promotionListData, setPromotionListData] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [open, setOpen] = useState(false);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const [search, setSearch] = useState("");
    const [updateListCount, setUpdateListCount] = useState(1);
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const openFilter = useSelector((state) => state?.pagination?.showFilter);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "discount_code");
    const serviceType = useSelector((state) => state?.pagination?.serviceTypeFilter);
    const tab = "promotion";
    const [searchedValue, setSearchedValue] = useState({});

    const dispatch = useDispatch();

    const getServiceName = (service) => {
        switch (service) {
            case "Catering": {
                return "CATERING";
            }
            case "Home Meals": {
                return "HOME_MEALS";
            }
            default:
                return "CATERING";
        }
    };
    const getPromotionsList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.PROMOTIONS.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            service_name: getServiceName(serviceType),
            ...(moreFilters && {...moreFilters})
        };
        if (moreFilters?.market_ids) {
            params.market_ids = moreFilters?.market_ids.join(",");
        }
        if (moreFilters?.brand_ids) {
            params.brand_ids = moreFilters?.brand_ids.join(",");
        }
        if (currentOrderBy) {
            if (currentOrderBy === "end_promodate") {
                params.sortBy = "end_date";
            } else {
                params.sortBy = currentOrderBy;
            }
            params.orderBy = currentOrder;
        }
        // // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.discount_code = search;
            setPromotionListData([]);
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            setPromotionListData(response.data.discountList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCreatePromotion = (btntype) => (e) => {
        switch (btntype) {
            case "Create promotions": {
                // setOpenFilter(false);
                setSelectedRow(null);
                dispatch(setShowFilter(false));
                toggleDrawer("right", "promotionSidebar")(e);
                break;
            }
            case "promotionFilter": {
                dispatch(setShowFilter(!openFilter));
                // setOpenFilter((prev) => !prev);
                // toggleDrawer("right", true)(e);
                break;
            }
            default:
                break;
        }
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.PROMOTIONS.STATUS(values.discount_id));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const openNewlyCreatedDiscountAfterDuplication = (row) => {
        // to open the sidebar with newly created discount id
        setSelectedRow(row);
        // setOpenFilter(false);
        dispatch(setShowFilter(false));
        toggleDrawer("right", true)();
    };

    const handleDuplicateRow = async (id) => {
        const instance = NetworkManager(API.PROMOTIONS.DUPLICATE(id));
        const response = await instance.request();
        if (response.success) {
            openNewlyCreatedDiscountAfterDuplication(response.data.discount);
            getPromotionsList();
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        // getPromotionsList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                // setOpenFilter(false);
                dispatch(setShowFilter(false));
                toggleDrawer("right", "promotionSidebar")(e);
                break;
            }
            case "Duplicate": {
                handleDuplicateRow(row.discount_id);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.PROMOTIONS.DELETE(selectedRow.discount_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getPromotionsList();
    };

    const handleSearch = (val) => {
        setSearch(val);
        dispatch(setPageNumber(1));
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };
    useEffect(() => {
        getBrandList();
        getMarketList();
    }, []);
    useEffect(() => {
        getPromotionsList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount, serviceType]);

    const handleApplyFilter = (Morefilters) => {
        // if (Morefilters?.market_ids) {
        //     Morefilters.market_ids = Morefilters.market_ids.join(",")
        // }
        // setFilterChips(Morefilters);
        dispatch(setPageNumber(1));
    };

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.PROMOTIONS.BULKDELETE);
        const payload = selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.discount_id;
        });

        const response = await instance.request({discountIds: payload});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedCheckbox([]);
        getPromotionsList();
    };

    const columnData = [
        {key: "discount_code", label: "Promotion"},
        {key: "service", label: "Service"},
        {key: "marketDetails", label: "Market"},
        {key: "brandDetails", label: "Brand"},
        {key: "description", label: "Description"},
        {key: "start_date", label: "Start date"},
        {key: "end_promodate", label: "End date"},
        {key: "redemptionCount", label: "Max number of redemptions"},
        {key: "is_active", label: "Status"}
    ];

    const csvBuilder = () => {
        new CsvBuilder("Promotion_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);

                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();
        setSelectedCheckbox([]);
    };

    return (
        <>
            <Grid container>
                {/* {openFilter ? (
                    <Filter
                        filterState={filterState}
                        setFilterState={setFilterChips}
                        filterChips={filterChips}
                        setSearchedValue={setSearchedValue}
                    />
                ) : (
                    <Createpromotion handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
                )} */}
                {!openFilter && state.right === "promotionSidebar" && (
                    <Createpromotion handleIsEdited={handleIsEdited} selectedRow={selectedRow} handleSelectedRow={handleSelectedRow} />
                )}
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        pageheader={<PageHeader title="Promotions" />}
                        btnTitle={WithPermission({module: "Promotions", permission: "create_permission"}) && "Create promotions"}
                        handleClick={handleCreatePromotion}
                        handleSearch={handleSearch}
                        filterType="promotionFilter"
                        orderFilter="orderFilter"
                        selectedCheckbox={selectedCheckbox}
                        handleDeleteBulk={WithPermission({module: "Promotions", permission: "delete_permission"}) && handleDeleteBulk}
                        csvBuilder={csvBuilder}
                        deleteBulkTitle="promotions"
                        showServiceDropdown
                    >
                        {openFilter && (
                            <Filter
                                filterState={filterState}
                                // setFilterState={setFilterChips}
                                filterChips={filterChips}
                                setSearchedValue={setSearchedValue}
                                showFilter={openFilter}
                            />
                        )}
                        <FilterChips
                            filterChips={filterChips}
                            //  setFilterChips={setFilterChips}
                            filterState={filterState}
                        />
                        <CommonTable
                            withCheckbox
                            loading={loading}
                            data={promotionListData}
                            header={[
                                {key: "discount_code", label: "Promotion"},
                                // {key: "service", label: "Service"},
                                {key: "marketDetails", label: "Market"},
                                {key: "brandDetails", label: "Brand"},
                                {key: "description", label: "Description"},
                                {key: "start_date", label: "Start date"},
                                {key: "end_promodate", label: "End date"},
                                {key: "redemptionCount", label: "Max number of redemptions"},
                                {key: "currentRedemptions", label: "Current number of redemptions"},
                                {key: "is_active", label: "Status"},
                                {
                                    key: "action",
                                    label: "Action"
                                }
                            ].filter((el) => el)}
                            actions={[
                                WithPermission({module: "Promotions", permission: "update_permission"}) && "Edit",
                                WithPermission({module: "Promotions", permission: "create_permission"}) && "Duplicate",
                                WithPermission({module: "Promotions", permission: "delete_permission"}) && "Delete"
                            ]}
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Promotion", "Description", "Start date", "End date", "Status"]}
                            setSelectedCheckbox={setSelectedCheckbox}
                            selectedCheckbox={selectedCheckbox}
                            tab={tab}
                        />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this promotion?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default PromotionsListing;
