import {Stack, InputAdornment} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {enforceFormat, formatToPhone} from "utils/helperFunctions/helpers";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import * as yup from "yup";

import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {NetworkManager, API} from "network/core";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    marketId: [],
    phone: "",
    note: "",
    driverCode: ""
};

const CreateDriverForm = (props) => {
    const dispatch = useDispatch();

    const {conditionalTitle, selectedData, handleIsEdited, setSelectedRow, setTitle = () => {}, setLoading = () => {}} = props;

    const {toggleDrawer} = useTempDrawer();
    const [marketList, setmarketList] = useState([]);
    const [initialValues, setInitialValues] = useState(formSchema);
    const {sideBarStateName = false} = props;

    const getAllMarkets = async (pageNumber = 1) => {
        const params = `?page=${pageNumber}&pageSize=5000&sortBy=createdAt&orderBy=desc&is_active=true`;
        const instance = NetworkManager(API.MARKET.GET);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            const idAndLabel = data.marketList.map((el) => ({id: el.market_id, label: el.market_name}));
            setmarketList([...idAndLabel]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    useEffect(() => {
        const tempMarket = [];
        getAllMarkets();
        if (selectedData) {
            selectedData?.market?.forEach((element) => {
                tempMarket.push(element.market_id);
            });

            setInitialValues({
                first_name: selectedData?.name.split(" ")[0],
                last_name: selectedData?.name.split(" ")[1],
                marketId: tempMarket,
                phone: selectedData?.phone,
                email: selectedData?.email,
                driverCode: selectedData?.driverCode
            });
        }
    }, [selectedData]);

    const handleCreateCustomer = async (values) => {
        setLoading(true);
        const payload = selectedData?.name
            ? {market_id: values.marketId, id: `${selectedData.id}`, driver_code: values.driverCode}
            : {
                  name: `${values.first_name} ${values.last_name}`,
                  phoneNumber: values.phone,
                  email: values.email,
                  market_id: values.marketId,
                  driver_code: values.driverCode
              };

        const instance = selectedData?.name ? NetworkManager(API.DRIVER.UPDATE) : NetworkManager(API.DRIVER.CREATE);

        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        handleIsEdited();
        setLoading(false);
        setInitialValues(formSchema);
        setTitle("Add new driver");
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={selectedData?.name ? initialValues : formSchema}
                validationSchema={yup.object({
                    first_name: yup.string().required("First name is required").nullable().trim(),
                    last_name: yup.string().required("Last name is required").nullable().trim(),
                    email: yup.string().required("Email is required").email("Enter valid email").nullable().trim(),
                    phone: yup.string().required("Phone number is required").nullable().trim(),
                    marketId: yup.array().min(1, "At least one option is required").required().nullable(),
                    driverCode: yup.string().required("Driver code is required").max(5, "Only up to 5 characters allow").nullable()
                })}
                onSubmit={(val) => handleCreateCustomer(val)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            whileCloseDoThis={() => setSelectedRow(null)}
                            sideBarStateName={sideBarStateName}
                            resetForm={formik.resetForm}
                            touched={formik.touched}
                        >
                            <Stack sx={{height: "100%"}} justifyContent="space-between" mt={-2}>
                                <Stack spacing={2}>
                                    <PageHeader fontSize="1.625rem" title={selectedData?.name ? conditionalTitle : "Add new driver"} />
                                    <Stack spacing={3}>
                                        <CustomTextField
                                            disabled={selectedData?.name}
                                            placeholder="First name"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                            }}
                                            label="First name"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("first_name")}
                                        />
                                        <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomTextField
                                            disabled={selectedData?.name}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                            }}
                                            placeholder="Last name"
                                            label="Last name"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("last_name")}
                                        />

                                        <CustomSelectWithCheckbox
                                            selectAll
                                            label="Markets"
                                            menuItems={marketList}
                                            values={formik.values.marketId || []}
                                            // onChange={(e) => formik.setFieldValue("marketId", e.target.value)}
                                            onChange={(val) => formik.setFieldValue("marketId", val)}
                                        />

                                        <ErrorMessage name="marketId" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                        <CustomTextField
                                            disabled={selectedData?.name}
                                            fullWidth
                                            label="Phone number"
                                            onKeyDown={enforceFormat}
                                            onKeyUp={formatToPhone}
                                            inputProps={{maxLength: 14}}
                                            // {...(formik.values.phone && {
                                            //     InputProps: {
                                            //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                            //     }
                                            // })}
                                            {...formik.getFieldProps("phone")}
                                        />
                                        <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />

                                        <CustomTextField
                                            disabled={selectedData?.name}
                                            placeholder="Email address"
                                            label="Email address"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("email")}
                                        />
                                        <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        <CustomTextField
                                            placeholder="Driver code"
                                            label="Driver code"
                                            inputProps={{maxLength: 50}}
                                            {...formik.getFieldProps("driverCode")}
                                        />
                                        <ErrorMessage name="driverCode" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Stack>
                                </Stack>

                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                if (selectedData) {
                                                    setTitle("Add new driver");
                                                    setSelectedRow(null);
                                                    setInitialValues(formSchema);
                                                }

                                                toggleDrawer("right", false)(e);
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: (e) => {
                                                formik.handleSubmit();

                                                formik.validateForm().then((res) => {
                                                    if (Object.keys(res).length === 0) {
                                                        toggleDrawer("right", false)(e);
                                                        setTimeout(() => {
                                                            formik.resetForm();
                                                        }, 1500);
                                                    }
                                                });
                                            }
                                        }
                                    ]}
                                />
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateDriverForm;
