import React from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid, FormControlLabel, Checkbox} from "@mui/material";
import {NetworkManager, API} from "network/core";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import StorageManager from "../../../../../../../storage/StorageManager";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {
    enforceNumericAndMaxLength,
    formatExpiryDate,
    enforceDateAndMaxLength,
    enforceFormatForAlphabet,
    onlyNumbersWithOutDecimal
} from "utils/helperFunctions/helpers";
import {ErrorMessage, Formik, Form} from "formik";
import styles from "./payment.module.scss";
import {useSelector} from "react-redux";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {addNewCardValidation} from "constants/validationConstant";
import {openSnackbar} from "store/slices/snackbar";
import {dispatch} from "store";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import {setCustomerPaymentProfileId} from "store/slices/card";

const defaultInitialValues = {
    name: "",
    card_number: "",
    month: "",
    year: "",
    cvv: ""
};

const AddNewCardForm = (props) => {
    const {setNewCardFlag, userID} = props;
    const headerData = useSelector((state) => state.headerData);

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));
    function convertToTwoDigit(number) {
        return number < 10 ? `0${number}` : `${number}`;
    }

    const handleSaveDetails = async (detail, setSubmitting) => {
        setSubmitting(true);

        const paylaod = {
            user_id: userID,
            name_on_card: detail.name,
            card_number: detail.card_number,
            expiration_date: `${convertToTwoDigit(detail.month)}/${detail.year}`,
            cvv: detail.cvv,
            is_default_card: detail.is_default_card
        };

        const instance = NetworkManager(API.CARD.ADD);
        const res = await instance.request(paylaod);
        console.log(res, "resss");

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            setNewCardFlag(false);
            dispatch(setCustomerPaymentProfileId(res.data?.customerPaymentProfileId));
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Enter valid card details",
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }

        setSubmitting(false);
    };

    return (
        <>
            <Formik
                enableReinitialize
                validationSchema={addNewCardValidation}
                initialValues={defaultInitialValues}
                onSubmit={(val, {setSubmitting}) => handleSaveDetails(val, setSubmitting)}
            >
                {(formik) => (
                    <Form>
                        <Box sideDrawerState="addNewCardForm">
                            <Stack sx={{height: "100%", padding: "0px 7px 7px 0px"}} direction="column" justifyContent="space-between">
                                <Box>
                                    <Typography variant="headingOne" fontSize="1.625rem" color="#000000" fontWeight={700}>
                                        Add new card
                                    </Typography>
                                    <Grid container gap={2} mt={3}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                                }}
                                                inputProps={{maxLength: 30}}
                                                label="Name on card"
                                                placeholder="Name on card"
                                                {...formik.getFieldProps("name")}
                                            />
                                            <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                placeholder="Card number"
                                                label="Card number"
                                                onKeyDown={enforceNumericAndMaxLength(formik.getFieldProps("card_number").value, 16)}
                                                {...formik.getFieldProps("card_number")}
                                            />
                                            <ErrorMessage
                                                name="card_number"
                                                render={(msg) => <CustomErrorMessage errorMessage="Enter valid card number" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{minWidth: "180px"}}>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="MM"
                                                        label="Expiration month"
                                                        inputProps={{
                                                            maxLength: 2
                                                        }}
                                                        onKeyDown={(e) => {
                                                            const value = e.target.value;
                                                            if (e.key === "Tab") return;
                                                            if (value.length >= 2 && e.key !== "Backspace" && e.key !== "Delete") {
                                                                e.preventDefault();
                                                            }

                                                            if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        {...formik.getFieldProps("month")}
                                                    />
                                                    <ErrorMessage
                                                        name="month"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Box>
                                                <Box sx={{color: "#9E9E9E", width: "16px", textAlign: "center", marginTop: "15px"}}>/</Box>
                                                <Box sx={{minWidth: "180px"}}>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="YY"
                                                        label="Expiration year"
                                                        inputProps={{maxLength: 2}}
                                                        onKeyDown={(e) => {
                                                            const value = e.target.value;
                                                            if (e.key === "Tab") return;
                                                            if (value.length >= 2 && e.key !== "Backspace" && e.key !== "Delete") {
                                                                e.preventDefault();
                                                            }

                                                            if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        {...formik.getFieldProps("year")}
                                                    />
                                                    <ErrorMessage name="year" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                placeholder="CVV"
                                                label="CVV"
                                                onKeyDown={enforceNumericAndMaxLength(formik.getFieldProps("cvv").value, 4)}
                                                {...formik.getFieldProps("cvv")}
                                            />
                                            <ErrorMessage name="cvv" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formik.values.is_default_card}
                                                        disableRipple
                                                        icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                                        checkedIcon={
                                                            <img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />
                                                        }
                                                        onChange={(e) =>
                                                            e.target.checked
                                                                ? formik.setFieldValue("is_default_card", true)
                                                                : formik.setFieldValue("is_default_card", false)
                                                        }
                                                    />
                                                }
                                                label={<Typography className={styles.smallData}>Make this default card</Typography>}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        position: "fixed",
                                        bottom: 40,

                                        height: "52px",
                                        width: !matchUpMd ? "290px" : "390px"
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 2, bgcolor: "#fff", zIndex: 1}}>
                                                <ButtonGrouping
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark}),
                                                            onClick: (e) => {
                                                                formik.resetForm();
                                                                setNewCardFlag(false);
                                                            }
                                                        },
                                                        {
                                                            btnTitle: "Save",
                                                            variant: "contained",
                                                            disabled: formik.isSubmitting,
                                                            onClick: () => {
                                                                formik.handleSubmit();
                                                            },
                                                            sx: (t) => ({color: t.palette.background.paper})
                                                        }
                                                    ]}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddNewCardForm;
