import {Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Stack, Typography} from "@mui/material";
import {IconGripVertical} from "@tabler/icons";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, FieldArray, Formik} from "formik";
import React, {useEffect, useState} from "react";
import * as yup from "yup";
import DietaryOptions from "commonComponent/ModifierComponent/DietaryPopup/DietaryPopup";

import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {twoDigitAfterDecimal} from "utils/helperFunctions/helpers";
import ActionButton from "commonComponent/Apptable/ActionButton";
import {validationContant} from "utils/validations/validationConstants";
import {useStyles} from "../../ModifierComponent/AddModifier/modifierStyles";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";

const defaultValue = {
    name: "",
    category: "",
    preparation: new Set(),
    dietary: []
};
const IncludedOptionItems = (props) => {
    const {index, arrayHelper, dataObj, provided, handleActionBtn, categoryList, methodsofPreparation, handlePrepSelect, arrayToMap} =
        props;
    const [initialValue, setInitalValue] = useState(defaultValue);
    const style = useStyles();
    const [categoryMap, setCategoryMap] = useState(new Map());
    const handleEditModifier = async (val, setSubmitting) => {
        const dataObjectRequired = {
            name: val.name,
            dietary: val.dietary ?? [],
            preparation: val.preparation ?? new Set(),
            category: val.category ?? "",
            id: dataObj?.id
        };
        arrayHelper.replace(index, {...dataObjectRequired, isEdit: false});
    };

    const categoryArrayToMap = () => {
        const sortedCategoryMap = new Map(categoryMap);

        categoryList.forEach((category) => {
            sortedCategoryMap.set(category.id, category.value);
        });
        setCategoryMap(sortedCategoryMap);
    };
    useEffect(() => {
        setInitalValue({
            series_no: dataObj?.series_no,
            name: dataObj?.name,
            category: dataObj?.category,
            preparation: dataObj?.preparation,
            dietary: dataObj?.modifier_dietary ?? dataObj?.dietary ?? []
        });
        categoryArrayToMap();
    }, [dataObj]);

    const validationObj = () => {
        return {
            name: yup.string().required("Modifier name is required").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
            price: yup.string().test("is-decimal", "Two digits decimal", twoDigitAfterDecimal)
        };
    };
    return (
        <Formik
            initialValues={initialValue}
            enableReinitialize
            validationSchema={yup.object(validationObj())}
            onSubmit={(val, {setSubmitting}) => {
                handleEditModifier(val, setSubmitting);
            }}
        >
            {(formik) => (
                <Grid container spacing={1.5} alignItems="center">
                    <Grid item xs>
                        <Stack direction="row" alignItems={dataObj?.isEdit ? "center" : "flex-start"}>
                            <span {...provided.dragHandleProps}>
                                <IconGripVertical icon="grip-vertical" style={{color: "#E0E2E7"}} />
                            </span>
                            <Box sx={style.hundredPercentWidth}>
                                {!dataObj?.isEdit ? (
                                    <Typography variant="body4" fontSize="13px" sx={{mb: 2}}>
                                        {formik.values.name}
                                    </Typography>
                                ) : (
                                    <CustomTextField
                                        sx={style.inputs}
                                        inputProps={{
                                            maxLength: 80
                                        }}
                                        fullWidth
                                        placeholder=""
                                        {...formik.getFieldProps("name")}
                                    />
                                )}
                                <ErrorMessage name="name" render={(msg) => <CustomErrorMessage fontSize="0.5em" errorMessage={msg} />} />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        <FieldArray
                            name="dietary"
                            render={(arrayHelper) => (
                                <DietaryOptions
                                    disabled={!dataObj?.isEdit}
                                    haslength={formik.values?.dietary?.length}
                                    checked={(obj) => formik.values?.dietary?.includes(obj.value)}
                                    onChange={(obj) => {
                                        if (formik.values?.dietary?.length && formik.values?.dietary?.includes(obj.value)) {
                                            const valIndex = formik.values?.dietary?.findIndex((el) => el === obj.value);
                                            arrayHelper.remove(valIndex);
                                        } else {
                                            arrayHelper.push(obj.value);
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={!dataObj?.isEdit ? 1 : 2}>
                        {!dataObj?.isEdit ? (
                            <ActionButton
                                row={dataObj}
                                ActionArray={["Duplicate", "Edit", "Delete"]}
                                handleActionBtn={(type, row, handleClose) => (e) => {
                                    handleActionBtn(type, row, handleClose, arrayHelper, index, arrayToMap)(e);
                                }}
                            />
                        ) : (
                            <IconButton
                                onClick={() => {
                                    formik.handleSubmit();
                                }}
                            >
                                <Typography fontSize="0.5em" sx={style.saveButton}>
                                    Save
                                </Typography>
                            </IconButton>
                        )}
                    </Grid>

                    <Box pl={2} pt={1} width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Box width="60%">
                            {!dataObj?.isEdit ? (
                                <Box
                                    sx={{
                                        padding: "7px 6px 7px 8px",
                                        backgroundColor: "#F0F0F0",
                                        color: "black",
                                        borderRadius: "4px",
                                        width: "max-content",marginLeft:2.4
                                    }}
                                >
                                    <Typography color="#25272B" fontWeight={500} fontSize="12px" lineHeight="12px">
                                        {categoryMap.get(formik?.values?.category)}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box pl={2}>
                                    <CustomSelect
                                        label="Category"
                                        menuItems={categoryList}
                                        fullWidth
                                        {...formik.getFieldProps("category")}
                                        inputProps={{maxLength: 250}}
                                        value={formik.values.category}
                                        // name='category'
                                        onChange={(e) => {
                                            const value = e.target.value;

                                            formik.setFieldValue("category", value);
                                        }}
                                    />
                                    <ErrorMessage name="displayName" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            {!dataObj?.isEdit ? (
                                <Typography mr={4.5} color="#25272B" fontWeight={500} fontSize="12px" lineHeight="12px">
                                    {Array.from(formik?.values?.preparation).join(", ")}
                                </Typography>
                            ) : (
                                <FormGroup sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                                    {Array.from(methodsofPreparation).map((obj, index) => (
                                        <Box key={obj} direction="row">
                                            <FormControlLabel
                                                sx={{height: 30}}
                                                // disabled={!isEdit}
                                                checked={formik.values.preparation.has(obj)}
                                                key={obj}
                                                control={
                                                    <Checkbox
                                                        disableRipple
                                                        icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                                        checkedIcon={
                                                            <img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        fontSize="15px"
                                                        variant={
                                                            formik.values.preparation.size > 0 && formik.values.preparation.has(obj)
                                                                ? "checkBoxSelected"
                                                                : "checkBoxUnSelected"
                                                        }
                                                    >
                                                        {obj}
                                                    </Typography>
                                                }
                                                onChange={(e) => handlePrepSelect(obj, formik)}
                                            />
                                        </Box>
                                    ))}
                                </FormGroup>
                            )}
                        </Box>
                    </Box>
                </Grid>
            )}
        </Formik>
    );
};

export default IncludedOptionItems;
