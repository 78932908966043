import useTempDrawerModifier from "hooks/useTempDrawerModifier";
import {API, NetworkManager} from "network/core";
import {useEffect, useLayoutEffect, useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {generateUrl} from "utils/helperFunctions/helpers";
import {useIncludedGroupModel} from "./addEdit.model";

const defaultState = {
    includedGroupName: "",
    includedOptions: []
};
const methodsofPreparation = new Set(["Prep", "Fire"]);
export const useAddIncludedGroupController = (props) => {
    const {
        handleIsEdited,
        selectedRow,
        handleSelectedRow,
        fromItemFlow = false,
        itemIndex,
        includedItemHelper = () => {},
        getModifiersGroupList = () => {}
    } = props;
    const model = useIncludedGroupModel();
    const [initialState, setInitialState] = useState();
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {toggleDrawer: toggleDrawerModifier} = useTempDrawerModifier();

    const handlePrepSelect = (obj, formik) => {
        const {preparation} = formik.values;
        const newSet = new Set(preparation);
        if (newSet?.has(obj)) {
            newSet.delete(obj);
        } else {
            newSet.add(obj);
        }
        formik.setFieldValue("preparation", newSet);
    };

    const getCategoryList = async () => {
        const instance = NetworkManager(API.CATEGORY.GET);
        const params = {
            sortBy: "category_name",
            orderBy: "asc",
            type: "modifier"
        };
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);
        if (response.success) {
            const states = response.data?.categoryList?.rows?.map((state) => ({
                label: state.category_name,
                value: state.category_name,
                id: state.category_id,
                description: state.description
            }));
            setCategoryList(states);
        }
    };

    function transformFirstObject(firstObject) {
        const transformedObject = {
            item_included_group_id: null,
            item_id: null,
            series_no: 0,
            is_deleted: firstObject.is_deleted,
            deleted_at: firstObject.deleted_at,
            included_group_id: firstObject.included_group_id,
            createdAt: firstObject.createdAt,
            updatedAt: firstObject.updatedAt,
            includedGroup: {
                included_group_id: firstObject.included_group_id,
                included_group_name: firstObject.included_group_name,
                is_deleted: firstObject.is_deleted,
                is_active: firstObject.is_active,
                deleted_at: firstObject.deleted_at,
                createdAt: firstObject.createdAt,
                updatedAt: firstObject.updatedAt,
                iGOs: firstObject.iGOs.map((igo) => ({
                    included_group_options_id: igo.included_group_options_id,
                    item_id: igo.item_id,
                    preparation: igo.preparation,
                    included_group_item_name: igo.included_group_item_name,
                    included_group_id: igo.included_group_id,
                    category_id: igo.category_id,
                    is_deleted: igo.is_deleted,
                    is_active: igo.is_active,
                    deleted_at: igo.deleted_at,
                    series_no: igo.series_no,
                    createdAt: igo.createdAt,
                    updatedAt: igo.updatedAt
                    // Remove the iOptionDietaries key as it's not present in the second object
                }))
            },
            id: firstObject.included_group_id,
            label: firstObject.included_group_name
        };

        return transformedObject;
    }

    const postNewIncludedGroup = async (body, e) => {
        const payload = {};
        payload.included_group_name = body?.includedGroupName;
        payload.included_group_options = body?.includedOptions.map((item, index) => {
            const dietaryArray = item.modifier_dietary ?? item?.dietary;
            const reuiredDietaryArr = dietaryArray.map((dietary) => ({included_dietary: dietary}));
            return {
                ...(item?.id !== "" && item?.id && {item_id: item.id}),
                item_name: item.name,
                preparation: Array.from(item.preparation),
                category_id: item?.category,
                dietary: reuiredDietaryArr,
                series_no: index
            };
        });
        const response = selectedRow
            ? await model.updateIncludedGroup(selectedRow?.included_group_id, payload)
            : await model.createNewIncludedGroup(payload);
        if (response?.success) {
            toggleDrawerModifier("right", false)(e);
            handleIsEdited();
            setInitialState(defaultState);
            await e?.resetForm();

            const data = response?.data;
            const requiredObject = transformFirstObject(data);
            includedItemHelper.replace(itemIndex, requiredObject);
            handleSelectedRow(null);
        }else{
            await e?.setSubmitting(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getDataForEdit = async () => {
        const response = await model.getSingleIncludedGroup(selectedRow?.included_group_id);
        if (response?.success) {
            const includedGroupItem = response?.data?.includedGroup;
            const initialStateObject = {
                id: includedGroupItem.included_group_id,
                includedGroupName: includedGroupItem.included_group_name,
                includedOptions: includedGroupItem?.iGOs
                    .sort((a, b) => a?.series_no - b?.series_no)
                    .map((item, index) => {
                        return {
                            name: item?.included_group_item_name,
                            dietary: item?.iOptionDietaries
                                .filter((option) => option?.included_dietary !== null)
                                .map((opt) => opt?.included_dietary),
                            preparation: new Set(item?.preparation),
                            category: item?.category_id,
                            id: item?.item_id,
                            series_no: item?.series_no
                        };
                    })
            };
            setInitialState(initialStateObject);
        }
    };

    useEffect(() => {
        if (selectedRow) {
            getDataForEdit();
        } else {
            setInitialState(defaultState);
        }
    }, [selectedRow]);

    useLayoutEffect(() => {
        getCategoryList();
    }, []);
    return {
        initialState,
        setInitialState,
        loading,
        defaultState,
        handlePrepSelect,
        methodsofPreparation,
        categoryList,
        postNewIncludedGroup
    };
};
