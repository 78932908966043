import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import NewAddItem from "commonComponent/ItemComponent/Additem/NewAddItem";
import CreateDriverForm from "./DriverData/createDriverForm";
import useTempDrawer from "hooks/useTempDrawer";
import InfoModal from "commonComponent/DilogBox/InfoModal";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const ItemsMasterList = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox} = props;
    const [title, setTitle] = useState("Add new driver");

    const [open, setOpen] = React.useState(false);
    const [driverListing, setDriverListing] = React.useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [orderBy, setOrderBy] = useState("driverName");
    const [order, setOrder] = useState("asc");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filterChips, setFilterChips] = useState({});

    const navigation = useNavigate();

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const getDriverList = async (moreFilters = false) => {
        setLoading(true);
        const params = {};
        if (orderBy) {
            params.sort_by = orderBy;
            params.sort = order;
        }

        const url = generateUrl(params);

        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request({}, [url]);

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList.map((obj) => ({
                phone: obj.phoneNumber,
                driverName: obj.name,
                market: obj?.market?.market_name,
                driverCode: obj?.driverCode,
                ...obj
            }));
            setDriverListing(modifiedArray);

            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                setTitle("Edit driver");
                setSelectedRow(row);
                toggleDrawer("right", "createDriver")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }

            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.DRIVER.DELETE(selectedRow.id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getDriverList();
    };

    const handlePageChange = (e, number) => {
        setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        setRowsPerPage(number);
        setCurrentPage(1);
    };

    const handleSort = (val1, val2) => {
        if (val2 === "driverName") {
            setOrder(val1);
            setOrderBy("name");
        } else if (val2 === "driverMarkets") {
            setOrder(val1);
            setOrderBy("market");
        } else {
            setOrder(val1);
            setOrderBy(val2);
        }
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    useEffect(() => {
        getDriverList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, order, orderBy, isEdited, filterChips, updateListCount]);

    const handleAddItemToCateogoryList = (item) => {
        // getDriverList();
    };

    const filterState = [
        {
            key: "first_name",
            label: "Driver Name",
            type: "textfield"
        },
        {
            key: "market_ids",
            label: "Market Id",
            type: "textfield"
        },
        {
            key: "vehicle_make_and_model",
            label: "Vehicle make and model",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created",
            type: "startDate"
        },
        {
            key: "is_active",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "active", label: "Active"},
                {id: "inactive", label: "Inactive"}
            ]
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        setCurrentPage(1);
        setFilterChips(Morefilters);
    };

    return (
        <>
            <CreateDriverForm
                setSelectedRow={setSelectedRow}
                selectedData={selectedRow}
                setTitle={setTitle}
                conditionalTitle={title}
                handleIsEdited={handleIsEdited}
                setLoading={setLoading}
            />

            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        loading={loading}
                        data={driverListing}
                        header={[
                            {key: "driverName", label: "Driver"},
                            {key: "driverCode", label: "Driver code"},
                            {key: "phone", label: "Phone"},
                            {key: "email", label: "Email"},
                            {key: "driverMarkets", label: "Market"},
                            {key: "createdAt", label: "Created"},

                            WithPermission({module: "Drivers", permission: ["delete_permission", "update_permission"]}) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Drivers", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Drivers", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={12}
                        rowsPerPage={rowsPerPage}
                        noPagination
                        // setSelectedCheckbox={setSelectedCheckbox}
                        // selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        sortingLabel={[]}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this driver?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default ItemsMasterList;
