import {Grid} from "@mui/material";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import NewAddItem from "commonComponent/ItemComponent/Additem/NewAddItem";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {resetState, setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";

const ItemsMasterList = (props) => {
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips, serviceType} = props;

    const [open, setOpen] = React.useState(false);
    const [itemListing, setItemListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "item_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const navigation = useNavigate();

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const getItemList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.ITEM.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            service_type: serviceType,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }

        if (moreFilters?.item_type) {
            params.item_type = moreFilters?.item_type.join(",");
        }

        if (moreFilters?.category) {
            params.category = moreFilters?.category.join(",");
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.item_name = search;
            setItemListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setItemListing(response.data.item);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.ITEM.UPDATE(`/status/${values.item_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleDuplicateRow = async (id) => {
        const instance = NetworkManager(API.ITEM.DUPLICATE(id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        getItemList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigation(`/items/masterList/${row.item_id}?tab=itemDetails&edit=true`);
                break;
            }
            case "Duplicate": {
                handleDuplicateRow(row.item_id);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ITEM.DELETE(selectedRow.item_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getItemList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getItemList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount, serviceType]);

    const headerData =
        serviceType === "Catering"
            ? [
                  {key: "item_name", label: "Menu item"},
                  {key: "menusItem", label: "Menu"},
                  {key: "item_type", label: "Type"},
                  {key: "Category_names", label: "Category"},
                  {key: "item_price", label: "Price"},
                  {key: "item_minimum_order_quantity", label: "MOQ"},
                  {key: "createdAt", label: "Created"},
                  {key: "status", label: "Status"},

                  WithPermission({
                      module: "Items",
                      permission: ["delete_permission", "create_permission", "update_permission"]
                  }) && {
                      key: "action",
                      label: "Action"
                  }
              ].filter((el) => el)
            : [
                  {key: "item_name", label: "Menu item"},
                  {key: "menusItem", label: "Menu"},
                  {key: "cusineItem", label: "Cusine"},
                  {key: "menuBrand", label: "Brand"},
                  {key: "Category_names", label: "Category"},
                  {key: "item_price", label: "Price"},
                  {key: "createdAt", label: "Created"},
                  {key: "status", label: "Status"},
                  WithPermission({
                      module: "Items",
                      permission: ["delete_permission", "create_permission", "update_permission"]
                  }) && {
                      key: "action",
                      label: "Action"
                  }
              ].filter((el) => el);

    return (
        <>
            {showFilter ? (
                {
                    /* <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                /> */
                }
            ) : (
                <NewAddItem handleIsEdited={handleIsEdited} isEditable />
            )}

            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        // setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        // withCheckbox
                        path
                        loading={loading}
                        data={itemListing}
                        currentTab="brands"
                        header={headerData}
                        actions={[
                            WithPermission({module: "Items", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Items", permission: "create_permission"}) && "Duplicate",
                            WithPermission({module: "Items", permission: "delete_permission"}) && "Delete"
                        ]}
                        height="75vh"
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        sortingLabel={["Menu item", "Created", "Status"]}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this item?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default ItemsMasterList;
