import {Box, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import useModifierGroupController from "./ModifierGroup.controller";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import {ErrorMessage, FieldArray} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {getCategoryStyle} from "commonComponent/Dragger/utils";
import {IconGripVertical} from "@tabler/icons";
import ActionButton from "commonComponent/Apptable/ActionButton";
import NewAddModifierGroup from "commonComponent/ModifierComponent/AddModifierGroup/NewAddModifierGroup";

const ModifierGroupSection = (props) => {
    const {formik, isEdit = true} = props;

    const MGC = useModifierGroupController(props);

    const {handleActionBtn, onDragEnd, selectedIndex, selectedRow, handleSelectedRow, handleIsEdited, getModifiersGroupList} = MGC;
    
    return (
        <> 
            <Grid container>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant="headingOne">Modifier groups</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomSelectWithCheckbox
                            disabled={!isEdit}
                            hasSearch
                            
                            label="Modifier groups"
                            menuItems={MGC.modiferGroup}
                            values={formik.values.modifier_group_id.map((obj) => obj?.id)}
                            onChange={(val) => {
                                const selectedItem = MGC.modiferGroup.filter((obj) => val.includes(obj.id));
                                formik.setFieldValue("modifier_group_id", selectedItem);
                            }}
                        />
                        <ErrorMessage name="modifier_group_id" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>

                    <Grid item xs={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" type="QUESTIONS">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={{background: "#ffff"}}>
                                        <FieldArray
                                            name="modifier_group_id"
                                            render={(arrayHelper) => (
                                                <>
                                                    {formik.values.modifier_group_id.length > 0 &&
                                                        formik.values.modifier_group_id?.map((obj, index) => (
                                                            <Draggable
                                                                key={`${obj?.id}${index}`}
                                                                draggableId={`${obj?.updatedAt}${index}`}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        style={getCategoryStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center"
                                                                            }}
                                                                        >
                                                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                                                <span {...provided.dragHandleProps}>
                                                                                    <IconGripVertical
                                                                                        icon="grip-vertical"
                                                                                        style={{color: "#E0E2E7"}}
                                                                                    />
                                                                                </span>
                                                                                <Box
                                                                                    
                                                                                    style={{cursor: "pointer"}}
                                                                                >
                                                                                    <Typography variant="body4" fontSize="14px">
                                                                                        {obj?.label}
                                                                                    </Typography>
                                                                                    {obj.modifierGroupModifier?.length > 0 ? (
                                                                                        <Typography fontSize="12px">
                                                                                            {obj?.modifierGroupModifier?.sort((a,b)=>a?.modifier?.series_no - b?.modifier?.series_no)?.map(
                                                                                                (el, i) =>
                                                                                                    `${i > 0 ? ", " : ""}${
                                                                                                        el?.modifier?.modifier_name
                                                                                                    }`
                                                                                            )}
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Typography fontSize="12px">&nbsp;</Typography>
                                                                                    )}
                                                                                </Box>
                                                                            </Stack>

                                                                            {isEdit && (
                                                                                <ActionButton
                                                                                    row={obj}
                                                                                    ActionArray={["Edit", "Remove"]}
                                                                                    handleActionBtn={(type, row, handleClose) => (e) => {
                                                                                        handleActionBtn(
                                                                                            type,
                                                                                            row,
                                                                                            handleClose,
                                                                                            arrayHelper,
                                                                                            index
                                                                                        )(e);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}

                                                    <NewAddModifierGroup
                                                        fromItemFlow
                                                        getModifiersGroupList={getModifiersGroupList}
                                                        itemModifierIndex={selectedIndex}
                                                        itemMoidiferarrayHelper={arrayHelper}
                                                        selectedRow={selectedRow}
                                                        handleSelectedRow={handleSelectedRow}
                                                        handleIsEdited={handleIsEdited}
                                                    />
                                                </>
                                            )}
                                        />

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ModifierGroupSection;
