import {Typography, Box} from "@mui/material";

function Cards({title, data, singleGrid = false}) {
    return (
        <Box
            sx={{
                borderRadius: "4px",
                border: "1px solid #DBDBDB",
                padding: "16px",
                width: {xs: "100%", sm: "50%"}
            }}
        >
            <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                {title}
            </Typography>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: singleGrid ? "1fr" : "1fr 1fr",
                    gridTemplateRows: singleGrid ? "auto" : "auto auto auto",
                    gap: "16px",
                    marginTop: "12px"
                }}
            >
                {data.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            gridColumn: singleGrid ? "1" : index < 3 ? "1" : "2",
                            gridRow: singleGrid ? "auto" : index < 3 ? `${index + 1}` : "1"
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "0.625rem",
                                fontWeight: 400,
                                color: "#9e9e9e"
                            }}
                        >
                            {item.label}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                color: "#212121"
                            }}
                        >
                            {item.value}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default Cards;
