import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useTempDrawer from "hooks/useTempDrawer";
import useTempDrawerModifier from "hooks/useTempDrawerModifier";
import ItemsListing from "../../brands/ItemList";
import ModifiersListing from "../../brands/ModifiersGroupList";
import Menu from "../../brands/brandDetail/menu/menuForm";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {useSelector, useDispatch} from "react-redux";
import {setChildFilterChips, setChildShowFilter, resetChildState} from "store/slices/paginationSlice";

const useMenuDetailController = () => {
    const navigate = useNavigate();
    const prm = useParams();
    const {menuid, type} = prm;

    const [label, setLebel] = useState("");
    const [heading, setHeading] = useState("");
    const [search, setSearch] = useState("");
    const showFilter = useSelector((state) => state?.pagination?.childShowFilter);
    const [breadCrumb, setBreadcrumb] = useState("View menu");
    const [editMenu, setEditMenu] = useState(false);
    const {toggleDrawer} = useTempDrawer();
    const {toggleDrawer: toggleDrawerModifier} = useTempDrawerModifier();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const tab = urlSearchParams.get("tab");
    const editMenuOrNot = urlSearchParams.get("edit");
    const headerName = urlSearchParams.get("menuName");
    const brandId = urlSearchParams.get("brandId");
    const brandName = urlSearchParams.get("brandName");
    const menuCreatePermission = WithPermission({module: "Menu", permission: "update_permission"});
    const itemCreatePermission = WithPermission({module: "Items", permission: "create_permission"});
    const modifierCreatePermission = WithPermission({module: "Modifiers", permission: "create_permission"});
    const [value, setValue] = React.useState("menu");
    const filterChips = useSelector((state) => state?.pagination?.childFilterChips);
    const dispatch = useDispatch();
    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "menu":
                setLebel(menuCreatePermission ? "Edit menu" : null);
                // setHeading("Menu");
                break;
            case "menuItems":
                setLebel(itemCreatePermission ? "Create item" : null);
                setHeading("Menu items");
                break;
            case "modifierGroup":
                setLebel(modifierCreatePermission ? "Create modifier group" : null);
                setHeading("Modifier group");
                break;
            default:
                setLebel(menuCreatePermission ? "Edit menu" : null);
                setHeading("Menu");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
    }, [window.location.search]);

    useEffect(() => {
        
        setValue(tab);
        let search = headerName ? `?tab=${tab}&menuName=${headerName}` : `?tab=${tab}&add=yes`;
        if (editMenuOrNot === "yes") {
            search = headerName ? `?tab=${tab}&edit=yes&menuName=${headerName}` : `?tab=${tab}&edit=yes&menuName=${headerName}`;
        }
        if(!brandId){
            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
       
        if (menuid === undefined) {
            setBreadcrumb("Create menu");
        }
    }, []);

    const handleChange = (event, newValue) => {
        dispatch(resetChildState());
        setValue(newValue);
        if (brandId) {
            navigate({
                pathname: window.location.pathname,
                search: headerName ? `?tab=${newValue}&menuName=${headerName}` : `?tab=${newValue}`
            });
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: headerName ? `?tab=${newValue}&menuName=${headerName}` : `?tab=${newValue}&add=yes`
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };
    const handleCreateLocation = (btntype) => (e) => {
        console.log("fired");
        // dispatch(setChildFilterChips({}));
        switch (btntype) {
            case "Create modifier group": {
                dispatch(setChildShowFilter(false));
                toggleDrawerModifier("right", "addModifierGroup")(e);
                break;
            }
            case "Create item": {
                dispatch(setChildShowFilter(false));
                toggleDrawer("right", "itemEditSidebar")(e);
                break;
            }
            case "none": {
                dispatch(setChildShowFilter(!showFilter));
                break;
            }
            default: {
                // setEditMenu(true)

                if (editMenu === true) {
                    setLebel("Edit menu");
                    if (menuid) {
                        navigate(
                            headerName
                                ? `/menus/menuDetail/${menuid}?tab=menu&menuName=${headerName}`
                                : `/menus/menuDetail/${menuid}?tab=menu&add=yes`
                        );
                    } else {
                        navigate(headerName ? `/menus/menuDetail/?tab=menu&menuName=${headerName}` : "/menus/menuDetail/?tab=menu&add=yes");
                    }
                    setEditMenu(false);
                } else {
                    setLebel("View menu");
                    if (menuid) {
                        navigate(
                            headerName
                                ? `/menus/menuDetail/${menuid}?tab=menu&edit=yes&menuName=${headerName}`
                                : `/menus/menuDetail/${menuid}?tab=menu&edit=yes`
                        );
                    } else {
                        navigate(
                            headerName
                                ? `/menus/menuDetail/?tab=menu&edit=yes&menuName=${headerName}`
                                : "/menus/menuDetail/?tab=menu&edit=yes"
                        );
                    }

                    setEditMenu(true);
                }

                break;
            }
        }
    };

    const dummyData = [
        {
            value: "menu",
            label: "Menu",
            comp: () => <Menu setHeading={setHeading} editMenu={editMenu} />
        }
    ];

    if (menuid) {
        dummyData.push({
            value: "menuItems",
            label: "Menu items",
            comp: () => (
                <ItemsListing
                    menuId={menuid}
                    search={search}
                    showFilter={showFilter}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                />
            )
        });
        dummyData.push({
            value: "modifierGroup",
            label: "Modifier groups",
            comp: () => <ModifiersListing search={search} showFilter={showFilter} setHeading={setHeading} filterChips={filterChips} />
        });
    }
    const getFilterState = (value) => {
        return value === "menuItems"
            ? [
                  {
                      key: "item_name",
                      label: "Item name",
                      type: "textfield"
                  },
                  {
                      key: "menu_name",
                      label: "Menu",
                      type: "textfield"
                  },
                  {
                      key: "service_name",
                      label: "Service",
                      type: "multiselect",
                      getData: () => [
                          {id: "CATERING", label: "Catering"},
                          {id: "HOME_MEALS", label: "Home meals"}
                      ]
                  },
                  {
                      key: "start_date",
                      label: "Created",
                      type: "createdAt"
                  },
                  {
                      key: "is_active",
                      label: "Status",
                      type: "select",
                      getData: () => [
                          {id: "active", label: "Active"},
                          {id: "inactive", label: "Inactive"}
                      ]
                  }
              ]
            : [
                  {
                      key: "modifier_group",
                      label: "Modifier group",
                      type: "textfield"
                  },
                  {
                      key: "modifier",
                      label: "Modifiers",
                      type: "textfield"
                  },
                  {
                      key: "start_date",
                      label: "Created",
                      type: "startDate"
                  },
                  {
                      key: "is_active",
                      label: "Status",
                      type: "select",
                      getData: () => [
                          {id: "active", label: "Active"},
                          {id: "inactive", label: "Inactive"}
                      ]
                  }
              ];
    };
    return {
        dummyData,
        value,
        editMenuOrNot,
        handleChange,
        breadCrumb,
        menuid,
        label,
        heading,
        handleCreateLocation,
        handleSearch,
        search,
        headerName,
        selectedCheckbox,
        setSelectedCheckbox,
        updateListCount,
        setUpdateListCount,
        getFilterState,
        filterChips,
        showFilter,
        type
    };
};

export default useMenuDetailController;
