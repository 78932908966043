import {Grid} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl, getPreviousDayDate} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {resetState, setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";
import {format, parseISO} from "date-fns";
import moment from "moment";

const MenusMasterList = (props) => {
    const {search, filterChips, serviceType} = props;
    const [open, setOpen] = React.useState(false);
    const [brandListing, setBrandListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "menu_name");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const headerName = urlSearchParams.get("menuName");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [availabilityCount, setAvailabilityCount] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MENU.STATUS(values.menu_id));

        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: JSON.stringify(isActive)
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleDuplicate = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MENU.DUPLICATE(values.menu_id));
        const response = await instance.request();

        if (response.success) {
            const menuId = response?.data?.menu?.menu_id;

            if (menuId) {
                navigate(`/menus/menuDetail/${menuId}/?tab=menu&edit=yes&menuName=${response?.data?.menu?.menu_name}`);
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                navigate(`/menus/menuDetail/${row.menu_id}/?tab=menu&edit=yes&menuName=${row.menu_name}`);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Duplicate": {
                handleDuplicate(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getMenuList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(serviceType === "Catering" ? API.MENU.GET : API.MENU.GETHOMEMEAL);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.menu_name = search;
            setBrandListing([]);
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempList = [];
            response.data.menuList.forEach((row) => {
                const tempObj = {};
                if (serviceType === "Catering") {
                    tempObj.brand_id = row.brand_id;
                    tempObj.brandName = row.brand.brand_name;
                    tempObj.menu_name = row.menu.menu_name;
                    tempObj.abbreviation = row.brand.abbreviation;
                    tempObj.brandServices = row.brand.brandServices;
                    if (row.menu.is_active === "false") {
                        tempObj.is_active = false;
                    } else {
                        tempObj.is_active = true;
                    }
                    tempObj.menu_id = row?.menu_id;
                    tempObj.createdAt = row?.createdAt;

                    tempList.push(tempObj);
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (
                        row?.service_type === "Home Meals" &&
                        row?.menu_type === "Regular" &&
                        row?.menuHomeMealsSchedule &&
                        row?.menuHomeMealsSchedule.length > 0
                    ) {
                        const sortedSchedules = [...row.menuHomeMealsSchedule].sort((a, b) => {
                            const weekStartA = new Date(a?.startDate).getTime();
                            const weekStartB = new Date(b?.startDate).getTime(); 
                            return weekStartA - weekStartB;
                        });
                        
                        sortedSchedules.forEach((schedule) => {
                            const tempObj = {};
                            const weekStart = parseISO(schedule?.startDate);
                            const weekEnd = parseISO(schedule?.endDate);
                            tempObj.menu_name = row?.menu_name;
                            tempObj.menu_display = `Week of ${format(weekStart, "MMM d")} - ${format(weekEnd, "MMM d")}`;
                            tempObj.homemeal_menu_type = row?.menu_type;
                            tempObj.menu_locations = row?.menuScheduleKitchenLocation;
                            tempObj.menu_start = moment(schedule?.startDate).subtract(availabilityCount, "weeks").toISOString();
                            tempObj.menu_cutoff = row?.menuScheduleKitchenLocation?.map((location) => ({
                                day: location?.kitchenLocation?.hm_cut_off_day,
                                time: location?.kitchenLocation?.hm_cut_off_time,
                                date: getPreviousDayDate(location?.kitchenLocation?.hm_cut_off_day, moment(schedule?.startDate))
                            }));
                            tempObj.menu_id = row?.menu_id;
                            tempObj.createdAt = row?.createdAt;
                            tempObj.is_active = row.is_active === "true";
                            tempList.push(tempObj);
                        });
                    } else {
                        const tempObj = {};
                        tempObj.menu_name = row?.menu_name;
                        tempObj.menu_display = row?.menu_name_external;
                        tempObj.homemeal_menu_type = row?.menu_type;
                        tempObj.menu_locations = row?.menuScheduleKitchenLocation;
                        tempObj.menu_start = row?.availability_date;
                        tempObj.menu_cutoff = row?.cut_off_date_time;
                        tempObj.menu_id = row?.menu_id;
                        tempObj.createdAt = row?.createdAt;
                        tempObj.is_active = row.is_active === "true";
                        tempList.push(tempObj);
                    }
                }
            });

            setBrandListing(tempList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.MENU.DELETE(selectedRow.menu_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getMenuList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    const getHomeMealAvailabilityFee = async () => {
        setLoading(true);

        const instance = NetworkManager(API.SERVICE.GETHOMEMEALSERVICE);
        const response = await instance.request();
        if (response?.success) {
            const availabilityDay = response?.data?.homeMealServiceFee?.availability?.availability;
            setAvailabilityCount(availabilityDay);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getMenuList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, serviceType]);

    useEffect(() => {
        getHomeMealAvailabilityFee();
    }, []);
    const getBrandList = async () => {
        let tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const BrandData = response.data.brandList.map((el) => ({id: `${el.brand_id}`, label: el.brand_name}));
            tempData = [...BrandData];
        }
        return tempData;
    };

    // const handleApplyFilter = (Morefilters) => {
    //     // setFilterChips(Morefilters);
    //     setCurrentPage(1);
    // };

    const headerData =
        serviceType === "Catering"
            ? [
                  {key: "menu_name", label: "Menu"},
                  {key: "brandName", label: "Brand"},
                  {key: "createdAt", label: "Created"},
                  {key: "is_active", label: "Status"},
                  WithPermission({
                      module: "Menu",
                      permission: ["delete_permission", "create_permission", "update_permission"]
                  }) && {
                      key: "action",
                      label: "Action"
                  }
              ].filter((el) => el)
            : [
                  {key: "menu_name", label: "Menu"},
                  {key: "menu_display", label: "Display"},
                  {key: "homemeal_menu_type", label: "Type"},
                  {key: "menu_locations", label: "Locations"},
                  {key: "menu_start", label: "Start"},
                  {key: "menu_cutoff", label: "Cut-off"},
                  {key: "createdAt", label: "Created"},
                  {key: "is_active", label: "Status"},
                  WithPermission({
                      module: "Menu",
                      permission: ["delete_permission", "create_permission", "update_permission"]
                  }) && {
                      key: "action",
                      label: "Action"
                  }
              ].filter((el) => el);
    return (
        <>
            {/* {showFilter && <Filters
                filterState={filterState}
                filterChips={filterChips}
                setFilterChips={setFilterChips}
                handleApplyFilter={handleApplyFilter}
            /> } */}
            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        // setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        loading={loading}
                        data={brandListing}
                        currentTab="brands"
                        header={headerData}
                        actions={[
                            WithPermission({module: "Menu", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Menu", permission: "create_permission"}) && "Duplicate",
                            WithPermission({module: "Menu", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        height="75vh"
                        sortingLabel={["Menu", "Brand", "Created", "Status"]}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this menu?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default MenusMasterList;
