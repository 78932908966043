import React, {useState, useEffect} from "react";
import {Grid} from "@mui/material";
import {useLocation, useSearchParams} from "react-router-dom";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import useTempDrawerModifier from "hooks/useTempDrawerModifier";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import FilterChips from "commonComponent/FilterChipsComponent";
import NewAddModifierGroup from "commonComponent/ModifierComponent/AddModifierGroup/NewAddModifierGroup";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {setPageNumber, setRowsPerPage, setShowFilter, setOrder, setOrderBy} from "store/slices/paginationSlice";
import AddEditIncludedGroup from "commonComponent/IncludedGroupComponents/AddEditIncludedGroup";

const IncludedGroup = (props) => {
    const {search, showFilter, setHeading = () => {}, filterChips} = props;
    const [open, setOpen] = React.useState(false);
    const [includedGroupListing, setIncludedGroupListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "included_group_name");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const ModifierGroupID = searchParams.get("id");
    const {toggleDrawer: toggleDrawerModifier} = useTempDrawerModifier();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };
    const handleStatus = async (values, type) => {
        let key;
        if (!type) {
            key = "delete";
        } else {
            key = type?.toLowerCase();
        }
        const instance = NetworkManager(API.INCLUDED_GROUPS.PATCH(values?.included_group_id, key));

        const response = await instance.request();

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
            if (!type) {
                handleDeleteModal();
            }
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        setShowFilter(false);
        handleSelectedRow(row);

        handleClose();
        switch (type) {
            case "Edit": {
                dispatch(setShowFilter(false));
                toggleDrawerModifier("right", "addIncludedGroup")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const getModifiersList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.INCLUDED_GROUPS.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.included_group_name = search;
            setIncludedGroupListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            const dataList = response.data?.includedGroupList;
            setIncludedGroupListing(dataList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getModifiersList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, ModifierGroupID, filterChips]);

    return (
        <>
            <Grid container>
                <>
                    {showFilter ? null : (
                        <AddEditIncludedGroup
                            selectedRow={selectedRow}
                            handleSelectedRow={handleSelectedRow}
                            handleIsEdited={handleIsEdited}
                            title={selectedRow ? "Edit included group" : "Add included group"}
                        />
                    )}
                    <Grid item xs={12}>
                        {/* <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                        /> */}
                        <CommonTable
                            path={location}
                            loading={loading}
                            data={includedGroupListing}
                            currentTab="brands"
                            header={[
                                {key: "included_group_name", label: "Included group"},
                                {key: "includedItems", label: "Included items"},
                                {key: "menuItem", label: "Menu items"},
                                {key: "createdAt", label: "Created"},
                                {key: "is_active", label: "Status"},
                                WithPermission({
                                    module: "IncludedGroup",
                                    permission: ["delete_permission", "update_permission"]
                                }) && {
                                    key: "action",
                                    label: "Action"
                                }
                            ].filter((el) => el)}
                            actions={[
                                WithPermission({module: "IncludedGroup", permission: "update_permission"}) && "Edit",

                                WithPermission({module: "IncludedGroup", permission: "delete_permission"}) && "Delete"
                            ]}
                            height="75vh"
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={["Included group", "Created", "Status"]}
                        />
                    </Grid>
                </>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this included group? "
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={() => handleStatus(selectedRow)}
            />
        </>
    );
};

export default IncludedGroup;
