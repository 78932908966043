import {Box, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, FieldArray, Formik} from "formik";
import React, {useCallback, useEffect, useRef, useState} from "react";
import DietaryOptions from "commonComponent/ModifierComponent/DietaryPopup/DietaryPopup";
import {enforceFormat, twoDigitAfterDecimal, enforceFormatFor3Digits, enforceFormatHandleDecimal} from "utils/helperFunctions/helpers";
import * as yup from "yup";
import networkManager from "network/core/networkManager";
import {API} from "network/core";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {validationContant} from "utils/validations/validationConstants";
import _debounce from "lodash/debounce";
import {useStyles} from "../../ModifierComponent/AddModifier/modifierStyles";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import GreenIcon from "assets/images/AddIconGreen.svg";

const defaultValue = {
    name: "",
    dietary: [],
    preparation: new Set(),
    category: "",
    id: ""
};

const AddIncludedItems = (props) => {
    const {
        modifierGroupId,
        parentFormik,
        allowedSplit,
        hidePrice,
        placeHolder = null,
        handlePrepSelect,
        methodsofPreparation,
        categoryList
    } = props;
    const style = useStyles();
    const [initialValues, setInitialValues] = useState(defaultValue);

    const closeRef = useRef(null);

    const [existingModifier, setExisitingModifiers] = useState([]);
    const [searchedVlaue, setSearchedValue] = useState("");

    const handleSearch = (e) => {
        setSearchedValue(e.target.value);
    };

    const debounceFn = useCallback(_debounce(handleSearch, 800), []);

    const getExisitingModifier = async () => {
        const instance = networkManager(API.ITEM.GET);
        const response = await instance.request({}, [`?page=1&pageSize=5000&sortBy=item_name&orderBy=asc&item_name=${searchedVlaue}`]);
        if (response.success) {
            const data = response.data.item;
            setExisitingModifiers(data);
        }
    };

    useEffect(() => {
        if (searchedVlaue) {
            getExisitingModifier();
        } else {
            setExisitingModifiers([]);
        }
    }, [searchedVlaue]);

    const handleDuplicateModifier = async (id) => {
        const instance = networkManager(API.MODIFIER.DUPLICATE(`${id}?modifier_group_id=${modifierGroupId}`));
        const response = await instance.request({});
        if (response.success) {
            const md = response.data;

            const modifierData = {
                isEdit: false,
                id: md.modifier_id,
                name: md.modifier_name,
                price: md.price,
                dietary: md.ModifierDietary.map((el) => el.modifier_dietary)
            };

            const existingModifier = parentFormik.values.modifierOptions;

            parentFormik.setFieldValue("modifierOptions", [modifierData, ...existingModifier]);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setInitialValues(defaultValue);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleAddModifier = async (val, setSubmitting, resetForm) => {
        setSubmitting(true);
        // if (val.id) {
        //     handleDuplicateModifier(val.id);
        // }
        console.log(val, "VALVAL");

        const payload = {
            name: val.name,
            modifier_dietary: val.dietary,
            series_no: parentFormik.values.includedOptions?.length,
            category: val?.category,
            preparation: val?.preparation,
            id: val?.id
        };

        parentFormik.setFieldValue("includedOptions", [...parentFormik.values.includedOptions, payload]);
        resetForm();
        setInitialValues(defaultValue);
    };

    const handleSelectExistingModifier = (obj, values) => {
        setInitialValues({
            name: obj?.item_name,
            id: obj?.item_id,
            dietary: values?.dietary,
            preparation: values?.preparation,
            category: values?.category,
            series_no: parentFormik.values.includedOptions?.length
        });
        setExisitingModifiers([]);
    };

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (!closeRef.current?.contains(e.target)) {
                setExisitingModifiers([]);
            }
        });
    }, []);
    const validationObj = () => {
        return {
            name: yup.string().required("Modifier name is required").max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
            category: yup.string().required("Category is required*"),
            preparation: yup
                .mixed()
                .test("is-set", "Preparation should be a Set", (value) => value instanceof Set) // Ensures it's a Set
                .test("min-items", "Preparation must have at least one item", (value) => value && value.size > 0) // Ensure Set has at least one item
        };
    };
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={yup.object(validationObj())}
            onSubmit={(val, {setSubmitting, resetForm}) => {
                handleAddModifier(val, setSubmitting, resetForm);
            }}
        >
            {(formik) => (
                <Grid container spacing={2} alignItems="center">
                    {console.log(formik, "FORMIKFORMIK174")}
                    <Grid item xs={11}>
                        <Stack direction="row" alignItems="center" mb={1}>
                            <img src={GreenIcon} alt="" style={{height: "18px", width: "18px", marginRight: "6px"}} />
                            <Box sx={{width: "100%"}}>
                                <Box sx={{position: "relative"}} ref={closeRef}>
                                    <CustomTextField
                                        sx={style.addInputs}
                                        {...props}
                                        fullWidth
                                        name="name"
                                        label={placeHolder ?? ""}
                                        value={formik.values.name}
                                        onChange={(e) => {
                                            debounceFn(e);
                                            formik.setFieldValue("name", e.target.value);
                                        }}
                                        inputProps={{
                                            maxLength: 80
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={formik.handleSubmit}>
                                                        <Typography
                                                            variant="checkBoxSelected"
                                                            fontSize="14px"
                                                            sx={(t) => ({color: t.palette.primary.main})}
                                                        >
                                                            Add
                                                        </Typography>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        height
                                    />
                                    {!!existingModifier?.length && (
                                        <Box sx={style.existingModifier}>
                                            {existingModifier?.map((obj, i) => (
                                                <Box
                                                    key={i}
                                                    sx={style.modItem}
                                                    onClick={() => {
                                                        handleSelectExistingModifier(obj, formik?.values);
                                                    }}
                                                >
                                                    <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                        {obj?.item_name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        <FieldArray
                            name="dietary"
                            render={(arrayHelper) => (
                                <DietaryOptions
                                    haslength={formik.values.dietary.length}
                                    checked={(obj) => formik.values.dietary.includes(obj.value)}
                                    onChange={(obj) => {
                                        if (formik.values.dietary.length && formik.values.dietary.includes(obj.value)) {
                                            const valIndex = formik.values.dietary.findIndex((el) => el === obj.value);
                                            arrayHelper.remove(valIndex);
                                        } else {
                                            arrayHelper.push(obj.value);
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7} mt={-2} mb={2} ml={3}>
                        <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                    <Box pl={5} mt={-1} width="100%" display="flex" alignItems="center">
                        <Box pr={2} width="60%">
                            <CustomSelect
                                label="Category"
                                menuItems={categoryList}
                                fullWidth
                                
                                {...formik.getFieldProps("category")}
                                inputProps={{maxLength: 250}}
                                value={formik.values.category}
                                // name='category'
                                onChange={(e) => {
                                    const value = e.target.value;

                                    formik.setFieldValue("category", value);
                                }}
                            />
                            <ErrorMessage name="category" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Box>
                        <Box>
                            <FormGroup sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}} name="preparation">
                                {Array.from(methodsofPreparation).map((obj, index) => (
                                    <Box key={obj} direction="row" p={0} width="max-content">
                                        <FormControlLabel
                                            sx={{height: 30}}
                                            // disabled={!isEdit}
                                            checked={formik.values.preparation.has(obj)}
                                            key={obj}
                                            control={
                                                <Checkbox
                                                    disableRipple
                                                    icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                                    checkedIcon={
                                                        <img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    fontSize="15px"
                                                    variant={
                                                        formik.values.preparation.size > 0 && formik.values.preparation.has(obj)
                                                            ? "checkBoxSelected"
                                                            : "checkBoxUnSelected"
                                                    }
                                                >
                                                    {obj}
                                                </Typography>
                                            }
                                            onChange={(e) => handlePrepSelect(obj, formik)}
                                        />
                                    </Box>
                                ))}
                            </FormGroup>
                            <ErrorMessage name="preparation" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Box>
                    </Box>
                </Grid>
            )}
        </Formik>
    );
};

export default AddIncludedItems;
