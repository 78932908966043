import React, {useState} from "react";
import Tooltip from "commonComponent/Tooltip";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {getformateDateUS} from "utils/app-dates/dates";
import {formatDateToAMPM} from "utils/helperFunctions/helpers";

const CutoffDisplay = ({cutOffData}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const cutOffItems = Array.isArray(cutOffData) ? cutOffData.filter((item) => item.date && item.time) : [];

    const initialMenuItem = cutOffItems.length > 0 ? cutOffItems[0] : null;

    const tooltipContent = cutOffItems.slice(1).map((item) => (
        <Stack spacing={1} sx={{minWidth: "60px", maxWidth: "60px"}} key={item.date + item.time}>
            <Typography variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                {getformateDateUS(item.date)} {item.date === null && "N/A"}
            </Typography>
            <Typography
                variant="secondaryDataText"
                fontWeight={400}
                fontSize="0.69em"
                sx={(t) => ({
                    color: t.palette.secondary[600],
                    opacity: 0.6,
                    fontSize: "11px"
                })}
            >
                {item.time ? formatDateToAMPM(item.time) : <span>&nbsp;</span>}
            </Typography>
        </Stack>
    ));

    return (
        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
            <div>
                {/* Display first date and time item */}
                {initialMenuItem && (
                    <Stack spacing={1} sx={{minWidth: "60px", maxWidth: "60px"}}>
                        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "12px"}}>
                            {getformateDateUS(initialMenuItem.date)} {initialMenuItem.date === null && "N/A"}
                        </Typography>
                        <Typography
                            variant="secondaryDataText"
                            fontWeight={400}
                            fontSize="0.69em"
                            sx={(t) => ({
                                color: t.palette.secondary[600],
                                opacity: 0.6,
                                fontSize: "11px"
                            })}
                        >
                            {initialMenuItem.time ? formatDateToAMPM(initialMenuItem.time) : <span>&nbsp;</span>}
                        </Typography>
                    </Stack>
                )}

                {/* Tooltip for additional items */}
                {cutOffItems.length > 1 && (
                    <Tooltip
                        title={<div>{tooltipContent}</div>}
                        arrow
                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, fontSize: "11px"}}>
                            &nbsp;+{cutOffItems.length - 1} more
                        </small>
                    </Tooltip>
                )}
            </div>
        </Typography>
    );
};

export default CutoffDisplay;
