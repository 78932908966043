import {Box, Grid, Stack, Link, Button, Typography, InputAdornment} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {useSearchParams} from "react-router-dom";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {ErrorMessage, Form, Formik} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useEffect, useState} from "react";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import {RefundValidtaion} from "utils/validations/validations";
import Loader from "ui-component/Loader";
import {FormatCurrency, enforceFormat} from "utils/helperFunctions/helpers";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import WarningAmber from "@mui/icons-material/WarningAmber";
import OrderDetails from "views/pages/privatePages/reports/Detail";

const formSchema = {
    type: "Full",
    method: "",
    selectedItem: [],
    reason: "",
    description: "",
    partialAmount: "",
    partialAmountType: "amount"
};

const RefundForm = (props) => {
    const {
        handleSelectedRow,
        customerId,
        orderId,
        orderNumber,
        refundMethod,
        orderData,
        fromEditScreen = "",
        refundAmount,
        type = "Full"
    } = props;

    const [searchparam] = useSearchParams();
    const {toggleDrawer} = useTempDrawer();
    const [orderItems, setOrderitems] = React.useState([]);
    const [orderDetail, setOrderDetail] = React.useState(null);
    const [itemObject, setItemObject] = React.useState({});
    const [totalAmount, setTotalAmount] = React.useState(null);
    const [openCancelModal, setCancelModal] = useState(false);
    const [amountCharged, setAmountCharged] = useState(false);
    const [initialValues, setInitialValues] = React.useState(formSchema);

    const [loading, setLoading] = useState(false);
    const [submitEvent, setSubmitEvent] = useState(null);

    const dispatch = useDispatch();

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleCancelOrder = async () => {
        setCancelModal(false);
    };

    const getItemRefundableAmount = (itemSelected) => {
        if (itemSelected.length === 0) {
            return 0;
        }
        let refundAmount = 0;

        const filter = orderItems.filter((el) => itemSelected.includes(el.id));

        filter.forEach((el) => {
            refundAmount += el.totalPrice;
        });
        return refundAmount;

        // const values = JSON.parse(JSON.stringify(orderDetail));
        // const hashmap = {};
        // let orderPriceForAllItem = 0;
        // let serviceTaxTotal = 0;
        // let discountedPrice = 0;
        // let salesTaxTotalPrice = 0;
        // let overAllPrice = 0;
        // let preferredPriceTotal = 0;

        // itemSelected.forEach((row) => {
        //     hashmap[row] = 1;
        // });

        // const handleItemTotalPrice = () => {
        //     let { itemDetail } = values;
        //     let totalPrice = 0;
        //     itemDetail = itemDetail.filter((col) => hashmap[col.itemId] !== 1)
        //     itemDetail.forEach((obj, itemIndex) => {
        //         let tempPrice = 0;
        //         tempPrice += parseFloat(itemDetail[itemIndex]?.price);
        //         tempPrice *= parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1);
        //         itemDetail[itemIndex].checkboxModifier.forEach((row) => {
        //             if (row.allowQuantityModification) {
        //                 // for custom modifier (means for those we can modify quantity)
        //                 tempPrice += parseFloat(row?.quantity !== "" ? row?.quantity : 1) * parseFloat(row.price);
        //             } else {
        //                 // for modifier that doest not have modifiable quantity
        //                 // for those we have to take item quantity * modifier price
        //                 tempPrice +=
        //                     parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1) *
        //                     parseFloat(row.price);
        //             }
        //         });
        //         itemDetail[itemIndex].radioModifier.forEach((row) => {
        //             const obj = JSON.parse(row);
        //             if (obj.allowQuantityModification) {
        //                 tempPrice += parseFloat(obj?.quantity !== "" ? obj?.quantity : 1) * parseFloat(obj.price);
        //             } else {
        //                 tempPrice +=
        //                     parseFloat(itemDetail[itemIndex].quantity !== "" ? itemDetail[itemIndex].quantity : 1) * parseFloat(obj.price);
        //             }
        //         });

        //         totalPrice += tempPrice;
        //         orderPriceForAllItem = totalPrice;
        //     });

        //     return totalPrice;
        // };
        // handleItemTotalPrice();

        // const calculatePreferredPrice = () => {
        //     let prePrice = 0;
        //     if (values.headerData.preferredDiscountType === "PERCENTAGE") {
        //         prePrice = parseFloat(parseFloat(orderPriceForAllItem) * (parseFloat(values.headerData.preferredDiscountValue) / 100));
        //     } else {
        //         prePrice = parseFloat(values.headerData.preferredDiscountValue);
        //     }
        //     if (Number.isNaN(prePrice)) {
        //         preferredPriceTotal = 0.0;
        //         prePrice = 0.0;
        //     } else {
        //         preferredPriceTotal = prePrice;
        //     }

        //     return parseFloat(prePrice).toFixed(2);
        // }

        // calculatePreferredPrice();

        // const calculateServiceTax = () => {
        //     let servicetax = 0;
        //     if (values.serviceFees.unitType === "percent") {
        //         servicetax = parseFloat(parseFloat(orderPriceForAllItem) * (parseFloat(values.serviceFees.value) / 100));
        //     } else {
        //         servicetax = parseFloat(values.serviceFees.value);
        //     }
        //     if (servicetax < Number(values.kitchenMinimumServiceFee)) {
        //         servicetax = Number(values.kitchenMinimumServiceFee);
        //     }
        //     if (Number.isNaN(servicetax)) {
        //         serviceTaxTotal = 0.0;
        //         servicetax = 0.0;
        //     } else {
        //         serviceTaxTotal = servicetax;
        //     }

        //     return parseFloat(servicetax).toFixed(2);
        // };

        // calculateServiceTax();

        // const calculatePriceAfterDiscount = () => {
        //     const { discountOrFee } = values;
        //     let discountValue = 0;
        //     if (discountOrFee.type === "Percentage" && parseFloat(discountOrFee.value) > 0) {
        //         discountValue = (parseFloat(discountOrFee.value) / 100) * parseFloat(orderPriceForAllItem);
        //     } else if (discountOrFee.type === "Amount") {
        //         discountValue = parseFloat(discountOrFee.value);
        //     }
        //     if (Number.isNaN(discountValue)) {
        //         discountedPrice = 0.0;
        //         discountValue = 0.0;
        //     } else {
        //         discountedPrice = discountValue;
        //     }

        //     return parseFloat(discountValue).toFixed(2);
        // };
        // calculatePriceAfterDiscount();

        // const calculateSalesTax = () => {
        //     const { salesTax, isTaxExempts } = values;
        //     const totalPriceToApplyForSalesTax = Number(orderPriceForAllItem) - Number(preferredPriceTotal);
        //     let priceForSalesTax = 0;
        //     if (!isTaxExempts) {
        //         if (parseFloat(salesTax.value) > 0) {
        //             priceForSalesTax = (parseFloat(salesTax.value) / 100) * parseFloat(totalPriceToApplyForSalesTax);
        //         }
        //     }

        //     if (Number.isNaN(priceForSalesTax)) {
        //         salesTaxTotalPrice = 0.0;
        //         priceForSalesTax = 0.0;
        //     } else {
        //         salesTaxTotalPrice = priceForSalesTax;
        //     }

        //     return parseFloat(priceForSalesTax).toFixed(2);
        // };

        // calculateSalesTax();

        // const calculateOverallPrice = () => {
        //     let finalPrice = parseFloat(orderPriceForAllItem) + parseFloat(serviceTaxTotal) + parseFloat(salesTaxTotalPrice);
        //     if (preferredPriceTotal < finalPrice) {
        //         finalPrice -= Number(preferredPriceTotal);
        //     }
        //     if (values.discountOrFee.priceType === "Fee") {
        //         finalPrice += parseFloat(discountedPrice);
        //     } else if (values.discountOrFee.priceType === "Discount") {
        //         finalPrice -= parseFloat(discountedPrice);
        //     }
        //     overAllPrice = finalPrice;
        //     return parseFloat(finalPrice).toFixed(2);
        // };

        // return values.paid_already - calculateOverallPrice();
    };

    const getRefundableAmount = (values) => {
        console.log("values : ", values);
        let refundAmount = 0;
        if (values.type === "Full") {
            refundAmount = totalAmount;
        } else if (values.type === "Partial") {
            if (values.partialAmountType === "percent") {
                const calcualtePercentage = +parseFloat(
                    (parseFloat(values.remainingAmount) * parseFloat(values.partialAmount)) / 100
                ).toFixed(2);
                return calcualtePercentage;
            }
            refundAmount = values.partialAmount;
        } else if (values.type === "Item refund") {
            // refundAmount += getItemRefundableAmount(values.selectedItem)
            values.selectedItem.forEach((item) => {
                refundAmount += itemObject[item]?.totalPrice;
            });
        }
        return refundAmount;
    };

    const disableIssueRefund = (values) => {
        if (orderDetail) {
            if (orderDetail?.remaining_amount_for_refund < getRefundableAmount(values)) {
                return true;
            }
        }
        return false;
    };

    const handleRefund = async (values, setSubmitting, e) => {
        setSubmitting(true);
        setLoading(true);

        const remainingAmount = totalAmount;

        const refundMethod = values.method === "HOUSE_ACCOUNT" ? "House Account" : "Credit Card";

        let payload = {};
        if (values.type === "Full") {
            payload = {
                orderId: orderId,
                refundType: "Full",
                // refundMethod: refundMethod,
                reason: values.reason,
                amount: getRefundableAmount(values),
                url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
                deliveryDate: orderDetail.headerData.when,
                discountOrFee: orderDetail.discountOrFee,

                isTaxExempts: orderDetail.isTaxExempts,
                serviceFees: orderDetail.serviceFees
            };
        }
        if (values.type === "Partial") {
            payload = {
                orderId: orderId,
                refundType: "Partial",

                reason: values.reason,
                amount: getRefundableAmount(values),
                url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
                deliveryDate: new Date(),
                discountOrFee: orderDetail.discountOrFee,

                isTaxExempts: orderDetail.isTaxExempts,
                serviceFees: orderDetail.serviceFees
            };
        }
        if (values.type === "Item refund") {
            payload = {
                orderId: orderId,
                refundType: "Partial",

                reason: values.reason,
                amount: getRefundableAmount(values),
                // itemids: values.selectedItem,
                order_item_id: values.selectedItem,
                url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
                deliveryDate: orderDetail.headerData.when,
                discountOrFee: orderDetail.discountOrFee,

                isTaxExempts: orderDetail.isTaxExempts,
                serviceFees: orderDetail.serviceFees
            };
        }

        if (Number(remainingAmount) >= Number(payload.amount)) {
            const instance = NetworkManager(API.ORDER.REFUND);
            const response = await instance.request(payload);
            if (response.success) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.message,
                        variant: "alert",
                        alert: {
                            color: "success"
                        },
                        close: false
                    })
                );
                toggleDrawer("right", false)();
            } else if (response.error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response.message,
                        variant: "alert",
                        alert: {
                            severity: "error",
                            color: "error"
                        },
                        close: false
                    })
                );
            }
        } else {
            setCancelModal(true);
        }
        setSubmitting(false);
        setLoading(false);
    };

    // useEffect(() => {
    //     setInitialValues({
    //         ...formSchema,
    //         type: type,
    //         method: refundMethod || "",
    //         remainingAmount: orderData?.order?.remaining_amount_for_refund
    //     });
    // }, [refundMethod,type]);

    const getOrderDetailById = async (id) => {
        const schema = {
            orderId: "",
            itemDetail: [],
            orderStatus: "",
            headerData: {
                customer: {
                    name: "",
                    email: "",
                    phone: "",
                    company: "",
                    companyId: "",
                    userId: null,
                    customerAddresses: []
                },
                service: "Catering",
                orderType: "Delivery",
                deliveryTo: "",
                latitude: "",
                longitude: "",
                when: "",
                forHowMany: "",
                preferredDiscountType: "DISCOUNT",
                preferredDiscountValue: 0
            },
            selectedBrand: "",
            brandName: "",
            selectedMenu: "",
            kitchenLocationId: "",
            selectedKitchen: "",
            kitchenLatitude: "",
            kitchenLongitude: "",
            kitchenMinimumServiceFee: 0,
            kitchenList: [],
            categories: "",
            items: [],
            specialInstruction: "",
            kitchenNote: "",
            deliveryInstruction: "",
            drive_time: "",
            setup_time: "",
            leave_by: null,
            isTaxExempts: false,
            discountOrFee: {
                type: "Percentage",
                priceType: "Discount",
                value: 0,
                description: ""
            },
            serviceFees: {
                unitType: "percent",
                value: 0
            },
            salesTax: {value: 0, percent: 0},
            discountedPrice: 0,
            serviceTaxTotal: 0,
            orderPriceForAllItem: 0,
            salesTaxTotalPrice: 0,
            totalPriceAfterCalculation: 0,
            perPersonOrderItem: 0,
            perPersonTotal: 0,
            clickOnSaveDraft: false,
            isSaved: false,
            is_payment_initiated: false,
            paid_already: 0,
            itemModifier: {},
            chargedByCardOrHouse: "",
            customerCardId: "",
            tableware: true,
            remaining_amount_to_be_paid: 0,
            total_price: 0,

            advance_deposit_amount: 0,
            is_advance_deposit_enabled: false
        };

        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            const {category, order} = response.data;
            const tempItemList = [];
            const itemDetailList = [];
            const orderDetailItemList = [];
            let numberOfItemsOrdered = 0;
            const overAllSalesTax = order?.sales_tax;
            const overAllServiceTax = order?.service_fee_unit === "percent" ? order?.service_fee_value : 0;
            const overAllPreferredPrice = order?.preferred_price_unit_type === "PERCENTAGE" ? order?.preferred_price : 0;

            // ----------------order detail info-------------
            schema.orderId = order?.order_id;
            schema.orderStatus = order?.status ?? "";
            schema.remaining_amount_for_refund = order?.remaining_amount_for_refund ?? 0;
            schema.total_price = order?.total_price ?? 0;
            schema.advance_deposit_amount = order?.advance_deposit_amount ?? 0;
            schema.is_advance_deposit_enabled = order?.is_advance_deposit_enabled ?? false;

            if (order?.status === "Accepted") {
                schema.is_payment_initiated = order?.is_payment_initiated;
                schema.paid_already = parseFloat(order?.total_price) - parseFloat(order?.remaining_amount_to_be_paid);
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";
            }
            if (
                order?.status === "Active" ||
                order?.status === "Driver Assigned" ||
                order?.status === "ON_THE_WAY" ||
                order?.status === "Needs Driver"
            ) {
                schema.is_payment_initiated = order?.is_payment_initiated;
                schema.paid_already = parseFloat(order?.total_price) - parseFloat(order?.remaining_amount_to_be_paid);
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";
            }
            schema.paid_already = parseFloat(order?.total_price) - parseFloat(order?.remaining_amount_to_be_paid);
            schema.isTaxExempts = order?.tax_exempt;
            schema.headerData.forHowMany = order?.no_of_guest ?? "";

            schema.discountOrFee = {
                type: order?.price_type_unit === "Amount" ? "Amount" : "Percentage",
                priceType: order?.price_type ?? "Discount",
                value: order?.discount_or_fee_unit_value ?? 0,
                description: order?.discount_or_fee_description ?? "Test"
            };

            schema.serviceFees = {
                unitType: order?.service_fee_unit ?? "percent",
                value: order?.service_fee_unit_value ?? 0
            };
            // schema.serviceFeesValue=order?.service_fee_value ?? 0
            schema.kitchenMinimumServiceFee = order?.kitchen_location?.kitchen_minimum_service_fee ?? 0;
            schema.salesTax = {value: order?.kitchen_location?.sales_tax_rate ?? 0, percent: order?.kitchen_location?.sales_tax_rate};
            schema.headerData.preferredDiscountType = order?.preferred_price_unit_type ?? "DISCOUNT";
            schema.headerData.preferredDiscountValue = order?.preferred_price_unit_value ?? 0;
            schema.headerData.preferredPrice = order?.preferred_price ?? 0;
            schema.headerData.when = order?.delivery_date ?? "";

            //  ------------------ order detail --------------------

            // if (order?.amount_charge > 0) {
            //     setAmountCharged(true)
            // }
            // else if (order?.amount_charge === 0) {
            //     setAmountCharged(false)
            // }

            category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    numberOfItemsOrdered += 1;
                });
            });

            category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    tempItemList.push(col.item_id);
                    console.log("col : ", col);
                    // Store order_item_id in the state

                    const tempCheckboxList = [];
                    const tempRadioList = [];
                    let modifierPrice = 0;

                    col.orderItemsModifiers.forEach((modifier) => {
                        if (modifier.modifier_group.is_allow_multiple) {
                            tempCheckboxList.push({
                                modifierGroupId: modifier.modifier_group.modifier_group_id,
                                modifierId: modifier.modifier.modifier_id,
                                itemId: col.item_id,
                                quantity: modifier.quantity,
                                price: parseFloat(modifier.price).toFixed(2),
                                modifierGroupName: modifier.modifier_group.modifier_group_na,
                                allowQuantityModification: modifier.modifier_group.allow_quantity_mo
                            });
                            modifierPrice += Number(modifier.quantity) * Number(modifier.price);
                        } else {
                            tempRadioList.push(
                                JSON.stringify({
                                    modifierGroupId: modifier.modifier_group.modifier_group_id,
                                    modifierId: modifier.modifier.modifier_id,
                                    itemId: col.item_id,
                                    quantity: modifier.quantity,
                                    price: parseFloat(modifier.price).toFixed(2),
                                    modifierGroupName: modifier.modifier_group.modifier_group_na,
                                    allowQuantityModification: modifier.modifier_group.allow_quantity_mo
                                })
                            );
                            modifierPrice += Number(modifier.quantity) * Number(modifier.price);
                        }
                    });

                    console.log(col, "COLUMNCOLUM");

                    itemDetailList.push({
                        price: col?.price ? Number(col.price) + modifierPrice : Number(col.item.price) + modifierPrice,
                        quantity: col.quantity,
                        id: col.item_id,
                        label: `${col.item_name} ($${
                            col?.price
                                ? Number(col.price) + modifierPrice
                                : (Number(Number(col.item.price) * Number(col.quantity)) + modifierPrice).toFixed(2)
                        })`,
                        orderItemId: col.order_item_id
                    });
                    orderDetailItemList.push({
                        checkboxModifier: tempCheckboxList,
                        radioModifier: tempRadioList,
                        price: col?.price ? col?.price : col.item.price,
                        quantity: col.quantity,
                        specialInstruction: col.special_instruction,
                        packaging: [],
                        itemId: col.item_id,
                        itemOrdering: col.item_ordering,
                        menuCategoryId: col.menu_category_id
                    });

                    itemObject[col.item_id] = {
                        totalPrice: col?.price
                            ? Number(col?.price) + modifierPrice
                            : Number(col.item.price) + modifierPrice * Number(col.quantity) + modifierPrice,
                        quantity: col.quantity,
                        price: col?.price ? Number(col?.price) : Number(col.item.price),
                        modifierPrice: modifierPrice
                    };

                    itemObject[col.order_item_id] = {
                        totalPrice: col?.price
                            ? Number(col?.price) + modifierPrice
                            : Number(col.item.price) + modifierPrice * Number(col.quantity) + modifierPrice,
                        quantity: col.quantity,
                        price: col?.price ? Number(col?.price) : Number(col.item.price),
                        modifierPrice: modifierPrice
                    };
                });
            });

            schema.itemDetail = orderDetailItemList;
            setOrderitems(itemDetailList);
            setOrderDetail(schema);
            setInitialValues({
                ...formSchema,
                method: order?.charged_by,
                remainingAmount: order?.remaining_amount_for_refund
            });
            setTotalAmount(order?.remaining_amount_for_refund);
        }
    };

    React.useEffect(() => {
        if (orderId) {
            getOrderDetailById(orderId);
        }
    }, [orderId]);

    return (
        <>
            {loading && <Loader />}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={RefundValidtaion}
                onSubmit={(val, {setSubmitting}) => {
                    handleRefund(val, setSubmitting, submitEvent);
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <TemporaryDrawer
                                sideBarStateName="refund"
                                sx={{zIndex: 1500, maxWidth: "430px"}}
                                handleSelectedRow={handleSelectedRow}
                                resetForm={formik.resetForm}
                                touched={formik.touched}
                            >
                                <Stack sx={{height: "100%"}} justifyContent="flex-start">
                                    <PageHeader
                                        fontSize="1.625rem"
                                        title={
                                            <span style={{padding: 0, lineHeight: "35px"}}>
                                                Refund order
                                                <br />
                                                {`#${orderNumber}`}
                                            </span>
                                        }
                                        showOnlyTitle
                                    />
                                    <Box mt={1} sx={{height: "100%"}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                                <Typography variant="newRefund">
                                                    {orderDetail?.is_advance_deposit_enabled ? "Total amount" : "Total payment received"}
                                                </Typography>
                                                <Typography variant="newRefund">{FormatCurrency(orderDetail?.total_price)}</Typography>
                                            </Grid>
                                            {orderDetail?.is_advance_deposit_enabled && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{display: "flex", justifyContent: "space-between", pt: "8px !important"}}
                                                >
                                                    <Typography variant="newRefund">Eligible refund amount</Typography>
                                                    <Typography variant="newRefund">
                                                        {FormatCurrency(orderDetail?.remaining_amount_for_refund)}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {fromEditScreen === "" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <CustomSelect
                                                            // disabled={marketName || readOnly}
                                                            noNone
                                                            label="Refund type"
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        maxHeight: 200
                                                                    }
                                                                }
                                                            }}
                                                            fullWidth
                                                            menuItems={[
                                                                {
                                                                    label:
                                                                        orderDetail?.remaining_amount_for_refund > 0
                                                                            ? `Full refund (${FormatCurrency(
                                                                                  orderDetail?.remaining_amount_for_refund
                                                                              )})`
                                                                            : "Full refund",
                                                                    id: "Full"
                                                                },
                                                                {
                                                                    label: "Partial refund",
                                                                    id: "Partial"
                                                                },
                                                                {label: "Item refund", id: "Item refund"}
                                                            ]}
                                                            {...formik.getFieldProps("type")}
                                                        />
                                                        <ErrorMessage
                                                            name="type"
                                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                        />
                                                    </Grid>

                                                    {formik.values.type === "Partial" && (
                                                        <Grid container spacing={2} item xs={12}>
                                                            <Grid item xs={6}>
                                                                <CustomSelect
                                                                    noNone
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            sx: {
                                                                                maxHeight: 200
                                                                            }
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                    menuItems={[
                                                                        {
                                                                            label: "$ Amount",
                                                                            id: "amount"
                                                                        },
                                                                        {
                                                                            label: "% Percent",
                                                                            id: "percent"
                                                                        }
                                                                    ]}
                                                                    {...formik.getFieldProps("partialAmountType")}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <CustomTextField
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Typography color="#A0A7B9">
                                                                                    {formik.values.partialAmountType === "amount"
                                                                                        ? "$"
                                                                                        : "%"}
                                                                                </Typography>
                                                                            </InputAdornment>
                                                                        ),
                                                                        inputProps: {
                                                                            min: 0,
                                                                            step: "0.01", // Allow decimals to 2 places
                                                                            pattern: "[0-9]*[.,]?[0-9]+" // Pattern to allow numbers with optional decimal points
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                    type="number" // or "text" if you need more control
                                                                    placeholder=""
                                                                    // onKeyDown={enforceFormat}
                                                                    {...formik.getFieldProps("partialAmount")}
                                                                />

                                                                <ErrorMessage
                                                                    name="partialAmount"
                                                                    render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {formik.values.type === "Item refund" && (
                                                        <Grid item xs={12}>
                                                            <CustomSelectWithCheckbox
                                                                fullWidth
                                                                selectAll
                                                                label="Select order items"
                                                                menuItems={orderItems}
                                                                values={formik.values.selectedItem}
                                                                onChange={(val) => formik.setFieldValue("selectedItem", [...val])}
                                                                fromRefund="true"
                                                            />
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <CustomSelect
                                                    disabled
                                                    noNone
                                                    label="Refund method"
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 200
                                                            }
                                                        }
                                                    }}
                                                    fullWidth
                                                    menuItems={[
                                                        {
                                                            label: "Credit card",
                                                            id: "CREDIT_CARD"
                                                        },
                                                        {
                                                            label: "House account",
                                                            id: "HOUSE_ACCOUNT"
                                                        }
                                                    ]}
                                                    {...formik.getFieldProps("method")}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomSelect
                                                    // disabled={marketName || readOnly}
                                                    noNone
                                                    label="Select Reason"
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxHeight: 200
                                                            }
                                                        }
                                                    }}
                                                    ellipSizeNumber={50}
                                                    fullWidth
                                                    numberValidation
                                                    menuItems={[
                                                        {
                                                            label: "Product quality issue",
                                                            id: "quality"
                                                        },
                                                        {
                                                            label: "Wrong item was sent",
                                                            id: "Wrong item"
                                                        },
                                                        {label: "Inaccurate quantity sent", id: "Inaccurate quantity"},
                                                        {
                                                            label: "Order arrived late or not at all",
                                                            id: "late"
                                                        },
                                                        {
                                                            label: "Better price available",
                                                            id: "Better price"
                                                        },
                                                        {
                                                            label: "No longer needed",
                                                            id: "not needed"
                                                        },
                                                        {
                                                            label: "Ordered by mistake",
                                                            id: "Ordered by mistake"
                                                        },
                                                        {
                                                            label: "Other",
                                                            id: "other"
                                                        }
                                                    ]}
                                                    {...formik.getFieldProps("reason")}
                                                />
                                                <ErrorMessage name="reason" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                            </Grid>

                                            {formik.values.reason === "other" && (
                                                <Grid item xs={12}>
                                                    <CustomTextField
                                                        multiline
                                                        minRows={2.5}
                                                        sx={{"&": {height: "fit-content !important"}}}
                                                        fullWidth
                                                        placeholder="Specify reason for refund"
                                                        inputProps={{maxLength: 1000}}
                                                        label="Specify reason for refund"
                                                        {...formik.getFieldProps("description")}
                                                    />
                                                    <ErrorMessage
                                                        name="description"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item xs={11.8} mt={1} sx={{borderRadius: "4px", padding: "2px"}}>
                                            <Typography variant="subDetail1" fontSize="0.813rem" color="#020A11" fontWeight={500}>
                                                <Stack sx={{display: "flex", flexDirection: "row", alignItems: "center", color: "#FFC107"}}>
                                                    <Stack>
                                                        {" "}
                                                        <WarningAmber sx={{fontSize: "1.213em"}} ml={1.5} />
                                                    </Stack>
                                                    <Stack ml={0.5}>
                                                        Remaining amount to refund{" "}
                                                        {FormatCurrency(orderDetail?.remaining_amount_for_refund)}
                                                    </Stack>
                                                </Stack>
                                            </Typography>
                                        </Grid>
                                    </Box>
                                    <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 0, bgcolor: "#fff", zIndex: 1}}>
                                        <Grid container justifyContent="flex-end" alignItems="center">
                                            <Grid
                                                item
                                                xs={12}
                                                spacing={1}
                                                sx={{textAlign: "left", display: "flex", justifyContent: "flex-end", flexDirection: "row"}}
                                                mt={0.8}
                                            >
                                                <ButtonGrouping
                                                    justifyContent="center"
                                                    sx={{
                                                        height: "35px"
                                                    }}
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark}),
                                                            onClick: (e) => {
                                                                formik.resetForm();
                                                                toggleDrawer("right", false)(e);
                                                                handleSelectedRow();
                                                            }
                                                        }
                                                    ]}
                                                />
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    disabled={formik.values.remainingAmount === 0.0 || disableIssueRefund(formik.values)} // Disable if formik.values is 0.0
                                                    sx={{
                                                        width: "206px",
                                                        height: "35px"
                                                    }}
                                                    onClick={() => formik.handleSubmit()}
                                                >
                                                    <Stack direction="row" justifyContent="space-between" sx={{width: "100%"}}>
                                                        <Typography fontSize="0.875rem" fontWeight={500} sx={{color: "#FCFCFC"}}>
                                                            Issue refund
                                                        </Typography>
                                                        <Typography fontSize="0.875rem" fontWeight={500} sx={{color: "#FCFCFC"}}>
                                                            {FormatCurrency(getRefundableAmount(formik.values))}
                                                        </Typography>
                                                    </Stack>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </TemporaryDrawer>
                        </Form>
                    );
                }}
            </Formik>
            {openCancelModal && (
                <DeleteModal
                    open={openCancelModal}
                    buttonTitle="Confirm"
                    title="Amount is not refundable!"
                    description={`Your remaining amount for refund is ${FormatCurrency(totalAmount)}`}
                    handleClose={handleCancelModal}
                    handleSubmit={handleCancelOrder}
                />
            )}
        </>
    );
};

export default RefundForm;
