/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import moment from "moment";
import dayjs from "dayjs";
import "moment-timezone";

export function Dates() {
    function addInCurrent(amount, unit = "minutes") {
        return moment(new Date()).add(amount, unit);
    }

    return {
        addInCurrent
    };
}

export function getformateDate(date, format = "dd/mm/yyyy") {
    if (date) {
        const today = new Date(date);
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        switch (format) {
            case "dd/mm/yyyy":
                return `${dd}/${mm}/${yyyy}`;
            case "dd/mm/yy":
                return `${dd}/${mm}/${yyyy.toString().slice(-2)}`;
            case "yyyy-mm-dd":
            case "mm/dd/yyyy":
                return `${mm}/${dd}/${yyyy}`;
            default:
                return `${mm}/${dd}/${yyyy}`;
        }
    }
    return "";
}

export function getDayjsFormat(date, format = "M/DD/YYYY") {
    return dayjs(date).format(format);
}

export function getMonDateYear(date) {
    const utcDateString = date;
    const utcDate = new Date(utcDateString);

    // Convert UTC date to local timezone
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

    // Format the local date
    const formattedDate = localDate.toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"});
    return formattedDate.replace(/,/g, ""); // Remove any commas
}

export function getHHMMTime(time) {
    const today = new Date(time);
    let minutes = today?.getMinutes();
    let hour = today?.getHours();
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (hour < 10) {
        hour = `0${hour}`;
    }

    return `${hour}:${minutes}`;
}

export function convertDaysToHHMM(days) {
    if (days >= 1) {
        const wholeDays = Math.floor(days);
        const hours = Math.floor((days % 1) * 24);
        const minutes = Math.floor((((days % 1) * 24) % 1) * 60);

        const dayPart = `${wholeDays} ${wholeDays === 1 ? "day" : "days"}`;
        const hourPart = `${hours} ${hours === 1 ? "hour" : "hours"}`;
        const minutePart = `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;

        if (minutes > 0) {
            return `${dayPart}, ${hourPart}, ${minutePart}`;
        } else {
            return `${dayPart}, ${hourPart}`;
        }
    } else {
        const hours = Math.floor(days * 24);
        const minutes = Math.round((days * 24 * 60) % 60);

        const hourPart = `${hours} ${hours === 1 ? "hour" : "hours"}`;
        const minutePart = `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;

        if (minutes > 0) {
            return `${hourPart}, ${minutePart}`;
        } else {
            return `${hourPart}`;
        }
    }
}

export const getFormattedTimeinAMPM = (timestamp) => {
    console.log(timestamp, "timestamp");
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${ampm}`;
    // if (time) {
    //     const str = new Date(time).toLocaleString("en-US", {
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true
    //     });
    //     return str;
    // }
    // return "";
};

export function getHHMMWithTimeString(time) {
    const now = new Date();
    const nowDateTime = now.toISOString();
    const nowDate = nowDateTime.split("T")[0];
    const hms = time;
    const target = new Date(`${nowDate}T${hms}`);
    return getFormattedTimeinAMPM(target);
}

export function getformateDateUS(date, format) {
    if (date) {
        const today = new Date(date);
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        switch (format) {
            case "dd/mm/yyyy":
                return `${dd}/${mm}/${yyyy}`;
            case "yyyy/mm/dd":
                return `${yyyy}/${mm}/${dd}`;
            default:
                return `${mm}/${dd}/${yyyy}`;
        }
    }
    return "";
}

export function convertToUTC(date) {
    const UTC = date ? moment(date).toISOString() : null;
    return UTC;
}

export function convertToLocal(date) {
    const local = date ? moment(date).toLocaleString() : null;
    return local;
}

export function getDateTimeByTimezone(date, timezone, format) {
    const targetDate = new Date(date);
    if (!isNaN(targetDate.getTime())) {
        const formatter = new Intl.DateTimeFormat("en-US", {
            timeZone: timezone || "America/Chicago",
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        });

        const parts = formatter.formatToParts(targetDate);
        const dateParts = {};

        parts.forEach((part) => {
            dateParts[part.type] = part.value;
        });

        switch (format) {
            case "dd/mm/yyyy":
                return `${parseInt(dateParts.day, 10)}/${dateParts.month}/${dateParts.year}`;
            case "yyyy/mm/dd":
                return `${dateParts.year}/${dateParts.month}/${parseInt(dateParts.day, 10)}`;
            default:
                return `${dateParts.month}/${parseInt(dateParts.day, 10)}/${dateParts.year}`;
        }
    }
    return "";
}

// Examples to demonstrate
function splitDateTimeOffset(inputString) {
    // Use a regular expression to split at either '-' or '+' before the offset
    const regex = /([+-]?\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}:\d{2})/;

    const matches = inputString.match(regex);

    if (matches) {
        // Return an array with the date-time part and the timezone offset part
        return [matches[1], matches[2]];
    } else {
        // Return null if the input string doesn't match the expected format
        return null;
    }
}
export const getTzDateTime = (tz, date) => {
    const originalDate = new Date(date);

    const options = {
        hourCycle: "h23",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };
    // Create the time object in the specified timezone
    const timeInTimezone = moment.tz(date, tz);
    const formatForOffSet = timeInTimezone.format();
    const result = splitDateTimeOffset(formatForOffSet);

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(originalDate);
    const formattedDate = `${parts.find((p) => p.type === "year").value}-${parts.find((p) => p.type === "month").value}-${
        parts.find((p) => p.type === "day").value
    }T${parts.find((p) => p.type === "hour").value}:${parts.find((p) => p.type === "minute").value}:${
        parts.find((p) => p.type === "second").value
    }${result[1]}`;
    return formattedDate;
};

export function convertIsoToCST(time, timezone) {
    const options = {
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: timezone
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(time));
}
