/* eslint-disable no-restricted-globals */
// import {Reorder} from "commonComponent/Dragger/utils";
import useTempDrawerModifier from "hooks/useTempDrawerModifier";
import {API, NetworkManager} from "network/core";
import React, {useEffect, useState} from "react";

const useIdentifierGroupController = (props) => {
    const {formik} = props;

    const [identiferGroup, setIdentiferGroup] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const {toggleDrawer: toggleDrawerModifier} = useTempDrawerModifier();
    const handleSelectedRow = (val) => {
        setSelectedRow(val);
    };

    const handleIsEdited = () => {
        setRefetch((prev) => !prev);
    };
    const Reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = (result) => {
        // If dropped outside the list, do nothing
        if (!result.destination) return;

        // Check if the dragged type is "QUESTIONS"
        if (result.type === "QUESTIONS") {
            // Reorder the groups
            const reorderedGroups = Reorder(formik.values.includedGroups, result.source.index, result.destination.index);

            // Update the formik values
            formik.setFieldValue("includedGroups", reorderedGroups);
        } else {
            // Reordering answers within a specific group
            const groupIndex = parseInt(result.type, 10); // Ensure the group index is a valid number
            const group = formik.values.includedGroups[groupIndex];

            // Handle cases where group data might not be structured as expected
            if (!group || !group.answers) {
                console.error("Invalid group or answers structure in formik values.");
                return;
            }

            // Reorder the answers within the selected group
            const reorderedAnswers = Reorder(group.answers, result.source.index, result.destination.index);

            // Update the specific group with reordered answers
            const updatedGroups = [...formik.values.includedGroups];
            updatedGroups[groupIndex] = {
                ...group,
                answers: reorderedAnswers
            };

            // Set the updated group values
            formik.setFieldValue("includedGroups", updatedGroups);
        }
    };

    const handleActionBtn = (type, row, handleClose, arrayHelper, index) => (e) => {
        handleSelectedRow(row);
        setSelectedIndex(index);
        handleClose();
        switch (type) {
            case "Edit": {
                toggleDrawerModifier("right", "addIncludedGroup")(e);
                break;
            }
            case "Remove": {
                arrayHelper.remove(index);
                break;
            }
            default:
                break;
        }
    };

    const getIdentifiersGroupList = async () => {
        const instance = NetworkManager(API.INCLUDED_GROUPS.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "included_group_name",
            orderBy: "asc",
            is_active: true
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const identifiersList = response.data.includedGroupList.map((state) => ({
                label: state.included_group_name,
                id: state.included_group_id,
                ...state
            }));
            setIdentiferGroup(identifiersList);
        }
    };

    useEffect(() => {
        getIdentifiersGroupList();
    }, [refetch]);
    return {
        identiferGroup,
        handleActionBtn,
        onDragEnd,
        selectedRow,
        handleSelectedRow,
        handleIsEdited,
        // getModifiersGroupList,
        selectedIndex
    };
};

export default useIdentifierGroupController;
