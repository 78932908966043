import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBarModifier";
import { Form, Formik } from "formik";
import React from "react";
import ModifierGroupDetail from "./ModifierGroupDetail/ModifierGroupDetail";
import ModifierSection from "./ModifierSection/ModifierSection";
import RulesSection from "./rulesSection/RulesSection";
import useAddNewModifierGroupController from "./addNewModifierGroup.controller";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import { NewAddModifierFlowValidation } from "utils/validations/validations";
import useTempDrawer from "hooks/useTempDrawerModifier";
import Loader from "ui-component/Loader";

const NewAddModifierGroup = (props) => {
    const { selectedRow, handleSelectedRow, fromItemFlow, handleIsEdited } = props;
    const { toggleDrawer,state } = useTempDrawer();

    const NAMGC = useAddNewModifierGroupController(props);
    const { loading, initialState, setinitialState, defaultState, handleCreateNewModifierGroup, handleModifierGroupUpdate } = NAMGC;

    const dynamicValidation = (values) => {
        const error = {
            minimum: ''
        };

        if (values?.minimum && values?.minimum > values?.modifierOptions.length) {
            error.minimum = "Minimum should be less than or equal to modifier option"

        }
        if (values?.maximum && values?.minimum > values?.maximum) {
            error.minimum = "Minimum should be less than maximum selection"

        }



        if (error.minimum === '') {
            return {};
        }

        return error;
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialState}
                validationSchema={NewAddModifierFlowValidation}
                validate={dynamicValidation}
                onSubmit={(val, { setSubmitting }) =>
                    val.modifierGroupId ? handleModifierGroupUpdate(val, setSubmitting) : handleCreateNewModifierGroup(val, setSubmitting)
                }
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer
                            sideBarStateName="addModifierGroup"
                            sx={{ pb: 1, pr: 0 }}
                            handleSelectedRow={handleSelectedRow}
                            resetForm={formik.resetForm}
                            whileCloseDoThis={() => {
                                if (formik.values.modifierGroupId) {
                                    handleIsEdited();
                                    setinitialState(defaultState);
                                }
                            }}
                        >
                            <Stack sx={{ height: "100%", position: "relative" }}>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <Box sx={{ overflow: "scroll", height: "92%", pr: 2 }}>
                                        <Box sx={{ pb: 2 }}>
                                            <Typography sx={{ fontSize: "1.625rem", fontWeight: 700, marginBottom: "0.5rem" }}>
                                                {selectedRow?.modifier_group_id ? "Edit modifier group" : "Add modifier group"}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Grid container spacing={2.5}>
                                                {/* modifierDetail */}
                                                <Grid item xs={12}>
                                                    <ModifierGroupDetail formik={formik} />
                                                </Grid>

                                                {formik.values.modifierGroupId && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>

                                                        {/* modifiers */}
                                                        <Grid item xs={12}>
                                                            <ModifierSection formik={formik} />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>

                                                        {/* rules */}
                                                        <Grid item xs={12}>
                                                            <RulesSection formik={formik} />
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={{ position: "absolute", bottom: 0, width: "100%", pr: 2 }}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({ color: t.palette.error.dark }),
                                                onClick: (e) => {
                                                    formik.resetForm();
                                                    handleSelectedRow(null);
                                                    if (formik.values.modifierGroupId) {
                                                        handleIsEdited();
                                                        setinitialState(defaultState);
                                                    }
                                                    if (fromItemFlow) {
                                                        toggleDrawer("right", true)(e);
                                                    } else {
                                                        toggleDrawer("right", false)(e);
                                                    }
                                                }
                                            },
                                            {
                                                btnTitle: "Save",
                                                disabled: formik.isSubmitting,
                                                variant: "contained",
                                                sx: (t) => ({ color: t.palette.background.paper }),
                                                onClick: (e) => {
                                                    formik.handleSubmit();
                                                }
                                            }
                                        ]}
                                    />
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default NewAddModifierGroup;
