/* eslint-disable camelcase */
import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {useStyles} from "./styles";
import {processData, processIndividualData} from "utils/helperFunctions/helpers";

function KitchenPdfComponent({itemDetailSorted}) {
    const styles = useStyles();
    if (!Array.isArray(itemDetailSorted) || itemDetailSorted.length === 0) {
        return null;
    }

    const individualTable = [];
    const trayTable = [];

    itemDetailSorted.forEach((item) => {
        const hasIndividualPackaging = item.orderItemsModifiers.some(
            (modifier) => modifier.modifier && modifier.modifier.modifier_name === "Individual packaging"
        );

        const hasTrayPackaging = item.orderItemsModifiers.some(
            (modifier) => modifier.modifier && modifier.modifier.modifier_name === "Tray packaging"
        );

        if (hasIndividualPackaging) {
            individualTable.push(item);
        } else if (hasTrayPackaging) {
            trayTable.push(item);
        } else {
            trayTable.push(item);
        }
    });

    const transformedData = processData(trayTable);
    console.log("transformedData", transformedData);

    const transformedindividualData = processIndividualData(individualTable);

    const tableHeader = (type) => (
        <Grid container sx={[styles?.tableHeaderContainer, {backgroundColor: type === "INDIVIDUAL" ? "#FFF202" : "#F3F3F3"}]}>
            <Grid item xs={1} sx={styles?.alignLeft}>
                <Typography sx={[styles?.detailTitle, styles?.color3]}>Prep</Typography>
            </Grid>
            <Grid item xs={1} sx={styles?.alignLeft}>
                <Typography sx={[styles?.detailTitle, styles?.color3]}>Fire</Typography>
            </Grid>
            <Grid item xs={1} sx={styles?.alignLeft}>
                <Typography sx={[styles?.detailTitle, styles?.color3]}>Quantity</Typography>
            </Grid>
            <Grid item xs={7} sx={styles?.alignLeft}>
                <Typography sx={[styles?.detailTitle, styles?.color3]}>Items</Typography>
            </Grid>
            <Grid item xs={2} sx={styles?.alignRight}>
                <Typography sx={[styles?.tableTag, {width: type === "INDIVIDUAL" ? "82px" : "45px"}]}>{type}</Typography>
            </Grid>
        </Grid>
    );
    return (
        <>
            <Box>
                {trayTable.length > 0 && (
                    <>
                        {tableHeader("TRAY")}
                        {transformedData.map((group, i) => (
                            <Box sx={{borderBottom: "1px solid #f0f0f0", paddingBottom: "4px"}} key={i}>
                                <Grid
                                    container
                                    sx={{
                                        paddingLeft: 0.5,
                                        paddingTop: 1,

                                        "-webkit-print-color-adjust": "exact",
                                        "print-color-adjust": "exact"
                                    }}
                                >
                                    <Grid item xs={1} sx={styles.gridItemFirstColumn}>
                                        <Box sx={{marginTop: "18px"}} />
                                        {group?.item.map((data, i) => (
                                            <Box key={i}>
                                                <Box
                                                    sx={[
                                                        styles.squareBox,
                                                        {
                                                            marginBottom: "4px",
                                                            border: data.prepOption ? "1px solid #ACACAC" : "1px solid #ffffff"
                                                        }
                                                    ]}
                                                />

                                                {data.special_instruction && <Box sx={{marginTop: "25px"}} />}
                                                {data?.mix_item && data?.mix_item?.length > 0 && (
                                                    <Box sx={{marginTop: data.special_instruction ? "38px" : "19px"}} />
                                                )}

                                                {data?.mix_item &&
                                                    data?.mix_item?.length > 0 &&
                                                    data?.mix_item.map((mixItem, i) => (
                                                        <Box key={i} sx={{marginTop: "21px"}}>
                                                            {mixItem?.items &&
                                                                mixItem?.items?.length > 0 &&
                                                                mixItem?.items?.map((protenis, j) => (
                                                                    <Box key={j}>
                                                                        <Box
                                                                            sx={[
                                                                                styles.squareBox,
                                                                                {
                                                                                    marginBottom: "4px",
                                                                                    border:
                                                                                        Array.isArray(protenis?.modifier?.preparation) &&
                                                                                        protenis.modifier?.preparation.includes("prep")
                                                                                            ? "1px solid #ACACAC"
                                                                                            : "1px solid #ffffff"
                                                                                }
                                                                            ]}
                                                                        />
                                                                        {protenis.special_instruction && <Box sx={{marginTop: "25px"}} />}
                                                                    </Box>
                                                                ))}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item xs={1} sx={styles.gridItemFirstColumn}>
                                        <Box sx={{marginTop: "18px"}} />
                                        {group?.item.map((data, i) => (
                                            <Box key={i}>
                                                <Box
                                                    sx={[
                                                        styles.squareBox,
                                                        {
                                                            marginBottom: "4px",
                                                            border: data.fireOption ? "1px solid #ACACAC" : "1px solid #ffffff"
                                                        }
                                                    ]}
                                                />

                                                {data.special_instruction && <Box sx={{marginTop: "25px"}} />}

                                                {data?.mix_item && data?.mix_item?.length > 0 && (
                                                    <Box sx={{marginTop: data.special_instruction ? "38px" : "19px"}} />
                                                )}
                                                {data?.mix_item &&
                                                    data?.mix_item?.length > 0 &&
                                                    data?.mix_item.map((mixItem, i) => (
                                                        <Box key={i} sx={{marginTop: "21px"}}>
                                                            {mixItem?.items &&
                                                                mixItem?.items?.length > 0 &&
                                                                mixItem?.items?.map((protenis, j) => (
                                                                    <Box key={j}>
                                                                        <Box
                                                                            sx={[
                                                                                styles.squareBox,
                                                                                {
                                                                                    marginBottom: "4px",
                                                                                    border:
                                                                                        Array.isArray(protenis?.modifier?.preparation) &&
                                                                                        protenis.modifier?.preparation.includes("fire")
                                                                                            ? "1px solid #ACACAC"
                                                                                            : "1px solid #ffffff"
                                                                                }
                                                                            ]}
                                                                        />
                                                                        {protenis.special_instruction && <Box sx={{marginTop: "25px"}} />}
                                                                    </Box>
                                                                ))}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item xs={1} sx={[styles.gridItemFirstColumn, {paddingLeft: 1.7}]}>
                                        <Box sx={{marginTop: "18px"}} />
                                        {group?.item.map((data, i) => (
                                            <Box key={i}>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "17.5px",
                                                        color: "#000",
                                                        marginBottom: "4px"
                                                    }}
                                                >
                                                    {data?.quantity}
                                                </Typography>
                                                {data.special_instruction && <Box sx={{marginTop: "25px"}} />}
                                                {data?.mix_item && data?.mix_item?.length > 0 && (
                                                    <Box sx={{marginTop: data.special_instruction ? "41px" : "19px"}} />
                                                )}

                                                {data?.mix_item &&
                                                    data?.mix_item?.length > 0 &&
                                                    data?.mix_item.map((mixItem, i) => (
                                                        <Box key={i} sx={{marginTop: "21px"}}>
                                                            {mixItem?.items &&
                                                                mixItem?.items?.length > 0 &&
                                                                mixItem?.items?.map((protenis, j) => (
                                                                    <Box key={j}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: 400,
                                                                                lineHeight: "17.5px",
                                                                                color: "#000",
                                                                                marginBottom: "4px"
                                                                            }}
                                                                        >
                                                                            {protenis?.quantity}
                                                                        </Typography>
                                                                        {protenis.special_instruction && <Box sx={{marginTop: "25px"}} />}
                                                                    </Box>
                                                                ))}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item xs={6} sx={styles?.gridFlextStart}>
                                        <Typography sx={styles.displayColumnName}>{group?.display_name.toUpperCase()}</Typography>
                                        {group?.item.map((data, i) => (
                                            <Box key={i}>
                                                <Box sx={{display: "flex", gap: "10px"}}>
                                                    <Box sx={styles.squareBox} />
                                                    <Box>
                                                        <Typography sx={styles.itemColumnName} variant="div">
                                                            {data?.modifier_name}
                                                            <span style={{fontWeight: "700", fontSize: "14px"}}> __________ </span>
                                                        </Typography>
                                                        {data.special_instruction && (
                                                            <Box
                                                                sx={{
                                                                    display: "-webkit-box",
                                                                    flexDirection: "row",
                                                                    marginBottom: "2px"
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: "#000",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "18.62px"
                                                                    }}
                                                                >
                                                                    Special instructions:&nbsp;{" "}
                                                                    {data?.special_instruction.length > 0 ? data?.special_instruction : "-"}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>

                                                {data?.mix_item &&
                                                    data?.mix_item?.length > 0 &&
                                                    data?.mix_item.map((mixItem, i) => (
                                                        <Box sx={{paddingLeft: "28.6px"}} key={i}>
                                                            <Typography sx={styles.displayColumnName}>
                                                                {mixItem?.display_name.toUpperCase()}
                                                            </Typography>
                                                            {mixItem?.items &&
                                                                mixItem?.items?.length > 0 &&
                                                                mixItem?.items.map((protenis, j) => (
                                                                    <Box sx={{display: "flex", gap: "10px"}} key={j}>
                                                                        <Box sx={styles.squareBox} />
                                                                        <Box>
                                                                            <Typography sx={styles.itemColumnName} variant="div">
                                                                                {protenis?.modifier_name}
                                                                                <span style={{fontWeight: "700", fontSize: "14px"}}>
                                                                                    {" "}
                                                                                    __________{" "}
                                                                                </span>
                                                                            </Typography>
                                                                            {protenis.special_instruction && (
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "-webkit-box",
                                                                                        flexDirection: "row",
                                                                                        marginBottom: "2px"
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: "#000",
                                                                                            fontSize: "14px",
                                                                                            fontWeight: 400,
                                                                                            lineHeight: "18.62px"
                                                                                        }}
                                                                                    >
                                                                                        Special instructions:&nbsp;{" "}
                                                                                        {data?.special_instruction.length > 0
                                                                                            ? data?.special_instruction
                                                                                            : "-"}
                                                                                    </Typography>
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center"
                                        }}
                                    />
                                </Grid>
                            </Box>
                        ))}
                    </>
                )}

                {/* IndividualTable  */}
                {individualTable.length > 0 && (
                    <>
                        {tableHeader("INDIVIDUAL")}
                        {transformedindividualData.map((orderItem, index) => {
                            return (
                                <Box
                                    key={`${orderItem.order_item_id},${index}`}
                                    sx={{borderBottom: "1px solid #f0f0f0", paddingBottom: "4px"}}
                                >
                                    <Grid
                                        container
                                        sx={{
                                            paddingLeft: 0.5,
                                            paddingTop: 1,
                                            "-webkit-print-color-adjust": "exact",
                                            "print-color-adjust": "exact"
                                        }}
                                    >
                                        <Grid item xs={1} sx={styles?.gridItemMarginColumn}>
                                            <Box
                                                sx={[
                                                    styles.squareBox,
                                                    {
                                                        border:
                                                            Array.isArray(orderItem?.item?.preparation) &&
                                                            orderItem.item?.preparation.includes("prep")
                                                                ? "1px solid #ACACAC"
                                                                : "1px solid #ffffff"
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={styles?.gridItemMarginColumn}>
                                            <Box
                                                sx={[
                                                    styles.squareBox,
                                                    {
                                                        border:
                                                            Array.isArray(orderItem?.item?.preparation) &&
                                                            orderItem.item?.preparation.includes("fire")
                                                                ? "1px solid #ACACAC"
                                                                : "1px solid #ffffff"
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={[styles?.gridItemMarginColumn, {paddingLeft: 1.7}]}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "17.5px",
                                                    color: "#000"
                                                }}
                                            >
                                                {orderItem.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={styles?.gridFlextStart}>
                                            <Typography sx={styles.displayColumnName}>
                                                {orderItem?.item?.category?.category_name.toUpperCase()}
                                            </Typography>
                                            <Box sx={{display: "flex", gap: "10px"}}>
                                                <Box sx={styles.squareBox} />
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        lineHeight: "17.5px",
                                                        color: "#000",
                                                        display: "flex",
                                                        gap: "4px",
                                                        maxWidth: "360px",
                                                        mb: "4px"
                                                    }}
                                                >
                                                    {orderItem.item_name}
                                                    <span style={{fontWeight: "700", fontSize: "14px"}}> __________ </span>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "left",
                                                alignItems: "center"
                                            }}
                                        />
                                    </Grid>

                                    {orderItem.modifiertransform && orderItem.modifiertransform.length > 0 && (
                                        <Grid container>
                                            {orderItem.modifiertransform
                                                // .filter((mod) => mod.modifier_group.display_name.toLowerCase() !== "packaging")
                                                .map((mod, modIndex) => (
                                                    <Grid
                                                        className="bgColor"
                                                        key={modIndex}
                                                        container
                                                        sx={{
                                                            marginTop: modIndex % 2 === 0 ? "0px" : "4px",
                                                            "-webkit-print-color-adjust": "exact",
                                                            "print-color-adjust": "exact"
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                flexDirection: "column",
                                                                paddingLeft: 1.6,
                                                                marginTop: "18px",
                                                                gap: "4px"
                                                            }}
                                                        >
                                                            {mod?.item.map((mode, i) => (
                                                                <Box key={i}>
                                                                    <Box
                                                                        sx={[
                                                                            styles.squareBox,
                                                                            {
                                                                                border:
                                                                                    Array.isArray(mode?.modifier?.preparation) &&
                                                                                    mode?.modifier?.preparation.includes("prep")
                                                                                        ? "1px solid #ACACAC"
                                                                                        : "1px solid #ffffff"
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={1} sx={styles?.gridModColumn}>
                                                            {mod?.item.map((mode, i) => (
                                                                <Box key={i}>
                                                                    <Box
                                                                        sx={[
                                                                            styles.squareBox,
                                                                            {
                                                                                border:
                                                                                    Array.isArray(mode?.modifier?.preparation) &&
                                                                                    mode?.modifier?.preparation.includes("fire")
                                                                                        ? "1px solid #ACACAC"
                                                                                        : "1px solid #ffffff"
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Box>
                                                            ))}
                                                        </Grid>

                                                        <Grid item xs={1} sx={[styles?.gridModColumn, {paddingLeft: "18.6px"}]}>
                                                            {mod?.item.map((mode, i) => (
                                                                <Typography
                                                                    key={i}
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "17.5px",
                                                                        color: "#000"
                                                                    }}
                                                                >
                                                                    {orderItem?.quantity === mode?.quantity ? "" : mode?.quantity}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                paddingLeft: 4.3,
                                                                wordBreak: "break-word"
                                                            }}
                                                        >
                                                            <Typography sx={styles.displayColumnName}>
                                                                {mod?.display_name.toUpperCase()}
                                                            </Typography>

                                                            {mod?.item.map((mode, i) => (
                                                                <Box key={i} sx={{display: "flex", gap: "10px"}}>
                                                                    <Box sx={styles.squareBox} />
                                                                    <Typography sx={[styles.itemColumnName]} variant="div">
                                                                        {mode?.modifier?.modifier_name}
                                                                        <span style={{fontWeight: "700", fontSize: "14px"}}>
                                                                            {" "}
                                                                            __________{" "}
                                                                        </span>
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center"
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    )}

                                    <Box sx={{pl: 32.5}}>
                                        {orderItem.special_instruction && (
                                            <Box
                                                sx={{
                                                    display: "-webkit-box",
                                                    flexDirection: "row",
                                                    marginBottom: "2px",
                                                    width: "500px"
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#000",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "18.62px"
                                                    }}
                                                >
                                                    Special instructions:&nbsp;{" "}
                                                    {orderItem?.special_instruction.length > 0 ? orderItem?.special_instruction : "-"}
                                                </Typography>
                                            </Box>
                                        )}
                                        {/* {orderItem.kitchen_note && (
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "18.62px",
                                                    color: "#000"
                                                }}
                                            >
                                                Kitchen note: {orderItem.kitchen_note}
                                            </Typography>
                                        )} */}
                                    </Box>
                                </Box>
                            );
                        })}
                    </>
                )}

                <Box sx={{borderBottom: "1px solid #f0f0f0", paddingBottom: "4px"}}>
                    <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={1} />
                        <Grid item xs={1} />
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: "flex",
                                flexDirection: "column",

                                wordBreak: "break-word"
                            }}
                        >
                            <Typography sx={styles.displayColumnName}>TABLEWARE</Typography>
                            <Box sx={{display: "flex", gap: "10px", paddingLeft: "2.2px"}}>
                                <Box sx={styles.squareBox} />
                                <Typography sx={[styles.itemColumnName]} variant="div">
                                    Plates
                                </Typography>
                            </Box>
                            <Box sx={{display: "flex", gap: "10px", paddingLeft: "2.2px"}}>
                                <Box sx={styles.squareBox} />
                                <Typography sx={[styles.itemColumnName]} variant="div">
                                    Cutlery with napkins
                                </Typography>
                            </Box>
                            <Box sx={{display: "flex", gap: "10px", paddingLeft: "2.2px"}}>
                                <Box sx={styles.squareBox} />
                                <Typography sx={[styles.itemColumnName]} variant="div">
                                    Serving utensils
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default KitchenPdfComponent;
