import CommonDilogBox from "commonComponent/CommonDilogBox/CommonDilogBox";
import React, {useEffect, useState, useRef} from "react";
import SideSection from "./sideSection/SideSection";
import HeaderSection from "./headerSection/HeaderSection";
import {Form, Formik} from "formik";
import {Box} from "@mui/material";

import CreateOrderFooter from "./createOrderFooter";

import MainSection from "./mainSection";
import useCreateOrderController from "./createOrder.controller";
import {API, NetworkManager} from "network/core";
import useToggleDilog from "hooks/useToggleDilog";
import useOrderDialog from "hooks/useOrderDialog";
import SendQuote from "./createOrderFooter/SendQuote/SendQuote";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import * as Yup from "yup";
import {useSearchParams, useNavigate} from "react-router-dom";
import PaymentForm from "./createOrderFooter/Payment";
import useTempDrawer from "hooks/useTempDrawer";
import {convertIsoToCST, convertToLocal, getTzDateTime} from "utils/app-dates/dates";
import RefundForm from "./createOrderFooter/Refund";
import {useSelector} from "react-redux";
import {setCartOrderName} from "store/slices/ordername";
import {getHeaderAddressObj} from "utils/helperFunctions/helpers";

const drawerWidth = 280;

const CreateOrder = () => {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [openRefundDialog, setOpenRefundDialog] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0);
    const footerRef = useRef();
    const [discountFlag, setDiscountFlag] = useState(false);
    const [isDiscountAdded, setISDiscountAdded] = useState(false);
    
    const [extraAmount, setExtraAmount] = useState(0);
    const [remainingAmountToPaid, setRemainingAmountToPaid] = useState(0);
    const [orderSaved, setOrderSaved] = useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [orderId, setOrderId] = React.useState(null);
    const {toggleDilog, open} = useToggleDilog();
    const [flag, setFlag] = React.useState(true);
    const [orderDetail, setOrderDetail] = React.useState(null);
    const [orderedBrandId, setOrderedBrandId] = React.useState("");
    const [orderedMenuId, setOrderedMenuId] = React.useState("");
    const [orderItems, setOrderitems] = React.useState(null);
    const [closeModal, setCloseModal] = React.useState(false);
    const [waitForItem, setWaitForItem] = useState(false);
    const [footerHeight, setFooterHeight] = useState("panel1");
    const [mainSectionHeight, setMainSectionHeight] = useState(0);
    const [customerNotes, setCustomerNotes] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const OrderId = searchParams.get("orderId");
    const kitchenIdRef = useRef(null);
    const deliveryDateRef = useRef(null);
    const Reorder = searchParams.get("reOrder");
    const CustomerId = searchParams.get("customerId");
    const CustomerName = searchParams.get("customerName");
    const Tab = searchParams.get("tab");
    const {toggleDrawer, state} = useTempDrawer();
    const {handlePastOrder, pastOrderId, orderIngFromPast, touchFormikFields, setTouchFormikFields} = useOrderDialog();
    const cartOrderName = useSelector((state) => state.headerdata?.cartOrderName);
    const [orderIdParam, setOrderIdParam] = useState(searchParams.get("orderId"));
    const [reOrder, setreOrder] = useState(searchParams.get("reOrder"));
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [isPaymentButtonDisabled, setIsPaymentButtonDisabled] = useState(false);
    const [orderParstData, setOrederPastData] = useState([]);

    const [singleQuoteData, setSingleQuoteData] = useState([]);
    const [createData, setCreateData] = useState(null);
    const customerPaymentProfileId = useSelector((state) => state.cardData?.customerPaymentProfileId);
    const [showRegularPrice, setShowRegularPrice] = useState(false);
    const [cartName, setCartName] = useState("");
    const newServiceFessRef = useRef(null);
    const preferrefPriceRef = useRef(null);

    const handleReset = () => {
        setMobileOpen(false);
        setOrderId(null);
        setFlag(true);
        setOrderDetail(null);
        setOrderedBrandId("");
        setOrderedMenuId("");
        setOrderitems(null);
        dispatch({
            type: "ORDER"
        });

        dispatch(setCartOrderName(""));
        navigate(`/orders?tab=${Tab}`);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const COC = useCreateOrderController();

    const CreateNewOrder = async (state) => {
        const instance = NetworkManager(API.ORDER.ADD);
        const response = await instance.request({});
        if (response.success) {
            setOrderId(response.data);
        }
    };

    const handleQuoteView = (e) => {
        toggleDrawer("right", "SendQuote")(e);
    };
    const handlePaymentView = (e) => {
        toggleDrawer("right", "Payment")(e);
    };

    const PackageDefault = {INDIVIDUAL: "Individual", TRAY: "Tray"};

    const filterEmptyAndNull = (obj) => {
        const result = {};
        const alwaysIncludeFields = ["calendarNote"];

        Object.entries(obj).forEach(([key, value]) => {
            if (alwaysIncludeFields.includes(key) || (value !== "" && value !== null)) {
                result[key] = value;
            }
        });

        return result;
    };

    const handleReverifyDiscount = async (values) => {
        const payload = {
            discount_code: values.discountOrFee?.description,
            service_type: "CATERING",
            market_id: [values?.marketIDForDiscount || ""],
            brand_id: [values?.selectedBrand],
            company_id: values.headerData?.customer?.companyId || ""
        };
        const instance = NetworkManager(API.PROMOTIONS.POST(values.headerData?.customer?.userId));

        const res = await instance.request(payload);
        if (res.success) {
            handlePaymentView();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getTimeZone = async (lat, lon) => {
        const instance = NetworkManager(API.ORDER.GETTIMEZONE);
        const response = await instance.request({}, [`?latitude=${lat}&longitude=${lon}`]);
        let tz = "";
        if (response.success) {
            tz = response.data.timeZoneId;
        } else {
            tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return tz;
    };

    const handleUpdateOrder = async (values, reOrderid = null, formik = null, isScheduleOrder = false, isAutoSave = false) => {
        const requiredAddressDetails = getHeaderAddressObj(values);
        let timezone = "";
        if (values?.kitchenLatitude !== "" && values?.kitchenLongitude !== "") {
            timezone = await getTimeZone(values?.kitchenLatitude, values?.kitchenLongitude);
        } else {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        const payload = {
            customerId: values.headerData.customer.userId,
            deliveryAddress: values.headerData.deliveryTo,
            latitude: values.headerData.latitude,
            longitude: values.headerData.longitude,
            address_details: requiredAddressDetails ?? {},
            deliveryDate: getTzDateTime(timezone, values.headerData.when),
            orderType: values.headerData.orderType,
            serviceType: values.headerData.service,
            noOfGuests: values.headerData.forHowMany,
            brandId: values.selectedBrand,
            menuId: values.selectedMenu,
            kitchenLocationId: values.kitchenLocationId,
            isTaxExempts: values.isTaxExempts,
            url: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${orderId.order_id}`,
            orderId: reOrderid === null ? orderId.order_id : reOrderid,
            serviceFees: values.serviceFees,
            tableware_plates: values.tableware_plates,
            tableware_cutlery: values.tableware_cutlery,
            serving_utensils: values?.serving_utensils,
            location_distance: values.location_distance,
            order_name: cartOrderName || null,
            order_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            customerAddressId: values?.headerData?.address_id,
            apply_minimum_service_fee: values?.apply_minimum_service_fee,
            calendarNote: values.calendarNote ?? ""
        };

        payload.discountOrFee = values.discountOrFee;
        if (values.discountOrFee.description === "") {
            delete payload.discountOrFee.description;
        }

        if (values.discount_applied_coupon_id !== "") {
            payload.discountId = values.discount_applied_coupon_id;
        }

        const headerDate = getTzDateTime(timezone, values.headerData.when).split("T")[0];
        const deliveryTime = getTzDateTime(timezone, values?.leave_by).split("T")[1];
        payload.specialInstruction = values.specialInstruction;
        payload.deliveryInstruction = values.deliveryInstruction;
        payload.drive_time = values.drive_time.toString();
        payload.setup_time = values.setup_time.toString();
        payload.leave_by = getTzDateTime(timezone, values?.leave_by) ? `${headerDate}T${deliveryTime}` : null;

        payload.kitchenNote = values.kitchenNote;
        payload.calendarNote = values.calendarNote;

        if (values.itemDetail.length > 0) {
            const items = [];
            values.itemDetail.forEach((row, index) => {
                const modifier = [];
                const tempItem = {
                    itemId: row.itemId,
                    quantity: row.quantity,
                    item_type: row.item_type,
                    item_ordering: index,
                    customer_note: row?.customer_note,
                    kitchen_note: row?.kitchen_note,
                    price: row.price.toString()
                };
                if (Number(row?.quantity) < Number(row?.minimum_order_quantity)) {
                    tempItem.minimum_order_quantity = row?.quantity;
                }
                if (row.item_type === 1) {
                    // only for regular items
                    tempItem.menuCategoryId = row.menuCategoryId;
                }

                if (row.specialInstruction !== "") {
                    tempItem.specialInstruction = row.specialInstruction;
                }
                if (row.packaging.length > 0) {
                    const tempCol = JSON.parse(row.packaging[0]);
                    tempItem.packagingType = PackageDefault[tempCol.name];
                }

                if (row.checkboxModifier.length > 0) {
                    row.checkboxModifier.forEach((col) => {
                        const obj = {
                            modifierGroupId: col.modifierGroupId,
                            modifierId: col.modifierId,
                            itemId: col.itemId,
                            quantity: col.quantity,
                            itemQuantity: row.quantity,
                            series_no: col.series_no
                        };

                        if (Number(col?.quantity) < Number(col?.modifier?.minimum_value)) {
                            obj.item_modifier_minimum_value = col?.quantity;
                        }

                        if (!col?.allowQuantityModification) {
                            obj.quantity = row.quantity;
                        }
                        modifier.push(obj);
                    });
                }
                if (row.radioModifier.length > 0) {
                    row.radioModifier.forEach((col) => {
                        const col1 = JSON.parse(col);
                        const obj = {
                            modifierGroupId: col1.modifierGroupId,
                            modifierId: col1.modifierId,
                            itemId: col1.itemId,
                            quantity: col1.quantity,
                            itemQuantity: row.quantity,
                            series_no: col1.series_no
                        };
                        if (!col?.allowQuantityModification) {
                            obj.quantity = row.quantity;
                        }
                        modifier.push(obj);
                    });
                    // modifier.push(row.radioModifier)
                }
                if (row.item_type === 1) {
                    // if not custom menu item
                    tempItem.modifiers = modifier;
                }
                items.push(tempItem);
            });

            payload.items = items;
            // payload.modifiers = modifier;
        }

        const instance = NetworkManager(API.ORDER.UPDATE);

        const response = await instance.request(filterEmptyAndNull(payload));
        if (isAutoSave) {
            setOrderSaved(true);

            return;
        }
        toggleDrawer("right", false)();

        if (response.success) {
            const data = response.data?.[1]?.[0]?.remaining_amount_to_be_paid;

            // this needs to be set and send as extra payment in api
            // since backend has added validation for points as well for example
            // if frontend calculation for extra payment is 15.40 and at backend calculation extra payemnt is
            // 15.399999907 it will give response as invalid amount
            setRemainingAmountToPaid(data);
            setOrderSaved(true);

            // if Order is order is SCHEDULED
            if (isScheduleOrder) {
                const instance = NetworkManager(API.ORDER.ORDERSCHEDULE(orderId.order_id));
                const response = await instance.request();
                if (response.success) {
                    console.log(response?.message, "RESPONSE");
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response.message,
                            variant: "alert",
                            alert: {
                                color: "success"
                            },
                            close: false
                        })
                    );
                } else {
                    console.log(response?.message, "RESPONSE");

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: response.message,
                            variant: "alert",
                            alert: {
                                severity: "error",
                                color: "error"
                            },
                            close: false
                        })
                    );
                }
                return;
            }

            if (formik) {
                formik.setTouched({});
                if (response.data?.[1]?.[0]?.status) {
                    formik.setFieldValue(`orderStatus`, response.data?.[1]?.[0]?.status);
                }
            }

            if (openDialog) {
                handleQuoteView();
                setOpenDialog(false);
                return;
            }
            if (openPaymentDialog) {
                if (response.data?.[1]?.[0]?.customer_card_id === null && response.data?.[1]?.[0]?.payment_profile_id === null) {
                    if (
                        (values.orderStatus === "Quote Sent" || values.orderStatus === "Draft") &&
                        values.discountOrFee?.description &&
                        values.discountOrFee?.description !== ""
                    ) {
                        handleReverifyDiscount(values);
                        return;
                    } else {
                        handlePaymentView();
                        return;
                    }
                } else if (response.data?.[1]?.[0]?.customer_card_id !== null && response.data?.[1]?.[0]?.payment_profile_id !== null) {
                    handlePaymentView();
                    return;
                } else {
                    setCloseModal(true);
                }
                setOpenPaymentDialog(false);
            }
            if (openRefundDialog) {
                // toggleDrawer("right", "refund")()
                setOpenRefundDialog(false);
            }

            // if REORDER and clicked on SCHEDULE ON CALENDAR
            if (searchParams.get("reOrder")) {
                dispatch({
                    type: "RemoveItem"
                });
                dispatch({
                    type: "ORDER"
                });
                // navigate(`/orders?tab=draft`);
                localStorage.setItem("quote", "");
            }

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setIsPaymentButtonDisabled(false);
        } else if (response.error) {
            let message = null;
            if (response?.message?.includes("items[0].price")) {
                message = "Item price cannot be empty.";
            } else if (response?.message?.includes("items[0].quantity")) {
                message = "Item quantity cannot be empty.";
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: message ?? response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            setIsPaymentButtonDisabled(false);
        }
    };

    const {initialValues} = COC;

    const getOrderDetailById = async (id) => {
        const schema = {
            orderId: "",
            itemDetail: [],
            orderStatus: "",
            headerData: {
                customer: {
                    name: "",
                    email: "",
                    phone: "",
                    company: "",
                    companyId: "",
                    userId: null,
                    customerAddresses: []
                },
                service: "Catering",
                orderType: "Delivery",
                deliveryTo: "",
                latitude: "",
                longitude: "",
                address_details: {},
                when: "",
                forHowMany: "",
                preferredDiscountType: "DISCOUNT",
                preferredDiscountValue: 0
            },
            selectedBrand: "",
            brandName: "",
            selectedMenu: "",
            kitchenLocationId: "",
            selectedKitchen: "",
            kitchenLatitude: "",
            kitchenLongitude: "",
            kitchenMinimumServiceFee: 0,
            kitchenList: [],
            categories: "",
            items: [],
            specialInstruction: "",
            kitchenNote: "",
            calendarNote: "",
            deliveryInstruction: "",
            drive_time: "",
            setup_time: "",
            leave_by: null,
            orderHistory: [],

            isTaxExempts: false,
            discountOrFee: {
                type: "Percentage",
                priceType: "Discount",
                value: 0,
                description: ""
            },
            serviceFees: {
                unitType: "percent",
                value: 0
            },
            salesTax: {value: 0, percent: 0},
            discountedPrice: 0,
            serviceTaxTotal: 0,
            orderPriceForAllItem: 0,
            salesTaxTotalPrice: 0,
            totalPriceAfterCalculation: 0,
            perPersonOrderItem: 0,
            perPersonTotal: 0,
            clickOnSaveDraft: false,
            isSaved: false,
            is_payment_initiated: false,
            paid_already: 0,
            itemModifier: {},
            chargedByCardOrHouse: "",
            customerCardId: "",

            tableware: true,
            selectedTableWare: ["Plates", "Cutlery with napkins", "Serving utensils"],
            tableware_plates: true,
            tableware_cutlery: true,
            serving_utensils: true,
            openTableWarePopup: false,
            discount_applied_coupon_id: "",
            advance_deposit_percent: "",
            advance_deposit_amount: null,
            is_advance_deposit_enabled: false,
            serviceFeeAdded: true
        };

        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        const customerNote = response.data.category.map((category) => category.orderItems.map((item) => item.item.customer_note));
        setCustomerNotes(customerNote);

        if (response.success) {
            setOrderDetail(response.data);

            if (Reorder !== "reOrder") {
                setOrderId({order_id: response.data.order.order_id, order_number: response.data.order.order_number});
                setOrderSaved(true);
            }

            const {category, order} = response.data;

            setOrderedBrandId(order?.brand_id ?? "");
            setOrderedMenuId(order?.menu_id ?? "");
            schema.apply_minimum_service_fee = order?.apply_minimum_service_fee;
            schema.orderId = order?.order_id;
            schema.orderStatus = order?.status ?? "";
            schema.discount_applied_coupon_id = order?.discount_id ?? "";
            schema.quote_expiry_time = order?.quote_expiry_time;
            schema.selectedBrand = order?.brand_id ?? "";
            schema.brandName = order?.brand?.brand_name;
            schema.selectedMenu = order?.menu_id ?? "";
            schema.tableware_plates = order?.tableware_plates;
            schema.tableware_cutlery = order?.tableware_cutlery;
            schema.serving_utensils = order?.serving_utensils;
            schema.service_fee_value = order?.service_fee_value;
            schema.tz = order?.order_timezone;
            if (order?.advance_deposit_amount) {
                schema.advance_deposit_amount = order?.advance_deposit_amount ?? null;
                schema.advance_deposit_percent = order?.advance_deposit_percent ?? "";
                schema.is_advance_deposit_enabled = order?.is_advance_deposit_enabled ?? false;
            }

            const arr = [];

            if (order?.tableware_plates) {
                arr.push("Plates");
            }
            if (order?.tableware_cutlery) {
                arr.push("Cutlery with napkins");
            }
            if (order?.serving_utensils) {
                arr.push("Serving utensils");
            }

            schema.selectedTableWare = arr;

            //  kitchen data prefilling
            schema.kitchenLocationId = order?.kitchen_location_id ?? "";
            schema.selectedKitchen = order?.kitchen_location?.name ?? "";
            schema.kitchenLatitude = order?.kitchen_location?.latitude ?? "";
            schema.kitchenLongitude = order?.kitchen_location?.longitude ?? "";
            schema.marketIDForDiscount = order?.kitchen_location?.market?.market_id ?? "";
            schema.marketNameForDiscount = order?.kitchen_location?.market?.market_name ?? "";
            schema.kitchenMinimumServiceFee = order?.kitchen_location?.minimum_service_fee ?? 0;
            schema.brandName = order?.brand?.brand_name ?? "";

            schema.specialInstruction = order?.special_instructions ?? "";
            schema.kitchenNote = order?.kitchen_note ?? "";
            schema.calendarNote = order?.calendar_note ?? "";
            schema.deliveryInstruction = order?.delivery_instructions ?? "";
            schema.drive_time = order?.drive_time ?? "";
            schema.setup_time = order?.setup_time ?? order?.kitchen_location?.setup_time ?? "";
            schema.leave_by = order?.leave_by ?? null;
            if (order?.status === "Accepted") {
                schema.is_payment_initiated = order?.is_payment_initiated;
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";

                if (Reorder !== "reOrder") {
                    schema.paid_already = parseFloat(order?.total_price);
                }
            }
            if (
                order?.status === "Active" ||
                order?.status === "Driver assigned" ||
                order?.status === "Needs Driver" ||
                order?.status === "Driver Assigned" ||
                order?.status === "ON_THE_WAY"
            ) {
                schema.is_payment_initiated = order?.is_payment_initiated;
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";

                if (Reorder !== "reOrder") {
                    schema.paid_already = parseFloat(order?.total_price);
                }
            }
            schema.isTaxExempts = order?.tax_exempt;
            schema.headerData.when = Reorder !== "reOrder" ? convertIsoToCST(order?.delivery_date, order?.order_timezone) ?? "" : "";
            schema.headerData.deliveryTo = order?.delivery_address ?? "";
            schema.headerData.longitude = order?.longitude ?? "";
            schema.headerData.latitude = order?.latitude ?? "";
            schema.headerData.address_details = order?.address_details ?? {};
            schema.headerData.forHowMany = order?.no_of_guest > 0 ? order?.no_of_guest : "";
            schema.headerData.service = order?.service_type ?? "Catering";
            schema.headerData.orderType = order?.order_type ?? "Delivery";
            let Cname = order?.customer?.full_name;
            if (order?.customer?.last_name) {
                Cname = `${Cname} ${order?.customer?.last_name}`;
            }
            schema.headerData.customer.name = Cname ?? "";
            schema.headerData.customer.userId = order?.customer?.user_id ?? null;
            schema.headerData.customer.company = order?.customer?.company?.company_name ?? "";
            schema.headerData.customer.companyId = order?.customer?.company?.company_id ?? "";
            schema.headerData.customer.phone = order?.customer?.phone ?? "";
            schema.headerData.customer.email = order?.customer?.email ?? "";
            schema.headerData.preferredDiscountType = order?.preferred_price_unit_type ?? "";
            schema.headerData.preferredDiscountValue = order?.preferred_price_unit_value ?? "";
            schema.phone = order?.address_details?.contact ?? "";
            schema.name = order?.address_details?.contact_name ?? "";
            schema.company = order?.address_details?.location_name;
            schema.apartment = order?.address_details?.apartment_name;
            schema.drive_time = order?.drive_time ?? "";
            schema.addressChanged = false;
            // ---------------- get customer address list

            const instance1 = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${order?.customer?.user_id}`));

            const response1 = await instance1.request();

            if (response1.success) {
                schema.headerData.customer.customerAddresses = response1.data?.customerAddresses;
            }

            // ----------------------------------------
            if (Reorder !== "reOrder") {
                schema.discountOrFee = {
                    type: order?.price_type_unit === "usd" ? "Amount" : "Percentage",
                    priceType: order?.price_type ?? "Discount",
                    value: order?.discount_or_fee_unit_value ?? 0,
                    description: order?.discount_or_fee_description ?? ""
                };
                if (order?.discount_or_fee_unit_value) {
                    setDiscountFlag(true);
                    setISDiscountAdded(true);
                } else {
                    setDiscountFlag(false);
                    setISDiscountAdded(false);
                }
            }

            schema.serviceFees = {
                unitType: order?.service_fee_unit ?? "percent",
                value: order?.service_fee_unit_value ?? 0
            };
            schema.salesTax = {value: order?.kitchen_location?.sales_tax_rate ?? 0, percent: order?.kitchen_location?.sales_tax_rate};
            const tempItemList = [];
            const itemDetailList = [];
            category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    const tempCheckboxList = [];
                    const tempRadioList = [];

                    col.orderItemsModifiers.forEach((modifier) => {
                        if (modifier.modifier_group.is_allow_multiple) {
                            tempCheckboxList.push({
                                modifierGroupId: modifier.modifier_group.modifier_group_id,
                                modifierId: modifier.modifier.modifier_id,
                                itemId: col.item_id,
                                quantity: modifier.quantity,
                                series_no: modifier?.modifier?.series_no,
                                price: parseFloat(modifier.price).toFixed(2),
                                modifierGroupName: modifier.modifier_group.modifier_group_na,
                                allowQuantityModification: modifier.modifier_group.allow_quantity_mo,
                                modifier: {minimum_value: modifier?.modifier?.minimum_value ?? 1},
                                splitModifier: modifier?.modifier_group?.split_modifiers
                            });
                        } else {
                            tempRadioList.push(
                                JSON.stringify({
                                    modifierGroupId: modifier.modifier_group.modifier_group_id,
                                    modifierId: modifier.modifier.modifier_id,
                                    itemId: col.item_id,
                                    quantity: modifier.quantity,
                                    price: parseFloat(modifier.price).toFixed(2),
                                    series_no: modifier?.modifier?.series_no,
                                    modifierGroupName: modifier.modifier_group.modifier_group_na,
                                    allowQuantityModification: modifier.modifier_group.allow_quantity_mo
                                })
                            );
                        }
                    });
                    if (col?.price) {
                        if (Number(col?.price / col?.quantity) !== Number(col.item.price)) {
                            setShowRegularPrice(true);
                        } else {
                            setShowRegularPrice(false);
                        }
                    } else {
                        setShowRegularPrice(false);
                    }
                    itemDetailList.push({
                        checkboxModifier: tempCheckboxList,
                        radioModifier: tempRadioList,
                        price: col?.price ? Number(col?.price / col?.quantity) : col.item.price,
                        regularPrice: col?.item?.price,
                        quantity: col.quantity,
                        specialInstruction: col.special_instruction,
                        packaging: [],
                        itemId: col.item_id,
                        minimum_order_quantity: Number(col?.item?.minimum_order_quantity),
                        itemOrdering: col.item_ordering,
                        servingSize: Number(col.item.serving_size),
                        menuCategoryId: col.menu_category_id,
                        item_type: col?.item?.item_type
                    });
                });
            });
            itemDetailList.sort((a, b) => a.itemOrdering - b.itemOrdering);
            itemDetailList.forEach((row1) => {
                tempItemList.push(row1.itemId);
            });

            schema.itemDetail = itemDetailList;
            COC.handleInitialValues(schema);

            setTimeout(() => {
                setOrderitems(tempItemList);
            }, 100);
            // if reorder is selected save the draft to the new id
            if (Reorder === "reOrder") {
                const instance = NetworkManager(API.ORDER.ADD);
                const response = await instance.request({});
                if (response.success) {
                    setOrderId(response.data);
                }
            }
            handlePastOrder(null);
        }
    };

    const getPastOrderById = async (id) => {
        const schema = {
            orderId: "",
            itemDetail: [],
            orderStatus: "",
            headerData: {
                customer: {
                    name: "",
                    email: "",
                    phone: "",
                    company: "",
                    companyId: "",
                    userId: null,
                    customerAddresses: []
                },
                service: "Catering",
                orderType: "Delivery",
                deliveryTo: "",
                latitude: "",
                longitude: "",
                address_details: {},
                when: "",
                forHowMany: "",
                preferredDiscountType: "DISCOUNT",
                preferredDiscountValue: 0
            },
            selectedBrand: "",
            brandName: "",
            selectedMenu: "",
            kitchenLocationId: "",
            selectedKitchen: "",
            kitchenLatitude: "",
            kitchenLongitude: "",
            kitchenMinimumServiceFee: 0,
            kitchenList: [],
            categories: "",
            items: [],
            specialInstruction: "",
            kitchenNote: "",
            calendarNote: "",
            deliveryInstruction: "",
            drive_time: "",
            setup_time: "",
            leave_by: null,
            orderHistory: [],

            isTaxExempts: false,
            discountOrFee: {
                type: "Percentage",
                priceType: "Discount",
                value: 0,
                description: ""
            },
            serviceFees: {
                unitType: "percent",
                value: 0
            },
            salesTax: {value: 0, percent: 0},
            discountedPrice: 0,
            serviceTaxTotal: 0,
            orderPriceForAllItem: 0,
            salesTaxTotalPrice: 0,
            totalPriceAfterCalculation: 0,
            perPersonOrderItem: 0,
            perPersonTotal: 0,
            clickOnSaveDraft: false,
            isSaved: false,
            is_payment_initiated: false,
            paid_already: 0,
            itemModifier: {},
            chargedByCardOrHouse: "",
            customerCardId: "",

            tableware: true,
            selectedTableWare: ["Plates", "Cutlery with napkins", "Serving utensils"],
            tableware_plates: true,
            tableware_cutlery: true,
            serving_utensils: true,
            openTableWarePopup: false,
            discount_applied_coupon_id: "",
            advance_deposit_percent: "",
            advance_deposit_amount: null,
            is_advance_deposit_enabled: false,
            serviceFeeAdded: true
        };

        setOrderedBrandId("");
        setOrderedMenuId("");
        const instance = NetworkManager(API.ORDER.QUOTEDETAIL(id));
        const response = await instance.request();
        if (response.success) {
            setOrderDetail(response.data);

            setOrderSaved(false);
            const {category, order} = response.data;
            setOrderedBrandId(order?.brand_id ?? "");
            setOrderedMenuId(order?.menu_id ?? "");
            schema.selectedBrand = order?.brand_id ?? "";
            schema.brandName = order?.brand?.brand_name;
            schema.selectedMenu = order?.menu_id ?? "";
            schema.tableware_plates = order?.tableware_plates;
            schema.tableware_cutlery = order?.tableware_cutlery;
            schema.serving_utensils = order?.serving_utensils;

            const arr = [];

            if (order?.tableware_plates) {
                arr.push("Plates");
            }
            if (order?.tableware_cutlery) {
                arr.push("Cutlery with napkins");
            }
            if (order?.serving_utensils) {
                arr.push("Serving utensils");
            }

            schema.selectedTableWare = arr;

            //  kitchen data prefilling
            schema.kitchenLocationId = order?.kitchen_location_id ?? "";
            schema.selectedKitchen = order?.kitchen_location?.name ?? "";
            schema.kitchenLatitude = order?.kitchen_location?.latitude ?? "";
            schema.kitchenLongitude = order?.kitchen_location?.longitude ?? "";
            schema.marketIDForDiscount = order?.kitchen_location?.market?.market_id ?? "";
            schema.marketNameForDiscount = order?.kitchen_location?.market?.market_name ?? "";
            schema.kitchenMinimumServiceFee = order?.kitchen_location?.minimum_service_fee ?? 0;
            schema.brandName = order?.brand?.brand_name ?? "";

            schema.specialInstruction = order?.special_instructions ?? "";
            schema.kitchenNote = order?.kitchen_note ?? "";
            schema.calendarNote = order?.calendar_note ?? "";
            schema.deliveryInstruction = order?.delivery_instructions ?? "";
            schema.drive_time = order?.drive_time ?? "";
            schema.setup_time = order?.setup_time ?? order?.kitchen_location?.setup_time ?? "";
            schema.leave_by = order?.leave_by ?? null;
            schema.orderHistory = order?.orderHistory ?? [];
            if (order?.status === "Accepted") {
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";
            }
            if (
                order?.status === "Active" ||
                order?.status === "Driver assigned" ||
                order?.status === "Needs Driver" ||
                order?.status === "Driver Assigned" ||
                order?.status === "ON_THE_WAY"
            ) {
                schema.chargedByCardOrHouse = order?.charged_by ?? "";
                schema.customerCardId = order?.payment_profile_id ?? "";
            }

            schema.isTaxExempts = order?.tax_exempt;
            schema.headerData.deliveryTo = order?.delivery_address ?? "";
            schema.headerData.longitude = order?.longitude ?? "";
            schema.headerData.address_details = order?.address_details ?? {};
            schema.headerData.latitude = order?.latitude ?? "";
            schema.headerData.forHowMany = order?.no_of_guest > 0 ? order?.no_of_guest : "";
            schema.headerData.service = order?.service_type ?? "Catering";
            schema.headerData.orderType = order?.order_type ?? "Delivery";
            schema.headerData.address_id = order?.customer_address_id;
            let Cname = order?.customer?.full_name;
            if (order?.customer?.last_name) {
                Cname = `${Cname} ${order?.customer?.last_name}`;
            }
            schema.headerData.customer.name = Cname ?? "";
            schema.headerData.customer.userId = order?.customer?.user_id ?? null;
            schema.headerData.customer.company = order?.customer?.company?.company_name ?? "";
            schema.headerData.customer.companyId = order?.customer?.company?.company_id ?? "";
            schema.headerData.customer.phone = order?.customer?.phone ?? "";
            schema.headerData.customer.email = order?.customer?.email ?? "";
            schema.headerData.preferredDiscountType = order?.preferred_price_unit_type ?? "";
            schema.headerData.preferredDiscountValue = order?.preferred_price_unit_value ?? "";
            schema.phone = order?.address_details?.contact ?? "";
            schema.name = order?.address_details?.contact_name ?? "";
            schema.company = order?.address_details?.location_name;
            schema.apartment = order?.address_details?.apartment_name;
            schema.addressChanged = true;
            // ---------------- get customer address list

            const instance1 = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${order?.customer?.user_id}`));

            const response1 = await instance1.request();

            if (response1.success) {
                schema.headerData.customer.customerAddresses = response1.data?.customerAddresses;
            }
            schema.serviceFees = {
                unitType: order?.service_fee_unit ?? "percent",
                value: order?.service_fee_unit_value ?? 0
            };
            schema.salesTax = {value: order?.kitchen_location?.sales_tax_rate ?? 0, percent: order?.kitchen_location?.sales_tax_rate};
            const tempItemList = [];
            const itemDetailList = [];
            category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    const tempCheckboxList = [];
                    const tempRadioList = [];
                    col.orderItemsModifiers.forEach((modifier) => {
                        if (modifier.modifier_group.is_allow_multiple) {
                            tempCheckboxList.push({
                                modifierGroupId: modifier.modifier_group.modifier_group_id,
                                modifierId: modifier.modifier.modifier_id,
                                itemId: col.item_id,
                                quantity: modifier.quantity,
                                series_no: modifier?.modifier?.series_no,
                                price: parseFloat(modifier.price).toFixed(2),
                                modifierGroupName: modifier.modifier_group.modifier_group_na,
                                allowQuantityModification: modifier.modifier_group.allow_quantity_mo,
                                modifier: {minimum_value: modifier?.modifier?.minimum_value},
                                splitModifier: modifier?.modifier_group?.split_modifiers
                            });
                        } else {
                            tempRadioList.push(
                                JSON.stringify({
                                    modifierGroupId: modifier.modifier_group.modifier_group_id,
                                    modifierId: modifier.modifier.modifier_id,
                                    itemId: col.item_id,
                                    quantity: modifier.quantity,
                                    price: parseFloat(modifier.price).toFixed(2),
                                    series_no: modifier?.modifier?.series_no,
                                    modifierGroupName: modifier.modifier_group.modifier_group_na,
                                    allowQuantityModification: modifier.modifier_group.allow_quantity_mo
                                })
                            );
                        }
                    });
                    if (col?.price) {
                        if (Number(col?.price / col?.quantity) !== Number(col.item.price)) {
                            setShowRegularPrice(true);
                        } else {
                            setShowRegularPrice(false);
                        }
                    } else {
                        setShowRegularPrice(false);
                    }
                    itemDetailList.push({
                        checkboxModifier: tempCheckboxList,
                        radioModifier: tempRadioList,
                        price: col?.price ? Number(col?.price / col?.quantity) : col.item.price,
                        regularPrice: col?.item?.price,
                        quantity: col.quantity,
                        specialInstruction: col.special_instruction,
                        packaging: [],
                        itemId: col.item_id,
                        minimum_order_quantity: Number(col?.item?.minimum_order_quantity),
                        itemOrdering: col.item_ordering,
                        servingSize: Number(col.item.serving_size),
                        menuCategoryId: col.menu_category_id,
                        item_type: col?.item?.item_type
                    });
                });
            });
            itemDetailList.sort((a, b) => a.itemOrdering - b.itemOrdering);
            itemDetailList.forEach((row1) => {
                tempItemList.push(row1.itemId);
            });

            schema.itemDetail = itemDetailList;
            COC.handleInitialValues(schema);

            setTimeout(() => {
                setOrderitems(tempItemList);
                setTouchFormikFields(true);
            }, 100);

            handlePastOrder(null);
        }
    };

    const getTemplateDetailById = async (id, formik) => {
        setOrderedBrandId("");
        setOrderedMenuId("");
        const instance = NetworkManager(API.ORDER.TEMPLATEDETAILBYID(id));
        let response = await instance.request();

        response = {
            code: response.code,
            data: {
                order: response.data.orderTemplateDetails,
                category: response.data.orderTemplateCategory.map((item) => {
                    return {
                        category: item.category,
                        orderItems: item.orderTemplateItems,
                        order_category_id: item.order_template_category_id
                    };
                })
            },
            message: response.message,
            success: response.success,
            error: response.error
        };

        if (response.success) {
            setOrderDetail(response.data);

            setOrderSaved(false);
            const schema = JSON.parse(JSON.stringify(formik.values));

            const {category, order} = response.data;

            schema.selectedBrand = order?.brand?.brand_id ?? "";
            schema.brandName = order?.brand?.brand_name;
            schema.selectedMenu = order?.menu?.menu_id ?? "";
            schema.tableware_plates = order?.tableware_plates ?? true;
            schema.tableware_cutlery = order?.tableware_cutlery ?? true;
            schema.serving_utensils = order?.serving_utensils ?? true;

            schema.brandName = order?.brand?.brand_name ?? "";

            schema.specialInstruction = order?.special_instructions ?? "";
            schema.kitchenNote = order?.kitchen_note ?? "";
            schema.calendarNote = order?.calendar_note ?? "";

            schema.deliveryInstruction = order?.delivery_instructions ?? "";

            schema.headerData.forHowMany = Number(schema.headerData.forHowMany) > 0 ? schema.headerData.forHowMany : order?.no_of_guest;
            schema.headerData.service = order?.service_type ?? "Catering";
            schema.headerData.orderType = order?.order_type ?? "Delivery";
            schema.addressChanged = false;
            schema.serviceFeeAdded = true;

            const tempItemList = [];
            const itemDetailList = [];
            category.forEach((row) => {
                row.orderItems.forEach((col) => {
                    const tempCheckboxList = [];
                    const tempRadioList = [];
                    col.orderTemplateItemsModifiers.forEach((modifier) => {
                        if (modifier.modifier_group.is_allow_multiple) {
                            tempCheckboxList.push({
                                modifierGroupId: modifier.modifier_group.modifier_group_id,
                                modifierId: modifier.modifier.modifier_id,
                                itemId: col.item_id,
                                quantity: modifier.quantity,
                                series_no: modifier?.modifier?.series_no,
                                price: parseFloat(modifier?.modifier?.price).toFixed(2),
                                modifierGroupName: modifier.modifier_group.modifier_group_name,
                                allowQuantityModification: modifier.modifier_group.allow_quantity_modification,
                                modifier: {minimum_value: modifier?.modifier?.minimum_value},
                                splitModifier: modifier?.modifier_group?.split_modifiers
                            });
                        } else {
                            tempRadioList.push(
                                JSON.stringify({
                                    modifierGroupId: modifier.modifier_group.modifier_group_id,
                                    modifierId: modifier.modifier.modifier_id,
                                    itemId: col.item_id,
                                    quantity: modifier.quantity,
                                    price: parseFloat(modifier?.modifier?.price).toFixed(2),
                                    series_no: modifier?.modifier?.series_no,
                                    modifierGroupName: modifier.modifier_group.modifier_group_name,
                                    allowQuantityModification: modifier.modifier_group.allow_quantity_modification
                                })
                            );
                        }
                    });

                    itemDetailList.push({
                        checkboxModifier: tempCheckboxList,
                        radioModifier: tempRadioList,
                        price: col?.price ? Number(col?.price / col?.quantity) : col.item.price,
                        regularPrice: col?.item?.price,
                        quantity: col.quantity,
                        specialInstruction: col.special_instruction,
                        packaging: [],
                        itemId: col.item_id,
                        minimum_order_quantity: Number(col?.item?.minimum_order_quantity),
                        itemOrdering: col.item_ordering,
                        servingSize: Number(col.item.serving_size),
                        menuCategoryId: col.menu_category_id,
                        item_type: col?.item?.item_type
                    });
                });
            });
            itemDetailList.sort((a, b) => a.itemOrdering - b.itemOrdering);
            itemDetailList.forEach((row1) => {
                tempItemList.push(row1.itemId);
            });

            schema.itemDetail = itemDetailList;

            COC.handleInitialValues(schema);
            setOrderedBrandId(order?.brand?.brand_id ?? "");
            setOrderedMenuId(order?.menu?.menu_id ?? "");

            setTimeout(() => {
                setOrderitems(tempItemList);
                setTouchFormikFields(true);
            }, 100);
            // handlePastOrder(null)
        }
    };

    React.useEffect(() => {
        if (open) {
            setFlag(false);
            if (OrderId) {
                getOrderDetailById(OrderId);
            } else if (open === "CreateOrder") {
                CreateNewOrder();
                setDiscountFlag(false);
                setISDiscountAdded(false);
            }
        }
    }, [open, OrderId]);

    React.useEffect(() => {
        if (pastOrderId) {
            if (orderIngFromPast === "pastOrder") {
                getPastOrderById(pastOrderId);
            }
        }
    }, [pastOrderId]);

    const handleClose = () => {
        toggleDilog(false)();
    };

    function numberToWords(number) {
        const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
        const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
        const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

        function convertToWordsLessThanThousand(num) {
            if (num === 0) {
                return "";
            } else if (num < 10) {
                return units[num];
            } else if (num < 20) {
                return teens[num - 10];
            } else {
                const digit = num % 10;
                const tenDigit = Math.floor(num / 10);
                return tens[tenDigit] + (digit !== 0 ? " " + units[digit] : ""); // eslint-disable-line prefer-template
            }
        }

        function convertToWords(num) {
            if (num === 0) {
                return "zero";
            }

            const billion = Math.floor(num / 1000000000);
            const million = Math.floor((num % 1000000000) / 1000000);
            const thousand = Math.floor((num % 1000000) / 1000);
            const remainder = num % 1000;

            let result = "";

            if (billion > 0) {
                result += convertToWordsLessThanThousand(billion) + " billion "; // eslint-disable-line prefer-template
            }

            if (million > 0) {
                result += convertToWordsLessThanThousand(million) + " million "; // eslint-disable-line prefer-template
            }

            if (thousand > 0) {
                result += convertToWordsLessThanThousand(thousand) + " thousand "; // eslint-disable-line prefer-template
            }

            if (remainder > 0) {
                result += convertToWordsLessThanThousand(remainder);
            }

            return result.trim();
        }

        return convertToWords(number);
    }

    const dynamicValidation = (values) => {
        const error = {
            itemModifier: []
        };

        values?.itemDetail?.forEach((row, index) => {
            const obbj = {};
            error.itemModifier.push(null);
            // if (Number(row?.quantity) < Number(row?.minimum_order_quantity)) {

            //     error.itemModifier[row.itemId] = `Minimum ${numberToWords(JSON.stringify(row.minimum_order_quantity)?.split(".")?.[0])} quantity required`;
            // }
            if (row?.checkboxModifier?.length) {
                row?.checkboxModifier?.forEach((col) => {
                    if (col?.modifierGroupId in obbj) {
                        obbj[col.modifierGroupId] += 1;
                    } else {
                        obbj[col?.modifierGroupId] = 1;
                    }
                    if (col.allowQuantityModification) {
                        if (col?.quantity < row?.quantity / 2) {
                            if (col?.modifier_group_name?.toLowerCase() === "drinks") {
                                error.itemModifier[index] = `For drinks modifier minimum quantity should be 1`;
                            }
                        }
                    }
                });
            }
            if (row?.radioModifier?.length) {
                row?.radioModifier?.forEach((col) => {
                    const col1 = JSON.parse(col);
                    if (col1?.modifierGroupId in obbj) {
                        obbj[col1?.modifierGroupId] += 1;
                    } else {
                        obbj[col1?.modifierGroupId] = 1;
                    }
                });
            }

            if (row.modifierData) {
                Object.keys(row?.modifierData).forEach((item) => {
                    if (row?.modifierData[item]?.is_required) {
                        if (!(item in obbj)) {
                            // es-lint disable line
                            error.itemModifier[index] = `Modifier ${row?.modifierData[item]?.name?.toLowerCase()} is required`;
                        }
                    }
                    if (
                        row?.modifierData[item]?.minimum_selections &&
                        Number(row.modifierData[item].minimum_selections) > Number(obbj[item] || 0)
                    ) {
                        error.itemModifier[index] = ` Minimim ${numberToWords(
                            row?.modifierData[item]?.minimum_selections
                        )} of ${row?.modifierData[item]?.name.toLowerCase()} is required`;
                    }
                    if (row?.modifierData[item]?.maximum_selections && Number(row.modifierData[item].maximum_selections) > 0) {
                        if (Number(row.modifierData[item].maximum_selections) < Number(obbj[item] || 0)) {
                            error.itemModifier[index] = ` Maximum ${numberToWords(
                                row?.modifierData[item]?.maximum_selections
                            )} of ${row?.modifierData[item]?.name.toLowerCase()} is allowed`;
                        }
                    }
                });
            }
        });
        const errorLength = error.itemModifier.filter((a) => a !== null);
        if (errorLength.length === 0) {
            return {};
        }
        return error;
    };

    const waitForTransitionThenUpdateheight = () => {
        const div = footerRef.current;
        if (div) {
            const {width, height} = div.getBoundingClientRect();
            const headerheight = 74 + 7;
            setMainSectionHeight(height + headerheight);
        }
    };

    const updateFooterDimensions = () => {
        setTimeout(() => waitForTransitionThenUpdateheight(), 1500);
    };

    // Add resize event listener to the ref
    useEffect(() => {
        updateFooterDimensions();
    }, [orderItems, open]);
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={dynamicValidation}
                validationSchema={Yup.object().shape({
                    itemDetail: Yup.array().min(1, "At least one item is required").required().nullable(),
                    headerData: Yup.object({
                        customer: Yup.object()
                            .shape({
                                userId: Yup.string().required("*Required field").nullable()
                            })
                            .nullable(),
                        service: Yup.string().required("*Required field").nullable(),
                        deliveryTo: Yup.string().required("*Required field").nullable(),
                        when: Yup.string().required("*Required field").nullable(),
                        forHowMany: Yup.string().required("*Required field").nullable()
                    }),
                    selectedMenu: Yup.string().required("*Required field").nullable(),
                    selectedBrand: Yup.string().required("*Required field").nullable(),
                    serviceFees: Yup.object().shape({
                        unitType: Yup.string(),
                        value: Yup.number().when("unitType", {
                            is: "percent",
                            then: Yup.number().max(100, "Value must not exceed 100")
                        })
                    }),
                    drive_time: Yup.string().required("Drive time is required ").nullable(),
                    setup_time: Yup.string().required("Setup time is required").nullable(),
                    name: Yup.string().required("*Contact name is required"),
                    phone: Yup.string().required("*Contact phone is required").length(14, "Contact phone must contain 10 digits"),
                    company: Yup.string().required("*Company or location name is required")
                })}
                onSubmit={(val, formikHelpers) => {
                    formikHelpers.setTouched({});
                    handleUpdateOrder(val);
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <CommonDilogBox openState="CreateOrder">
                                {open && (
                                    <Box sx={{display: "flex"}}>
                                        <SideSection
                                            formik={formik}
                                            initialValues={COC.defaultValues}
                                            drawerWidth={drawerWidth}
                                            mobileOpen={mobileOpen}
                                            handleDrawerToggle={handleDrawerToggle}
                                            orderId={orderId}
                                            ordereredId={orderId?.order_id}
                                            orderdName={orderId?.order_name}
                                            editScreen={OrderId}
                                            navigate={navigate}
                                            orderedMenuId={orderedMenuId}
                                            orderedBrandId={orderedBrandId}
                                            setOrderedMenuId={setOrderedMenuId}
                                            handleReset={handleReset}
                                            Tab={Tab}
                                            orderSaved={orderSaved}
                                            closeModal={closeModal}
                                            setCloseModal={setCloseModal}
                                            setWaitForItem={setWaitForItem}
                                            getTemplateDetailById={getTemplateDetailById}
                                            waitForItem={waitForItem}
                                            orderName={orderDetail?.order?.order_name}
                                            handleUpdateOrder={(values, isScheduleOrder = false, isAutoSave = false) => {
                                                handleUpdateOrder(formik.values, null, formik, isScheduleOrder, isAutoSave);
                                            }}
                                            cartName={cartName}
                                            setCartName={setCartName}
                                            setOrederPastData={setOrederPastData}
                                        />
                                        <Box
                                            component="main"
                                            sx={{
                                                display: "flex",
                                                flexGrow: 1,
                                                p: 0,
                                                width: {sm: `calc(100% - ${drawerWidth}px)`},
                                                position: "relative"
                                            }}
                                        >
                                            <HeaderSection
                                                orderId={orderId}
                                                formik={formik}
                                                drawerWidth={drawerWidth}
                                                customerName={CustomerName}
                                                customerId={CustomerId}
                                                editScreen={OrderId}
                                                handleDrawerToggle={handleDrawerToggle}
                                                preferrefPriceRef={preferrefPriceRef}
                                                kitchenIdRef={kitchenIdRef}
                                                deliveryDateRef={deliveryDateRef}
                                            />
                                            <Box
                                                sx={{
                                                    marginTop: "60px",
                                                    pl: "1.275rem",
                                                    pt: "1.25rem",
                                                    pr: "1.25rem",
                                                    height: `calc(100vh - ${mainSectionHeight}px)`,
                                                    overflowY: "scroll",
                                                    marginBottom: 2,
                                                    width: "100%",
                                                    position: "relative"
                                                }}
                                            >
                                                <MainSection
                                                    orderId={orderId}
                                                    formik={formik}
                                                    orderItems={orderItems}
                                                    setWaitForItem={setWaitForItem}
                                                    showRegularPrice={showRegularPrice}
                                                    setShowRegularPrice={setShowRegularPrice}
                                                />
                                            </Box>
                                            <Box
                                                id="order-footer"
                                                ref={footerRef}
                                                sx={{position: "fixed", bottom: 10, background: "#ffff", zIndex: 1}}
                                            >
                                                <CreateOrderFooter
                                                    navigate={navigate}
                                                    formik={formik}
                                                    handleUpdateOrder={(values, isScheduleOrder = false, isAutoSave = false) => {
                                                        handleUpdateOrder(values, null, formik, isScheduleOrder, isAutoSave);
                                                    }}
                                                    orderId={orderId}
                                                    initialValues={COC.defaultValues}
                                                    handleReset={handleReset}
                                                    setOpenDialog={setOpenDialog}
                                                    setOpenPaymentDialog={setOpenPaymentDialog}
                                                    setOpenRefundDialog={setOpenRefundDialog}
                                                    setRefundAmount={setRefundAmount}
                                                    setExtraAmount={setExtraAmount}
                                                    handlePaymentView={handlePaymentView}
                                                    Reorder={Reorder}
                                                    setFooterHeight={setFooterHeight}
                                                    updateFooterDimensions={updateFooterDimensions}
                                                    setCartName={setCartName}
                                                    customerName={CustomerName}
                                                    newServiceFessRef={newServiceFessRef}
                                                    preferrefPriceRef={preferrefPriceRef}
                                                    setOrderPlaced={setOrderPlaced}
                                                    orderPlaced={orderPlaced}
                                                    kitchenIdRef={kitchenIdRef}
                                                    deliveryDateRef={deliveryDateRef}
                                                    setSingleQuoteData={setSingleQuoteData}
                                                    setCreateData={setCreateData}
                                                    isPaymentButtonDisabled={isPaymentButtonDisabled}
                                                    setIsPaymentButtonDisabled={setIsPaymentButtonDisabled}
                                                    discountFlag={discountFlag}
                                                    setDiscountFlag={setDiscountFlag}
                                                    isDiscountAdded={isDiscountAdded}
                                                    setISDiscountAdded={setISDiscountAdded}
                                         
                                                />

                                                {state.right === "SendQuote" && (
                                                    <SendQuote
                                                        handleClose={handleClose}
                                                        orderId={orderId}
                                                        formik={formik}
                                                        buttonTitle="Send quote"
                                                        customerId={formik.values.headerData?.customer?.email}
                                                        userId={formik.values.headerData?.customer?.userId}
                                                        brandName={formik.values.brandName}
                                                        brandId={formik.values.selectedBrand}
                                                        singleQuoteData={singleQuoteData}
                                                        setSingleQuoteData={setSingleQuoteData}
                                                        initialValues={COC.defaultValues}
                                                        handleReset={handleReset}
                                                    />
                                                )}
                                                {formik.values.headerData?.customer?.userId && state.right === "Payment" && (
                                                    <PaymentForm
                                                        orderId={orderId}
                                                        customerId={formik.values.headerData?.customer?.userId}
                                                        name={formik.values.headerData?.customer?.name}
                                                        company={formik.values.headerData?.customer?.company}
                                                        handleReset={handleReset}
                                                        Parentformik={formik}
                                                        maininitialValues={COC.defaultValues}
                                                        extraAmount={extraAmount}
                                                        remainingAmountToPaid={remainingAmountToPaid}
                                                        refundAmount={refundAmount}
                                                        saveAdvanceDeposit={handleUpdateOrder}
                                                        chargedBy={formik.values.chargedByCardOrHouse}
                                                        orderStatus={formik.values.orderStatus}
                                                        paidAlready={formik.values.paid_already}
                                                        customerCardId={formik.values.customerCardId}
                                                        setCartName={setCartName}
                                                        setOrderPlaced={setOrderPlaced}
                                                        orderPlaced={orderPlaced}
                                                        customerPaymentProfileId={customerPaymentProfileId}
                                                        isPaymentButtonDisabled={isPaymentButtonDisabled}
                                                        setIsPaymentButtonDisabled={setIsPaymentButtonDisabled}
                                                    />
                                                )}
                                                {formik.values.headerData?.customer?.userId && state.right === "refund" && (
                                                    <RefundForm
                                                        // handleSelectedRow={handleSelectedRow}
                                                        customerId={formik.values.headerData?.customer?.userId}
                                                        orderId={orderId?.order_id}
                                                        orderNumber={orderId?.order_number}
                                                        refundAmount={refundAmount}
                                                        fromEditScreen="fromEditScreen"
                                                        type="Item refund"
                                                        // refundMethod={selectedRow?.charged_by}
                                                        orderData={{order: {remaining_amount_for_refund: refundAmount}}}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </CommonDilogBox>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default CreateOrder;
