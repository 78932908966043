import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import React from "react";
import {enforceFormat, enforceFormatForNumber} from "utils/helperFunctions/helpers";
import {ErrorMessage, FieldArray, Form, Formik} from "formik";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import useSettingSectionController from "./settingSection.controller";
import {BpRadio} from "commonComponent/Form/Fields/CustomRadioButton";
import Unchecked from "assets/images/icons/unchecked.svg";
import Checked from "assets/images/icons/lightCheckedBox.svg";

const SettingSection = (props) => {
    const {formik, isEdit = true} = props;
    const {getFieldProps} = formik;
    const STC = useSettingSectionController();

    const getPreparation = () => {
        return (
            <FormGroup>
                <Typography variant="checkBoxSelected" fontSize="13px" sx={{ mb: 2 }}>
                    Preparation
                </Typography>
                {STC?.prepareCheckboxes.map((obj, index) => (
                    <Stack key={index} direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                            disableRipple
                            icon={<img src={Unchecked} style={{ width: "18px", height: "18px" }} alt="unchecked" />}
                            checkedIcon={<img src={Checked} style={{ width: "18px", height: "18px" }} alt="checked" />}
                            checked={formik.values.preparation.includes(obj.value)}
                            onChange={(e) => {
                                const newValue = obj.value;
                                const currentPreparation = formik.values.preparation;
                                const updatedPreparation = currentPreparation.includes(newValue)
                                    ? currentPreparation.filter((value) => value !== newValue)
                                    : [...currentPreparation, newValue];
    
                                formik.setFieldValue("preparation", updatedPreparation);
                            }}
                        />
                        <Typography
                            fontSize="15px"
                            variant={
                                formik.values.preparation.length && formik.values.preparation.includes(obj.value)
                                    ? "checkBoxSelected"
                                    : "checkBoxUnSelected"
                            }
                            onClick={() => {
                                const newValue = obj.value;
                                const currentPreparation = formik.values.preparation;
                                const updatedPreparation = currentPreparation.includes(newValue)
                                    ? currentPreparation.filter((value) => value !== newValue)
                                    : [...currentPreparation, newValue];
    
                                formik.setFieldValue("preparation", updatedPreparation);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {obj.label}
                        </Typography>
                    </Stack>
                ))}
            </FormGroup>
        );
    };

    return (
        <Grid container>
            {formik?.values?.service_type === "Catering" && (
                <Grid item xs={12}>
                    <Box sx={{padding: "16px 0px"}}>
                        <Typography variant="headingOne">Settings</Typography>
                    </Box>
                </Grid>
            )}
            <Grid item container spacing={4} xs={12}>
                {formik?.values?.service_type === "Catering" && (
                    <Grid item container xs={12} spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Minimum order quantity"
                                label="Minimum order quantity"
                                inputProps={{maxLength: 7}}
                                onKeyDown={enforceFormatForNumber}
                                {...getFieldProps("minimumOrderQuantity")}
                            />
                            <ErrorMessage name="minimumOrderQuantity" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <CustomTextField
                                disabled={!isEdit}
                                fullWidth
                                placeholder="Serving size"
                                label="Serving size"
                                inputProps={{maxLength: 7}}
                                onKeyDown={enforceFormatForNumber}
                                {...getFieldProps("servingSize")}
                            />
                            <ErrorMessage name="servingSize" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                )}

                {formik?.values?.service_type === "Catering" && (
                    <Grid item spacing={2} container xs={12}>
                        <Grid item sm={6} xs={12}>
                            <FieldArray
                                name="temperatureServed"
                                render={() => (
                                    <FormControl>
                                        <Typography variant="checkBoxSelected" fontSize="13px" sx={{mb: 2}}>
                                            Temperature served
                                        </Typography>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={formik.values.temperatureServed[0] || ""}
                                            onChange={(e) => formik.setFieldValue("temperatureServed[0]", e.target.value)}
                                            name="radio-buttons-group"
                                        >
                                            {STC.RadioButtons?.map((obj, index) => (
                                                <FormControlLabel
                                                    sx={{height: 30}}
                                                    disabled={!isEdit}
                                                    key={index}
                                                    value={obj.value}
                                                    control={<BpRadio />}
                                                    label={
                                                        <Typography
                                                            fontSize="15px"
                                                            variant={
                                                                formik.values.temperatureServed[0] === obj.value
                                                                    ? "checkBoxSelected"
                                                                    : "checkBoxUnSelected"
                                                            }
                                                        >
                                                            {obj.lable}
                                                        </Typography>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                        <ErrorMessage
                                            name="temperatureServed"
                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                        />
                                    </FormControl>
                                )}
                            />
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            {getPreparation()}
                        </Grid>
                    </Grid>
                )}
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <CustomTextField
                            disabled={!isEdit}
                            fullWidth
                            placeholder="Kitchen note (For kitchen staff only)"
                            label="Kitchen note (For kitchen staff only)"
                            inputProps={{maxLength: 250}}
                            {...getFieldProps("kitchenNote")}
                        />
                        <ErrorMessage name="kitchenNote" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>

                    <Grid style={{paddingTop: "16px"}} spacing={2} item xs={12}>
                        <CustomTextField
                            disabled={!isEdit}
                            fullWidth
                            placeholder="Customer note (For customers only)"
                            label="Customer note (For customers only)"
                            inputProps={{maxLength: 250}}
                            {...getFieldProps("customerNote")}
                        />
                        <ErrorMessage name="customerNote" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SettingSection;
