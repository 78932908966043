import {Grid} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "store";
import useAuth from "hooks/useAuth";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import useToggleDilog from "hooks/useToggleDilog";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewCategoryForm from "views/pages/privatePages/brands/brandDetail/category/addNewCategoryForm";
import EditCategoryForm from "views/pages/privatePages/brands/brandDetail/category/editCategoryForm";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const CategoryList = (props) => {
    const {search, showFilter, handleHeaderActions, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const [open, setOpen] = React.useState(false);
    const {toggleDilog} = useToggleDilog();
    const {toggleDrawer, state} = useTempDrawer();
    const [categoryListing, setCategoryListing] = React.useState([]);
    const {orderUpdate} = useAuth();
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "category_name");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleMove = async (direction, row) => {
        const payload = {
            direction: direction
        };
        const instance = NetworkManager(API.CATEGORY.CHANGEORDER(row.id));
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        // eslint-disable-next-line no-use-before-define
        getCategoryList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                // toggleDilog("CreateTemplate")();
                // EditNewCategory
                toggleDrawer("right", "EditNewCategory")(e);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Move up": {
                handleMove("up", row);
                break;
            }
            case "Move down": {
                handleMove("down", row);
                break;
            }

            default:
                break;
        }
    };

    const getCategoryList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.CATEGORY.GET);
        const params = {
            page: currentPage,
            pageSize: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = "series_no";
            params.orderBy = "asc";
        }
        // while searching we dont need page number
        if (search === "") {
            params.page = currentPage;
        }

        if (search !== "") {
            params.search = search.trim();
            setCategoryListing([]);
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const states = response.data?.categoryList?.rows?.map((state, index) => ({
                category_name: state.category_name,
                label: state.category_name,
                id: state.category_id,
                description: state.description,
                menu_type: state.type,
                index: index,
                count: response.data?.categoryList?.count,
                isCategory: true
            }));
            setCategoryListing(states);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.CATEGORY.DELETE(selectedRow.id));

        const response = await instance.request({});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getCategoryList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };
    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));

        switch (val2) {
            case "category_name":
                dispatch(setOrderBy("category_name"));
                break;
            case "menu_type":
                dispatch(setOrderBy("type"));
                break;
            case "description":
                dispatch(setOrderBy("description"));
                break;

            default:
                dispatch(setOrderBy(val2));
                break;
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getCategoryList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, orderUpdate, isEdited, filterChips, updateListCount]);

    return (
        <>
            {state.right === "AddNewCategory" && (
                <AddNewCategoryForm
                    handleIsEdited={handleIsEdited}
                    selectedRow={null}
                    handleSelectedRow={() => {}}
                    handleChange={() => {
                        toggleDrawer("right", false)();
                    }}
                    updateCategory={() => {}}
                    isEditable
                />
            )}

            {state.right === "EditNewCategory" && (
                <EditCategoryForm
                    handleIsEdited={handleIsEdited}
                    selectedRow={selectedRow}
                    handleSelectedRow={handleSelectedRow}
                    isEditable
                    handleRefreshDataAfterAction={(response) => {
                        // const tempCat = JSON.parse(JSON.stringify(formik.values.category));
                        // tempCat[selectedIndex].label = response.categoryName;
                        // tempCat[selectedIndex].value = response.categoryName;
                        // tempCat[selectedIndex].description = response.description;
                        // formik.setFieldValue("category", tempCat);
                        // setSelectedIndex(null);
                        // setSelectedRow(null);
                    }}
                    currentCategory={selectedRow?.id}
                    updateCategory={(cat) => {}}
                />
            )}

            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        loading={loading}
                        withCheckbox
                        data={categoryListing}
                        currentTab="brands"
                        header={[
                            {key: "category_name", label: "Name"},
                            {key: "menu_type", label: "Type"},
                            {key: "descriptioncat", label: "Description"},

                            WithPermission({
                                module: "Brands",
                                permission: ["delete_permission", "create_permission", "update_permission"]
                            }) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Brands", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Brands", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        handleHeaderActions={handleHeaderActions}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        // sortingLabel={["Name"]}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this category?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default CategoryList;
