import React from 'react';
import { Typography } from "@mui/material";
import { ellipsizeText } from 'commonComponent/Apptable/textUtils';

const AddressComponent = ({ row, isMobile = false }) => {
    return (row?.address_details && row?.address_details?.street_line) ? (
        <>
            {row?.address_details?.street_line && (
                <>
                    <Typography
                        // variant="subtitle3"
                        // sx={{ 
                        //     "@media (max-width:600px)": {
                        //         fontSize: "0.9rem"
                        //     }
                        // }}
                        variant="body3" sx={{ fontWeight: 400, fontSize: isMobile ? "10px" : "12px" }}
                    >
                        {row.address_details ? ellipsizeText(`${row?.address_details?.street_line}${row?.address_details?.apartment_name && row?.address_details?.apartment_name !== "" ? `, ${row?.address_details?.apartment_name}` : ""}`, 22) : "N/A"}
                    </Typography>
                </>
            )}
            {row?.address_details?.street_line &&<Typography
                // variant="subtitle3"
                // sx={{
                //     "@media (max-width:600px)": {
                //         fontSize: "0.9rem"
                //     }
                // }}
                variant="secondaryDataText"
                fontWeight={400}
                fontSize="0.69em"
                sx={(t) => ({
                    color: t.palette.secondary[600],
                    textDecoration: "none",
                    opacity: 0.6,
                    fontSize: "11px"
                })}
            >
                {ellipsizeText(`${row?.address_details?.city !=="" ? `${row?.address_details?.city}, `:"" }${row?.address_details?.state !==""? `${row?.address_details?.state} `:""}${row?.address_details?.pincode}`,22)}
            </Typography>}{" "}
        </>
    ) : (
        <>
            <Typography variant="body3" sx={{ fontWeight: 400, fontSize: isMobile ? "10px" : "12px" }}>
                {row.delivery_address ? ellipsizeText(row.delivery_address.split(",")[0], 22) : "N/A"}
            </Typography>
            <Typography
                variant="secondaryDataText"
                fontWeight={400}
                fontSize="0.69em"
                sx={(t) => ({
                    color: t.palette.secondary[600],
                    textDecoration: "none",
                    opacity: 0.6,
                    fontSize: "11px"
                })}
            >
                {/* {row.delivery_address ? ellipsizeText(row.delivery_address.split(",").splice(1).join(","), 22): (
                            <span>&nbsp;</span>
                        )} */}
                         {ellipsizeText(row?.delivery_address?.split(",")?.splice(1)?.join(","), 22)}
            </Typography>
        </>
    )
}


export default AddressComponent