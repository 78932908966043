import {Box, Grid, Typography, Stack} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import {ErrorMessage, Form, Formik} from "formik";
import React from "react";
import PropTypes from "prop-types";
import LocationDetails from "./formComponents/LocationDetails";
import Fees from "./formComponents/Fees";
import Delivery from "./formComponents/Delivery";
import HoursOfOperation from "./formComponents/HoursOfOperation/HoursOfOperation";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import {addLocationValidation} from "utils/validations/validations";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";
import HomeMeal from "./formComponents/HomeMeal";

const allowHomeMealsUI = process.env.REACT_APP_ALLOW_HOME;

export const MainHeading = (props) => {
    const {title} = props;
    return (
        <Box sx={{pt: 4, pb: 3}}>
            <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                {title}
            </Typography>
        </Box>
    );
};

export const SubContainer = (props) => {
    const {title, switchBtn, formik, readOnly = false, children, childFlex, fontSize = "18px", fontWeight = 500, color = "#020A11"} = props;
    const prm = useParams();
    const dispatch = useDispatch();
    const {id, type} = prm;

    const handleStatus = async (kitchenLocationId, type) => {
        // if selectedRow is not null have to add PUT api else ADD api

        const instance = NetworkManager(API.LOCATION.STATUS(kitchenLocationId));

        const payload = {status: type};

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        }
    };

    return (
        <Grid
            item
            xs={12}
            sx={(t) => ({
                p: 2,
                border: "1px solid #dbdbdb",
                borderRadius: 1,
                height: "100%",
                display: childFlex ? "flex" : "block",
                ...(childFlex && {flexDirection: "column"})
            })}
        >
            <Grid item container justifyContent="space-between" alignItems={childFlex ? "flex-start" : "center"} xs={12} sx={{mb: 2}}>
                <Grid item>
                    <Typography variant="body4" fontSize={fontSize} fontWeight={fontWeight} color={color}>
                        {title}
                    </Typography>
                    {title === "Standard hours" && typeof formik?.errors?.hoursOfOperation?.standardHours === "string" && (
                        <ErrorMessage name="hoursOfOperation.standardHours" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                    )}
                </Grid>
                {switchBtn && (
                    <Grid item>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                fontSize="0.875rem"
                                fontWeight={700}
                                sx={{color: formik.values.temporaryClosures.isActive ? "#A1A3A8" : "#F0475C"}}
                            >
                                Inactive
                            </Typography>
                            <CustomSwitch
                                disabled={readOnly}
                                checked={formik.values.temporaryClosures.isActive}
                                onChange={(e) => {
                                    // if (!e.target.checked) {
                                    //     formik.setFieldValue(`temporaryClosures.cloures`, [
                                    //         {
                                    //             from: "",
                                    //             startingAt: "",
                                    //             through: "",
                                    //             endingAt: ""
                                    //         }
                                    //     ]);
                                    // }
                                    handleStatus(id, e.target.checked);

                                    formik.setFieldValue("temporaryClosures.isActive", e.target.checked);
                                }}
                            />
                            <Typography
                                fontSize="0.875rem"
                                fontWeight={700}
                                sx={{color: !formik.values.temporaryClosures.isActive ? "#A1A3A8" : "#00ab3a"}}
                            >
                                Active
                            </Typography>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};

const CreateLocationForm = ({CLC}) => {
    const navigation = useNavigate();
    const [searchParam] = useSearchParams();
    const marketId = searchParam.get("marketId");
    const marketName = searchParam.get("marketName");

    const handleCancel = (formik) => {
        formik.resetForm();
        navigation(marketId ? `/marketLocations/${marketId}?marketName=${marketName}` : "/locations");
    };

    return (
        <Box sx={{mb: 5}}>
            <Formik
                enableReinitialize
                initialValues={CLC.initialVlaues}
                validationSchema={addLocationValidation}
                onSubmit={(val, {setSubmitting}) => {
                    CLC.handleSubmitForm(val, setSubmitting);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container sx={{mt: -1}}>
                            <Grid item xs={12}>
                                <MainHeading title="Location details" />
                                <LocationDetails formik={formik} controller={CLC} marketName={marketName} />
                            </Grid>
                            <Grid item xs={12}>
                                <MainHeading title="Brands" />
                                <Grid item md={5.84} xs={12}>
                                    <CustomSelectWithCheckbox
                                        disabled={CLC.readOnly}
                                        selectAll
                                        label="Brands"
                                        menuItems={CLC.brandList.map((obj) => ({label: obj.brand_name, id: obj.brand_id}))}
                                        values={formik.values.brandsmarket}
                                        onChange={(val) => formik.setFieldValue("brandsmarket", val)}
                                    />
                                    <ErrorMessage name="brandsmarket" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <MainHeading title="Settings" />
                                <Grid item lg={10} md={10} xs={12}>
                                    <HoursOfOperation formik={formik} readOnly={CLC.readOnly} timezone={CLC.timezone} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 3}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid item lg={10} md={10} xs={12}>
                                            <SubContainer title="Delivery">
                                                <Delivery formik={formik} readOnly={CLC.readOnly} />
                                            </SubContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item lg={10} md={10} xs={12}>
                                            <SubContainer title="Rates and fees">
                                                <Fees formik={formik} readOnly={CLC.readOnly} />
                                            </SubContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {allowHomeMealsUI === "true" && (
                                <Grid item xs={12} sx={{mt: 3}}>
                                    <Grid item lg={10} md={10} xs={12}>
                                        <SubContainer title="Home meals delivery">
                                            <HomeMeal formik={formik} readOnly={CLC.readOnly} />
                                        </SubContainer>
                                    </Grid>
                                </Grid>
                            )}
                            {!CLC.readOnly && (
                                <Grid item xs={12} sx={{pt: 3, pb: 1, zIndex: 1, position: "sticky", bottom: 0, bgcolor: "#fff"}}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({color: t.palette.error.dark}),
                                                onClick: () => handleCancel(formik)
                                            },
                                            {
                                                btnTitle: "Save",
                                                variant: "contained",
                                                disabled: formik.isSubmitting,
                                                sx: (t) => ({color: t.palette.background.paper}),
                                                onClick: formik.handleSubmit
                                            }
                                        ]}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

MainHeading.propTypes = {
    title: PropTypes.string
};

export default CreateLocationForm;