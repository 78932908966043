import React, {useState} from "react";
import Tooltip from "commonComponent/Tooltip";
import Typography from "@mui/material/Typography";
import {ellipsizeText} from "./textUtils";

const CusineDisplay = ({cusineData}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const cusineItems = [];
    cusineData.cuisines.forEach((row) => {
        cusineItems.push(row?.cusine?.cusine);
    });

    const count = cusineItems.length;

    // Display the first 2 menu names for each menu category
    const initialCusineItems = cusineItems
        .slice(0, 2)
        .map((cusine) => ellipsizeText(cusine))
        .join(", ");

    // Display "View more" button and tooltip
    const tooltipContent = cusineItems
        .slice(2)
        .map((cusine) => cusine)
        .join(", ");

    return (
        <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
            <div>
                {initialCusineItems}

                {tooltipContent && (
                    <Tooltip
                        title={
                            <Typography variant="body3" sx={{fontWeight: 400, fontSize: "11px"}}>
                                {tooltipContent}
                            </Typography>
                        }
                        arrow
                        followCursor
                        open={isTooltipOpen}
                        onOpen={() => setIsTooltipOpen(true)}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <small style={{cursor: "pointer", color: "#00ab3a", fontWeight: 500, fontSize: "11px"}}>
                            &nbsp;+{count - 2} more
                        </small>
                    </Tooltip>
                )}
            </div>
        </Typography>
    );
};

export default CusineDisplay;
