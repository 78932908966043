import {Box, Grid, Typography} from "@mui/material";
import {getCategoryStyle} from "commonComponent/Dragger/utils";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import AddNewModifier from "commonComponent/ModifierComponent/AddModifier/AddNewModifier";
import {ErrorMessage, FieldArray} from "formik";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import useIncludedGroupController from "./includedOption.controller";
import IncludedOptionItems from "./IncludedOptionItems";
import AddIncludedItems from "./AddIncludedItem";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";

const IncludedOptions = (props) => {
    const {formik, hidePrice = false, placeHolder = null, handlePrepSelect, methodsofPreparation, categoryList} = props;
    const {onDragEnd, handleActionBtn, open, handleDeleteModal, handleDelete} = useIncludedGroupController(formik);
    const arrayToMap = formik?.values?.includedOptions;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="headingOne">{hidePrice ? "Included" : "Modifier"} options</Typography>
                <ErrorMessage name="includedOptions" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Grid>

            <Grid item xs={12}>
                <Box>
                    <DragDropContext onDragEnd={onDragEnd(formik)}>
                        <Droppable droppableId="droppable" type="QUESTIONS">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} style={{background: "#ffff"}}>
                                    <FieldArray
                                        name="includedOptions"
                                        render={(arrayHelper) => (
                                            <>
                                                {arrayToMap?.map((obj, index) => (
                                                    <Draggable key={obj.series_no} draggableId={`${obj.series_no}`} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getCategoryStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            >
                                                                <IncludedOptionItems
                                                                    index={index}
                                                                    arrayHelper={arrayHelper}
                                                                    dataObj={obj}
                                                                    provided={provided}
                                                                    handleActionBtn={handleActionBtn}
                                                                    categoryList={categoryList}
                                                                    methodsofPreparation={methodsofPreparation}
                                                                    handlePrepSelect={handlePrepSelect}
                                                                    arrayToMap={arrayToMap}
                                                                />

                                                                {/* <EditModifier
                                                                    modifierGroupId={1}
                                                                    index={index}
                                                                    arrayHelper={arrayHelper}
                                                                    provided={provided}
                                                                    dataObj={obj}
                                                                    // handleActionBtn={MOC.handleActionBtn}
                                                                    allowedSplit={formik.values.allowQuantityModification}
                                                                /> */}
                                                                <DeleteModal
                                                                    noOnClose
                                                                    open={open}
                                                                    buttonTitle="Delete"
                                                                    title="Are you sure you want to delete included item?"
                                                                    description="This will permanently remove all data associated with it."
                                                                    handleClose={handleDeleteModal}
                                                                    handleSubmit={() => handleDelete(arrayHelper)}
                                                                    hideBackdrop
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </>
                                        )}
                                    />

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <AddIncludedItems
                    parentFormik={formik}
                    handlePrepSelect={handlePrepSelect}
                    methodsofPreparation={methodsofPreparation}
                    categoryList={categoryList}
                    placeHolder="Add item name"
                />
                {/* <AddNewModifier
                    parentFormik={formik}
                    modifierGroupId={formik.values.modifierGroupId}
                    allowedSplit={formik.values.allowQuantityModification}
                    hidePrice
                    placeHolder={placeHolder}
                /> */}
            </Grid>
        </Grid>
    );
};

export default IncludedOptions;
