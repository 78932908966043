import {Grid, Typography} from "@mui/material";
import {Box, Stack} from "@mui/system";
import {getCategoryStyle} from "commonComponent/Dragger/utils";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelectWithCheckbox from "commonComponent/Form/Fields/CustomeSelectWithCheckbox";
import {ErrorMessage, FieldArray} from "formik";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IconGripVertical} from "@tabler/icons";
import ActionButton from "commonComponent/Apptable/ActionButton";
import useIdentifierGroupController from "./includedItemSection.controller";
import AddEditIncludedGroup from "commonComponent/IncludedGroupComponents/AddEditIncludedGroup";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const IncludedGroupSection = (props) => {
    const {isEdit, formik} = props;
    const {identiferGroup, onDragEnd, handleActionBtn, selectedRow, handleSelectedRow, handleIsEdited} =
        useIdentifierGroupController(props);
   
    return (
        <Grid container>
            <Grid item container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="headingOne">Included groups</Typography>
                </Grid>

                <Grid item xs={12}>
                    <CustomSelectWithCheckbox
                        disabled={!isEdit}
                        hasSearch
                        label="Included groups"
                        menuItems={identiferGroup}
                        values={formik.values.includedGroups.map((obj) => obj?.id)}
                        onChange={(val) => {
                            const selectedItem = identiferGroup.filter((obj) => val.includes(obj.id));
                            formik.setFieldValue("includedGroups", selectedItem);
                        }}
                    />
                    <ErrorMessage name="modifier_group_id" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </Grid>
                <Grid item xs={12}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" type="QUESTIONS">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} style={{background: "#ffff"}}>
                                    <FieldArray
                                        name="includedGroups"
                                        render={(arrayHelpers) => (
                                            <>
                                                {formik.values.includedGroups.length > 0 &&
                                                    formik.values.includedGroups.map((group, index) => (
                                                        <>
                                                            <Draggable
                                                                key={`${group?.id}${index}`}
                                                                draggableId={`${group?.id}${index}`}
                                                                index={index}
                                                            >
                                                                {(provided, snapshot) => {
                                                                    const arrayToMap = group?.includedGroup?.iGOs ?? group?.iGOs;
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            style={getCategoryStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center"
                                                                                }}
                                                                            >
                                                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                                                    <span {...provided.dragHandleProps}>
                                                                                        <IconGripVertical
                                                                                            icon="grip-vertical"
                                                                                            style={{color: "#E0E2E7"}}
                                                                                        />
                                                                                    </span>
                                                                                    <Box style={{cursor: "pointer"}}>
                                                                                        <Typography variant="body4" fontSize="14px">
                                                                                            {group?.label}
                                                                                        </Typography>
                                                                                        {arrayToMap?.length > 0 ? (
                                                                                            <Typography fontSize="12px">
                                                                                                {arrayToMap
                                                                                                    ?.sort(
                                                                                                        (a, b) =>
                                                                                                            a?.series_no - b?.series_no
                                                                                                    )
                                                                                                    ?.map(
                                                                                                        (el, i) =>
                                                                                                            `${i > 0 ? ", " : ""}${
                                                                                                                el?.included_group_item_name
                                                                                                            }`
                                                                                                    )}
                                                                                            </Typography>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </Box>
                                                                                </Stack>

                                                                                {isEdit && (
                                                                                    <ActionButton
                                                                                        row={group}
                                                                                        ActionArray={[
                                                                                            WithPermission({module: "IncludedGroup", permission: ["update_permission"]})&&"Edit"
                                                                                            , "Remove"]}
                                                                                        handleActionBtn={(type, row, handleClose) =>
                                                                                            (e) => {
                                                                                                handleActionBtn(
                                                                                                    type,
                                                                                                    row,
                                                                                                    handleClose,
                                                                                                    arrayHelpers,
                                                                                                    index
                                                                                                )(e);
                                                                                            }}
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                            <AddEditIncludedGroup
                                                                selectedRow={selectedRow}
                                                                fromItemFlow
                                                                handleSelectedRow={handleSelectedRow}
                                                                title="Edit included group"
                                                                handleIsEdited={handleIsEdited}
                                                                includedItemHelper={arrayHelpers}
                                                                itemIndex={index}
                                                            />
                                                        </>
                                                    ))}
                                                {provided.placeholder}
                                            </>
                                        )}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IncludedGroupSection;
