import {Box, Grid, Typography} from "@mui/material";
import {getCategoryStyle} from "commonComponent/Dragger/utils";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import useModifierSectionController from "./modifierSection.controller";
import {FieldArray} from "formik";
import EditModifier from "commonComponent/ModifierComponent/AddModifier/EditModifier";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import AddNewModifier from "commonComponent/ModifierComponent/AddModifier/AddNewModifier";

const ModifierSection = (props) => {
    const {formik, placeHolder = null} = props;
    const MOC = useModifierSectionController(props);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="headingOne">Modifier options</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box>
                        <DragDropContext onDragEnd={MOC.onDragEnd(formik)}>
                            <Droppable droppableId="droppable" type="QUESTIONS">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={{background: "#ffff"}}>
                                        <FieldArray
                                            name="modifierOptions"
                                            render={(arrayHelper) => (
                                                <>
                                                    {formik.values.modifierOptions?.map((obj, index) => (
                                                        <Draggable key={obj.id} draggableId={obj.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={getCategoryStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <EditModifier
                                                                        modifierGroupId={formik.values.modifierGroupId}
                                                                        index={index}
                                                                        arrayHelper={arrayHelper}
                                                                        provided={provided}
                                                                        dataObj={obj}
                                                                        handleActionBtn={MOC.handleActionBtn}
                                                                        allowedSplit={formik.values.allowQuantityModification}
                                                                    />
                                                                    <DeleteModal
                                                                        noOnClose
                                                                        open={MOC.open}
                                                                        buttonTitle="Delete"
                                                                        title="Are you sure you want to delete modifier?"
                                                                        description="This will permanently remove all data associated with it."
                                                                        handleClose={MOC.handleDeleteModal}
                                                                        handleSubmit={MOC.handleDelete}
                                                                        hideBackdrop
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </>
                                            )}
                                        />

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <AddNewModifier
                        parentFormik={formik}
                        modifierGroupId={formik.values.modifierGroupId}
                        allowedSplit={formik.values.allowQuantityModification}
                        hidePrice
                        placeHolder={placeHolder}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ModifierSection;
