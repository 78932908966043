const useSettingSectionController = () => {
    const RadioButtons = [
        {lable: "Hot", value: "HOT"},
        {lable: "Warm", value: "WARM"},
        {lable: "Cold", value: "COLD"}
    ];
    const checkBoxs = [
        {lable: "Tray", value: "tray"},
        {lable: "Individual", value: "individual"}
    ];

    const prepareCheckboxes = [
        {label: "Prep", value: "prep"},
        {label: "Fire", value: "fire"}
    ];

    return {
        RadioButtons,
        checkBoxs,
        prepareCheckboxes
    };
};

export default useSettingSectionController;
