import {Box, Divider, Stack, Typography} from "@mui/material";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBarModifier";
import {ErrorMessage, Form, Formik} from "formik";
import React from "react";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import {AddEditIncludedOptions} from "utils/validations/validations";
import useTempDrawer from "hooks/useTempDrawerModifier";
import Loader from "ui-component/Loader";
import {useAddIncludedGroupController} from "./addEditIncludedGroup.controller";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import IncludedOptions from "../IncludedOptions";

const AddEditIncludedGroup = (props) => {
    const {selectedRow, handleSelectedRow, fromItemFlow, handleIsEdited, title = "Add included group"} = props;
    const {toggleDrawer, state} = useTempDrawer();
    const {
        initialState,
        setinitialState,
        loading,
        defaultState,
        handlePrepSelect,
        methodsofPreparation,
        categoryList,
        postNewIncludedGroup
    } = useAddIncludedGroupController(props);

    console.log(initialState, "STATE IS HERE");
    const dynamicValidation = (values) => {
        const {includedGroupName, includedOptions} = values;

        const error = {};

        console.log(values, "VALYES");

        if (includedGroupName === "") {
            error.includedGroupName = "Included group name is required*";
        }
        if (includedOptions?.length === 0) {
            error.includedOptions = "At least 1 included item should be added*";
        }
        return error;
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={AddEditIncludedOptions}
            validate={dynamicValidation}
            onSubmit={(val, e) => {
                postNewIncludedGroup(val, e);
            }}
        >
            {(formik) => (
                <Form>
                    <TemporaryDrawer
                        sideBarStateName="addIncludedGroup"
                        sx={{pb: 1, pr: 0}}
                        handleSelectedRow={handleSelectedRow}
                        resetForm={formik.resetForm}
                        whileCloseDoThis={() => {
                            handleIsEdited();
                            
                        }}
                    >
                        <Stack sx={{height: "100%", position: "relative"}}>
                            {loading ? (
                                <Loader />
                            ) : (
                                <Box sx={{overflow: "scroll", height: "92%", pr: 2}}>
                                    <Box sx={{pb: 2}}>
                                        <Typography sx={{fontSize: "1.625rem", fontWeight: 700, marginBottom: "0.5rem"}}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <CustomTextField
                                            // disabled={!isEdit}
                                            fullWidth
                                            placeholder="Included group name (internal)"
                                            label="Modifier group name (internal)"
                                            inputProps={{maxLength: 250}}
                                            {...formik.getFieldProps("includedGroupName")}
                                        />
                                        <ErrorMessage
                                            name="includedGroupName"
                                            render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                        />
                                    </Box>
                                    <Box pt="18px" pb="16px">
                                        <Divider />
                                    </Box>
                                    <Box name="includedOptions">
                                        <IncludedOptions
                                            formik={formik}
                                            hidePrice
                                            handlePrepSelect={handlePrepSelect}
                                            methodsofPreparation={methodsofPreparation}
                                            categoryList={categoryList}
                                        />
                                    </Box>
                                </Box>
                            )}
                            <Box sx={{position: "absolute", bottom: 0, width: "100%", pr: 2}}>
                                <ButtonGrouping
                                    btnprops={[
                                        {
                                            btnTitle: "Cancel",
                                            sx: (t) => ({color: t.palette.error.dark}),
                                            onClick: (e) => {
                                                formik.resetForm();
                                                handleSelectedRow(null);
                                                if (formik.values.modifierGroupId) {
                                                    handleIsEdited();
                                                    setinitialState(defaultState);
                                                }
                                                if (fromItemFlow) {
                                                    toggleDrawer("right", true)(e);
                                                } else {
                                                    toggleDrawer("right", false)(e);
                                                }
                                            }
                                        },
                                        {
                                            btnTitle: "Save",
                                            disabled: formik.isSubmitting,
                                            variant: "contained",
                                            sx: (t) => ({color: t.palette.background.paper}),
                                            onClick: (e) => {
                                                formik.handleSubmit(e);
                                            }
                                        }
                                    ]}
                                />
                            </Box>
                        </Stack>
                    </TemporaryDrawer>
                </Form>
            )}
        </Formik>
    );
};

export default AddEditIncludedGroup;
