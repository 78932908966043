/* eslint-disable no-plusplus */
import {Reorder} from "commonComponent/Dragger/utils";
import {API, NetworkManager} from "network/core";
import {useState} from "react";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";

const useIncludedGroupController = (props) => {
    const {formik} = props;

    const modifierGroupId = 1;

    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);



    const onDragEnd = (formik) => (result) => {
        const {source, destination, type} = result;

        if (!destination) return;

        if (type === "QUESTIONS") {
            const reorderedQuestions = Reorder(formik.values.includedOptions, source.index, destination.index);

            formik.setFieldValue("includedOptions", reorderedQuestions);

            
        } else {
            const questionIndex = parseInt(type, 10);
            const reorderedAnswers = Reorder(formik.values.includedOptions[questionIndex].answers, source.index, destination.index);

            const updatedQuestions = [...formik.values.includedOptions];
            updatedQuestions[questionIndex] = {
                ...updatedQuestions[questionIndex],
                answers: reorderedAnswers
            };

            formik.setFieldValue("includedOptions", updatedQuestions);

          
        }
    };

    const handleDeleteModal = (index) => {
        setSelectedRow(index);
        setOpen((pre) => !pre);
    };

    const handleDelete = (arrayHelper) => {
        arrayHelper.remove(selectedRow);
        handleDeleteModal(null);
    };

    const handleDuplicateModifier = async (arrayHelper, index, row, allArray) => {
        const arrayMap = new Map();
        allArray.forEach((item) => {
            const baseName = item?.name.split(" Copy")[0].trim();
            if (!arrayMap.has(baseName)) {
                arrayMap.set(baseName, [item]);
            } else {
                arrayMap.set(baseName, [...arrayMap.get(baseName), item]);
            }
        });

        const baseName = row?.name.split(" Copy")[0].trim();
        const existingCopies = arrayMap.get(baseName) ?? [];

        let newName;
        const copyPattern = /copy\s?(\d+)$/i;
        const lastPartMatch = row.name.match(copyPattern);

        if (lastPartMatch) {
            const baseWithoutLastCopy = row.name.replace(copyPattern, "").trim();
            const lastCopyNumber = parseInt(lastPartMatch[1], 10);
            let nextCopyNumber = 1;
            const nameSet = new Set(existingCopies.map((item) => item.name));
            while (nameSet.has(`${baseWithoutLastCopy} Copy${lastCopyNumber} Copy${nextCopyNumber}`)) {
                nextCopyNumber++;
            }
            newName = `${baseWithoutLastCopy} Copy${lastCopyNumber} Copy${nextCopyNumber}`;
        } else {
            let nextCopyNumber = 1;
            const nameSet = new Set(existingCopies.map((item) => item.name));
            while (nameSet.has(`${baseName} Copy ${nextCopyNumber}`)) {
                nextCopyNumber++;
            }
            newName = `${baseName} Copy ${nextCopyNumber}`;
        }
        const modifier = {
            isEdit: false,
            name: newName,
            dietary: row.dietary ?? [],
            preparation: new Set(row?.preparation) ?? new Set(),
            category: row.category,
            series_no: allArray?.length
        };

        arrayHelper.insert(index, modifier);
    };
    const handleActionBtn = (type, row, handleClose, arrayHelper, index, arrayToMap) => (e) => {
        handleClose();
        switch (type) {
            case "Edit": {
                arrayHelper.replace(index, {...row, isEdit: true});
                break;
            }
            case "Duplicate": {
                handleDuplicateModifier(arrayHelper, index, row, arrayToMap);
                break;
            }
            case "Delete": {
                handleDeleteModal(index);
                break;
            }
            default:
                break;
        }
    };

    return {
        open,
        handleDeleteModal,
        handleDelete,
        onDragEnd,
        handleActionBtn
    };
};

export default useIncludedGroupController;

