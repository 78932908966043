import React, {useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import useTempDrawer from "hooks/useTempDrawer";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import {useMediaQuery, useTheme} from "@mui/material";
import {setCustomerPaymentProfileId} from "store/slices/card";
import {dispatch} from "store";

export default function TemporaryDrawer({
    children,
    sx,
    handleSelectedRow = () => {},
    resetForm = () => {},
    touched = null,
    useForFilter,
    sideBarStateName = false,
    whileCloseDoThis = () => {},
    closingFunction = () => {},
    text
}) {
    const {state, toggleDrawer} = useTempDrawer();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));
    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
        dispatch(setCustomerPaymentProfileId(""));
    };

    const handleDelete = () => {
        handleSelectedRow(null);
        resetForm();
        toggleDrawer("right", false)(anchor);
        setAnchor(null);
        handleDeleteModal();
        whileCloseDoThis();
        if (text) {
            localStorage.setItem("quote", text);
        }
    };

    const list = (anchor, sideBarStateName) => (
        <Box sx={{width: !matchUpMd ? 320 : 480, p: 3, height: "100%", ...(sx && {...sx})}} role="presentation">
            <List sx={{height: "100%"}}>{children}</List>
        </Box>
    );
console.log(sideBarStateName, "sideBarStateName")
    return (
        <div>
            {["left", "right", "top", "bottom"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        sx={{zIndex: 1300}}
                        anchor={anchor}
                        open={sideBarStateName ? state[anchor] === sideBarStateName : state[anchor]}
                        onClose={(e) => {
                            // whileCloseDoThis();

                            if (useForFilter) {
                                toggleDrawer("right", false)(anchor);
                                return;
                            }
                            if (touched && Object.keys(touched).length === 0) {
                                toggleDrawer("right", false)(anchor);
                                closingFunction();
                            } else {
                                setOpen(true);
                                setAnchor(e);
                            }
                        }}
                    >
                        {list(anchor, sideBarStateName)}
                    </Drawer>
                    <DeleteModal
                        open={open}
                        buttonTitle="Close"
                        title="Are you sure you want to exit?"
                        description="Your data will not be saved."
                        handleClose={handleDeleteModal}
                        handleSubmit={handleDelete}
                        hideBackdrop
                    />
                </React.Fragment>
            ))}
        </div>
    );
}
