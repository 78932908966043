import {Grid} from "@mui/material";
import React, {useEffect, useState, useContext} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import Scheduled from "./Tabs/scheduled/Scheduled";
import History from "./Tabs/History/History";
import Canceled from "./Tabs/Canceled/Canceled";
import Rejected from "./Tabs/Rejected/Rejected";
import Unplaced from "./Tabs/Unplaced/Unplaced";
import useToggleDilog from "hooks/useToggleDilog";
// import CreateOrder from "./CreateOrder/CreateOrder";
import useTempDrawer from "hooks/useTempDrawer";
import {FilterContext} from "contexts/FilterContext";
import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import RefundForm from "./CreateOrder/createOrderFooter/Refund";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import {useOrderController} from "./orders.controller";
import FilterChips from "commonComponent/FilterChipsComponent";
import {AnimatePresence} from "framer-motion";
import DraftTab from "./Tabs/Draft/draft";
import {resetState, setShowFilter} from "store/slices/paginationSlice";
import networkManager from "network/core/networkManager";

const Orders = () => {
    const {
        getMarketList,
        marketList,
        getBrandList,
        brandList,
        getCustomerListing,
        customers,
        getCompanyListing,
        companyList,
        getTabsData,
        setCustomers
    } = useOrderController();
    const [value, setValue] = React.useState("scheduled");
    const [btnlabel, setBtnLebel] = useState("Create order");
    const {openFilter, handleFilter} = useContext(FilterContext);
    const [heading, setHeading] = useState("Orders");
    const [search, setSearch] = useState("");
    // const [showFilter, setShowFilter] = useState(false);
    const [urlSearchParams] = useSearchParams();
    const {toggleDrawer} = useTempDrawer();
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const showFilter = useSelector((state) => state?.pagination?.showFilter);

    // const [filterChips, setFilterChips] = useState({});
    const dispatch = useDispatch();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchedValue, setSearchedValue] = useState({});
    const [filterValue, setFilterValue] = useState("");
    const [showMultipleQuote, setShowMultipleQuote] = useState(false);

    useEffect(() => {
        if (searchedValue?.customerName) {
            getCustomerListing(searchedValue?.customerName);
        } else {
            getCustomerListing();
        }
    }, [searchedValue]);
    useEffect(() => {
        if (searchedValue?.companyName) {
            getCompanyListing(searchedValue?.companyName);
        }
    }, [searchedValue]);
    const {toggleDilog} = useToggleDilog();

    const navigate = useNavigate();
    const tab = urlSearchParams.get("tab");
    const OrderId = urlSearchParams.get("orderId");
    const CustomerId = urlSearchParams.get("customerId");
    const CustomerName = urlSearchParams.get("customerName");

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            default:
                setBtnLebel("Create order");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
    }, [window.location.search]);

    useEffect(() => {
        setValue(tab);
        let search = `?tab=${tab}`;
        if (OrderId) {
            search = `?tab=${tab}&orderId=${OrderId}`;
        }
        // if(CustomerId){

        // }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    }, [tab]);
    useEffect(() => {
        getMarketList();
        getBrandList();
    }, []);
    useEffect(() => {
        // is user edits the url query then we need to redirect it to scheduled tab
        if (
            tab == null ||
            (tab !== "scheduled" && tab !== "history" && tab !== "canceled" && tab !== "rejected" && tab !== "draft" && tab !== "quotes")
        ) {
            setValue("scheduled");
            let search = `?tab=${"scheduled"}`;
            if (OrderId) {
                search = `?tab=${"scheduled"}&orderId=${OrderId}`;
            }
            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setSearch("");
        setValue(newValue);
        // setFilterChips({});
        setSearchedValue({});
        dispatch(resetState());
        let search = `?tab=${newValue}`;
        if (OrderId) {
            search = `?tab=${newValue}&orderId=${OrderId}`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Create order": {
                toggleDilog("CreateOrder")(e);
                dispatch(setShowFilter(false));
                handleFilter(false);
                break;
            }
            case "orderFilter": {
                dispatch(setShowFilter(!showFilter));
                handleFilter();
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleEditExpirationTime = async (orderId, expiryDateTime, row) => {
        const instance = NetworkManager(API.ORDER.EDITEXPIRATIONTIME(orderId));
        const payload = {expiry_time: expiryDateTime === "" ? row?.quote_expiry_time : expiryDateTime};
        const response = await instance.request(payload);

        if (response.success) {
            toggleDrawer("right", false)();
        }
    };  

    const handleEditOrder = (orderId) => {
        navigate(`/orders?tab=${value}&orderId=${orderId}&isEdit=${value==="scheduled"}`);
        toggleDilog("CreateOrder")();
    };

    const openEditDialog = () => {
        toggleDilog("CreateOrder")();
    };

    const handleReorder = (orderId) => {
        navigate(`/orders?tab=${value}&reOrder=reOrder&orderId=${orderId}`);
        toggleDilog("CreateOrder")();
    };

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.ORDER.BULKDELETE);
        const payload = selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.order_id;
        });

        const response = await instance.request({orderIds: payload.join(",")});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedCheckbox([]);
        // getUnplacedOrderList();
    };

    const handleMultipleQuotes = async () => {
        let customerSameOrNot = 0;
        const hashmap = {};
        const orderIds = []; // Array to store order_ids

        const expiredOrderSet = new Set();

        selectedCheckbox.forEach((row) => {
            const list = JSON.parse(row);
            if (new Date(list?.delivery_date) < new Date()) {
                expiredOrderSet.add(list.order_id);
            }

            // Collect all order_ids
            if (list.order_id) {
                orderIds.push(list.order_id);
            }

            if (list.customer === null) {
                customerSameOrNot = 1;
            } else {
                hashmap[list.customer.user_id] = 1;
                if (Object.keys(hashmap).length > 1) {
                    customerSameOrNot = 2;
                }
            }
        });

        if (expiredOrderSet?.size > 0) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Delivery date of orders has expired.",
                    variant: "warning",
                    alert: {
                        severity: "warning",
                        color: "warning",
                        variant: "filled"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            return;
        }

        if (customerSameOrNot === 1) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Orders should have customer data",
                    variant: "warning",
                    alert: {
                        severity: "warning",
                        color: "warning",
                        variant: "filled"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            return;
        }
        if (customerSameOrNot === 2) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Orders should belong to the same customer.",
                    variant: "warning",
                    alert: {
                        severity: "warning",
                        color: "warning",
                        variant: "filled"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            return;
        }

        if (customerSameOrNot === 0) {
            setShowMultipleQuote(true);
        }
    };

    const columnData = [
        {key: "order_number", label: "Order #"},
        {key: "source", label: "Sources"},
        {key: "created_at", label: "Created date"},
        {key: "created_time", label: "Created time"},
        {key: "delivery_date", label: "Delivery date"},
        {key: "delivery_time", label: "Delivery time"},
        {key: "customer", label: "Customer"},
        {key: "company_name", label: "Comapany"},
        {key: "delivery_address", label: "Address"},
        {key: "service_type", label: "Service"},
        {key: "kitchen_location.market.market_name", label: "Market"},
        {key: "brand.brand_name", label: "Brand"},
        {key: "kitchen", label: "Kitchen Location"},
        {key: "total_price", label: "Total"},
        {key: "no_of_guest", label: "Guest"},
        {key: "payment_by", label: "Payment"},
        {key: "payment_method", label: "Payment method"},
        {key: "order.status", label: "Status"}
    ];

    const csvBuilder = () => {
        new CsvBuilder("order_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);
                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();
        setSelectedCheckbox([]);
    };

    const TabsData = [
        {
            value: "scheduled",
            label: "Scheduled",
            comp: () => (
                <Scheduled
                    search={search}
                    openEditDialog={openEditDialog}
                    handleReorder={handleReorder}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setFilterValue={setFilterValue}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "history",
            label: "History",
            comp: () => (
                <History
                    search={search}
                    openEditDialog={openEditDialog}
                    handleReorder={handleReorder}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "canceled",
            label: "Canceled",
            comp: () => (
                <Canceled
                    search={search}
                    openEditDialog={openEditDialog}
                    handleReorder={handleReorder}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "rejected",
            label: "Declined",
            comp: () => (
                <Rejected
                    search={search}
                    openEditDialog={openEditDialog}
                    handleReorder={handleReorder}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "quotes",
            label: "Quotes",
            comp: () => (
                <Unplaced
                    search={search}
                    openEditDialog={openEditDialog}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                    showMultipleQuote={showMultipleQuote}
                    setShowMultipleQuote={setShowMultipleQuote}
                    handleEditExpirationTime={handleEditExpirationTime}
                />
            )
        },
        {
            value: "draft",
            label: "Drafts",
            comp: () => (
                <DraftTab
                    search={search}
                    openEditDialog={openEditDialog}
                    handleEditOrder={handleEditOrder}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                    showMultipleQuote={showMultipleQuote}
                    setShowMultipleQuote={setShowMultipleQuote}
                />
            )
        }
    ];

    const filterState = [
        {
            type: "dateRange",
            key1: "start_date",
            key2: "end_date",
            startLabel: "Created date",
            endLabel: "Created to"
        },
        {
            type: "dateRange",
            key1: "delivery_start_date",
            key2: "delivery_end_date",
            startLabel: "Delivery date",
            endLabel: "Delivery to"
        },
        {
            key: "customer_name",
            label: "Customer name",
            type: "select",
            getData: customers,
            freeSolo: true
        },
        {
            key: "company_name",
            label: "Company name",
            type: "select",
            freeSolo: true,
            getData: companyList
        },
        {
            key: "source",
            label: "Source",
            type: "multiselect",
            getData: () => [
                {id: "ADMIN", label: "Direct order"},
                {id: "Customer", label: "Marketplace"},
                {id: "EZcater", label: "EZcater"},
                {id: "EZorder", label: "EZorder"}
            ]
        },
        {
            key: "service_type",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "charged_by",
            label: "Payment",
            type: "multiselect",
            getData: () => [
                {id: "HOUSE_ACCOUNT", label: "House account"},
                {id: "CREDIT_CARD", label: "Credit card"}
            ]
        },
        {
            key: "market_id",
            label: "Market",
            type: "multiselect",
            getData: marketList
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: brandList
        },
        {
            key: "guest_count",
            label: "Guests",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "guestFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: "total_amount",
            label: "Total",
            type: "numberfieldWithDecimal",
            filterType: "Equals",
            filterKey: "amountFilter",
            getData: [
                {label: "Equals", value: "equal", id: "equal"},
                {label: "Greater than", value: "greater than", id: "_greater_than"},
                {label: "Less than", value: "less than", id: "_less_than"},
                {label: "Not equal", value: "not equal", id: "_not_equal"}
            ]
        },
        {
            key: `status`,
            attrib: `status${Date.now()}`,
            label: "Status",
            type: "select",
            getData: getTabsData(value)
        }
    ];

    const filteredFilterState =
        value === "draft" || value === "quotes" ? filterState.filter((filter) => filter.key !== "charged_by") : filterState;

    return (
        <>
            {!WithPermission({module: "Orders", permission: "read_permission"}) && navigate({pathname: "/settings", search: "tab=account"})}
            <Grid container>
                {/* <CreateOrder /> */}
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        showBadge={Object.keys(filterChips)?.length}
                        pageheader={<PageHeader title={heading} />}
                        btnTitle={WithPermission({module: "Orders", permission: ["create_permission"]}) && btnlabel}
                        handleClick={handleHeaderActions}
                        handleSearch={handleSearch}
                        filterType="orderFilter"
                        orderFilter="orderFilter"
                        selectedCheckbox={selectedCheckbox}
                        multipleQuote={value === "draft" || value === "quotes" ? "multipleQuote" : null}
                        handleMultipleQuote={WithPermission({module: "Orders", permission: ["update_permission"]}) && handleMultipleQuotes}
                        handleDeleteBulk={WithPermission({module: "Orders", permission: ["delete_permission"]}) && handleDeleteBulk}
                        csvBuilder={csvBuilder}
                    >
                        {/* {showFilter && ( */}
                        <Filter
                            filterState={filteredFilterState}
                            // setFilterState={handleFilters}
                            filterChips={filterChips}
                            setSearchedValue={setSearchedValue}
                            filterValue={filterValue}
                            showFilter={showFilter}
                        />
                        {/* )} */}
                        <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                            filterState={filteredFilterState}
                        />
                        <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default Orders;
