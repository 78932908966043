import React, {useEffect, useState} from "react";
import {Grid, Typography, Box, Stack, Button, Divider, Checkbox} from "@mui/material";
import Paid from "assets/PAID.svg";
import {getFormattedTimeinAMPM} from "utils/app-dates/dates";
import {FormatCurrency, FormatPhoneNumber, commaFormattedNumber, getUtensils, removeDecimal} from "utils/helperFunctions/helpers";
import {useStyles} from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import logo from "assets/images/logo.svg";
import logoDark from "assets/images/bitetimeDarkLogo.svg";
import {truncate} from "lodash";
import {API, NetworkManager} from "network/core";
import moment from "moment";
import KitchenPdfComponent from "./KitchenPdfComponent";

const PDFComponent = (props) => {
    const styles = useStyles();
    const {
        quoteListData,
        PriceParam,
        getColorOfStatus,
        showDietaryForItem,
        itemDetailSorted,
        metersToMiles,
        showDietaryForModifier,
        handleSalesTaxString,
        handleAdvanceString,
        subTotal,
        showHeading,
        getBackgroundOfStatus,
        handleDriverModal,
        showAssignDriverButton = true,
        handleUnassignDriverModal,
        secondaryDriver = null,
        printSelect,
        driverListing = [],
        imageUrl,
        zIndex
    } = props;
    // const PriceParam = "price";
    const finalTotalPrice = quoteListData?.order?.service_fee_unit_value
        ? quoteListData?.order?.total_price
        : quoteListData?.order?.total_price - quoteListData?.order?.service_fee_value;

    const roundToNearest5Minutes = (timestamp) => {
        const date = new Date(timestamp);
        const minutes = Math.round(date.getMinutes() / 5) * 5;
        date.setMinutes(minutes);
        return date;
    };

    function formatDateToAMPM(timestamp, order, hide) {
        const date = new Date(timestamp);
        const options = {
            timeZone: order?.order_timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            ...(!hide && {timeZoneName: "short"})
        };

        const formattedDateTime = date.toLocaleString("en-US", options);
        return formattedDateTime
            .toLowerCase()
            .replace(/\s*[a-z]{3}$/i, "")
            .replace(/\s/g, "");
    }

    const formatDateToAMPMWithRounding = (timestamp, order) => {
        // First round the timestamp to the nearest 5 minutes
        const roundedDate = roundToNearest5Minutes(timestamp);

        // Format the rounded date using the original formatDateToAMPM function
        return formatDateToAMPM(roundedDate, order);
    };

    const calculateTotalItemPrice = (orderItem) => {
        let totalPrice = parseFloat(orderItem?.price);

        orderItem?.orderItemsModifiers.forEach((row) => {
            totalPrice += row?.total_price;
        });
        if (orderItem?.packaging_price) {
            totalPrice += parseFloat(orderItem?.packaging_price);
        }
        return parseFloat(totalPrice)?.toFixed(2);
    };

    const isPartialAmountPaid = () => {
        if (
            (quoteListData?.order?.status === "Complete" ||
                quoteListData?.order?.status === "Cancelled" ||
                quoteListData?.order?.status === "Rejected" ||
                quoteListData?.order?.status === "Draft" ||
                quoteListData?.order?.status === "Quote Sent" ||
                quoteListData?.order?.status === "Quote Rejected") &&
            !quoteListData?.order?.remaining_amount_to_be_paid > 0
        ) {
            return false;
        }
        // if (quoteListData?.order?.remaining_amount_to_be_paid === 0) {
        //     return false;
        // } else if (
        //     Number(quoteListData?.order?.remaining_amount_to_be_paid) < Number(quoteListData?.order?.total_price)
        // ) {
        //     return true;
        // }
        // if (quoteListData?.order?.remaining_amount_for_refund > 0) {
        //     return true;
        // }
        if (quoteListData?.order?.advance_deposit_amount > 0 || quoteListData?.order?.remaining_amount_to_be_paid > 0) {
            return true;
        }

        // if(quoteListData?.order?.remaining_amount_to_be_paid !== quoteListData?.order?.total_price ) {
        //     return true;
        // }
        return false;
    };

    const partialAmountPaid = () => {
        if (quoteListData?.order?.remaining_amount_for_refund) {
            return FormatCurrency(Number(quoteListData?.order?.advance_deposit_amount + quoteListData?.order?.remaining_amount_for_refund));
        } else return FormatCurrency(Number(quoteListData?.order?.advance_deposit_amount));
        // return FormatCurrency(Number(quoteListData?.order?.total_price) - Number(quoteListData?.order?.remaining_amount_to_be_paid));
    };

    const handleRemainingAmount = () => {
        if (quoteListData?.order?.remaining_amount_for_refund) {
            return FormatCurrency(
                Number(
                    quoteListData?.order?.total_price -
                        (quoteListData?.order?.advance_deposit_amount + quoteListData?.order?.remaining_amount_for_refund)
                )
            );
        } else {
            return FormatCurrency(quoteListData?.order?.remaining_amount_to_be_paid);
        }
    };
    const statusChecks =
        quoteListData?.order?.status === "Cancelled" ||
        quoteListData?.order?.status === "Complete" ||
        quoteListData?.order?.status === "Draft" ||
        quoteListData?.order?.status === "New" ||
        quoteListData?.order?.status === "Accepted" ||
        quoteListData?.order?.status === "Active" ||
        quoteListData?.order?.status === "Needs Driver" ||
        quoteListData?.order?.status === "QUOTE_REJECTED" ||
        quoteListData?.order?.status === "Driver Assigned" ||
        quoteListData?.order?.status === "Driver assigned" ||
        quoteListData?.order?.status === "Quote Sent" ||
        quoteListData?.order?.status === "Rejected" ||
        quoteListData?.order?.status === "ON_THE_WAY";

    const isCustomer = quoteListData?.order?.customer !== null;
    const getStatusText = (status) => {
        switch (status) {
            case "Cancelled":
                return "Canceled!";
            case "Active":
                return "Accepted!";
            case "Needs Driver":
                return "Assign driver!";
            case "Needs driver ":
                return "Assign driver!";
            case "QUOTE_REJECTED":
                return "Quote declined!";
            case "Quote Sent":
                return "Quote sent!";
            case "Driver assigned":
                return "Active!";
            case "Driver Assigned":
                return "Active!";
            case "ON_THE_WAY":
                return "On the way!";
            default:
                return `${quoteListData?.order?.status}!`;
        }
    };
    const getMarkedText = (status) => {
        switch (status) {
            case "Cancelled":
                return "canceled";
            case "Active":
                return "accepted";
            case "Needs Driver":
                return "assign driver";
            case "Needs driver ":
                return "assign driver";
            case "QUOTE_REJECTED":
                return "quote declined";
            case "Quote Sent":
                return "quote sent";
            case "Driver assigned":
                return "active";
            case "Driver Assigned":
                return "active";
            default:
                return quoteListData?.order?.status.toLowerCase();
        }
    };

    const selectedDriver = driverListing.filter((driver) => driver?.id === Number(quoteListData?.order?.carrier_id));

    const isDriverAssigned =
        (quoteListData?.order?.status === "Accepted" ||
            quoteListData?.order?.status === "New" ||
            quoteListData?.order?.status === "new" ||
            quoteListData?.order?.status === "Needs Driver" ||
            quoteListData?.order?.status === "ON_THE_WAY" ||
            quoteListData?.order?.status === "Active" ||
            quoteListData?.order?.status === "NOT_ASSIGNED" ||
            quoteListData?.order?.status === "NOT_ACCEPTED") &&
        quoteListData?.order?.carrier_id === null;

    const paymentText = () => {
        if (quoteListData?.order?.payment_declined && quoteListData?.order?.charged_by === "CREDIT_CARD") {
            return {text: "DECLINED", color: "#ff0000"};
        }
        if (quoteListData?.order?.status === "Cancelled") {
            return {text: "CANCELED", color: "#ff0000"};
        } else if (quoteListData?.order?.status === "Rejected") {
            return {text: "REJECTED", color: "#ff0000"};
        } else if (
            // ["New", "Accepted", "Active", "Needs Driver", "Driver Assigned", "Complete"].includes(quoteListData?.order?.status) &&
            !quoteListData?.order?.is_payment_initiated
        ) {
            return {text: "UNPAID", color: "#FFC107"};
        } else if (
            quoteListData?.order?.status === "Complete" &&
            (quoteListData?.order?.is_full_refunded || quoteListData?.order?.is_partial_refunded) &&
            Number(quoteListData?.order?.total_price - quoteListData?.order?.remaining_amount_for_refund) > 0
        ) {
            return {text: "REFUNDED", color: "#2196F3"};
        } else if (Number(quoteListData?.order?.remaining_amount_to_be_paid) <= 0) {
            return {text: "PAID", color: "#19B93D"};
        } else if (
            Number(
                quoteListData?.order?.total_price -
                    (quoteListData?.order?.remaining_amount_for_refund + quoteListData?.order?.advance_deposit_amount)
            ) > 0 &&
            quoteListData?.order?.status !== "Cancelled" &&
            (quoteListData?.order?.remaining_amount_for_refund > 0 || quoteListData?.order?.advance_deposit_amount > 0) &&
            !quoteListData?.order?.is_full_refunded &&
            !quoteListData?.order?.is_partial_refunded
        ) {
            return {text: "PARTIAL PAYMENT", color: "#FFC107"};
        }
        // else if (
        //     ["New", "Accepted", "Active", "Driver Assigned", "ON_THE_WAY", "Complete"].includes(quoteListData?.order?.status) &&
        //     quoteListData?.order?.is_payment_initiated &&
        //     Number(quoteListData?.order?.remaining_amount_to_be_paid) <= 0 &&
        //     Number(quoteListData?.order?.total_price - quoteListData?.order?.remaining_amount_for_refund) <= 0 &&
        //     !quoteListData?.order?.is_full_refunded
        // ) {
        //     return {text: "PAID", color: "#19B93D"};
        // } else if (
        //     ["New", "Accepted", "Active", "Driver Assigned", "ON_THE_WAY", "Complete"].includes(quoteListData?.order?.status) &&
        //     Number(quoteListData?.order?.remaining_amount_to_be_paid) <= 0 &&
        //     Number(quoteListData?.order?.advance_deposit_percent) === 100
        // ) {
        //     return {text: "PAID", color: "#19B93D"};
        // }

        return {text: "", color: "#19B93D"};
    };

    const fullName = quoteListData?.order?.customer?.full_name ?? "";
    const lastName = quoteListData?.order?.customer?.last_name ?? "";
    const name = `${fullName} ${lastName}`.trim();

    const isPercent = quoteListData?.order?.service_fee_unit === "percent";
    const isUsd = quoteListData?.order?.service_fee_unit === "usd";
    const percentFee = (quoteListData?.order?.service_fee_unit_value * quoteListData?.order?.order_price) / 100;
    const showMinimum =
        quoteListData?.order?.apply_minimum_service_fee &&
        ((isPercent && percentFee < quoteListData?.order?.service_fee_value) ||
            (isUsd && quoteListData?.order?.service_fee_unit_value < quoteListData?.order?.service_fee_value));
    const feeText = isPercent
        ? `(${quoteListData?.order?.service_fee_unit_value}% with gratuity included)`
        : `($${quoteListData?.order?.service_fee_unit_value} with gratuity included)`;

    let cumulativeIndex = 0;
    const getAlternatingColor = () => {
        const color = cumulativeIndex % 2 === 0 ? "transparent" : "#F3F3F3";
        cumulativeIndex += 1;
        return color;
    };
    return (
        <>
            <Box
                id="divToPrint"
                sx={{
                    padding: "21px 32px 0px 32px",
                    borderRadius: "4px",
                    width: "972px",

                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    zIndex: zIndex
                }}
            >
                <table style={{display: "flex", flexDirection: "column"}}>
                    <tbody>
                        <Box sx={{mb: "30%", flex: 1}} id="content">
                            <Grid container>
                                {statusChecks && isCustomer}{" "}
                                <Grid item xs={12}>
                                    <Typography variant="subDetail1" fontSize="0.813em" color="#020A11" fontWeight={500}>
                                        <Stack
                                            sx={[
                                                styles?.titleContainer,
                                                {
                                                    overflow: PriceParam === "price" ? "auto" : "hidden"
                                                }
                                            ]}
                                        >
                                            <Grid item xs={12} py="12px" px={0}>
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        fontSize: "24px",
                                                        fontWeight: 600,
                                                        lineHeight: "31.2px",
                                                        paddingBottom: "16px",
                                                        color: "#020A11"
                                                    }}
                                                >
                                                    {quoteListData?.order ? (
                                                        <>
                                                            Catering{" "}
                                                            {quoteListData?.order?.charged_by === "HOUSE_ACCOUNT" ? "invoice" : "order"} #
                                                            {quoteListData?.order?.order_number}{" "}
                                                            {quoteListData?.order?.order_name && (
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            color: "rgba(190, 190, 190, 1)",
                                                                            fontSize: "24px",
                                                                            fontWeight: 400
                                                                        }}
                                                                    >
                                                                        |{" "}
                                                                    </span>
                                                                    <span style={{fontWeight: 400}}>
                                                                        {truncate(quoteListData?.order?.order_name, {length: 25})}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Typography>
                                                {PriceParam === "price" && (
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "20px",
                                                                fontWeight: 500,
                                                                lineHeight: "28px",
                                                                color: "#000",
                                                            }}
                                                        >
                                                            {FormatPhoneNumber(
                                                                quoteListData?.order?.address_details?.location_name
                                                                    ? `${truncate(
                                                                          FormatPhoneNumber(
                                                                              quoteListData?.order?.address_details?.location_name
                                                                          ),
                                                                          {length: 28}
                                                                      )}`
                                                                    : "-"
                                                            )}
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                fontSize: "20px",
                                                                lineHeight: "28px",
                                                                fontWeight: 500,
                                                                color: "#000"
                                                            }}
                                                        >
                                                            {quoteListData?.order?.address_details ? (
                                                                <>
                                                                    {quoteListData?.order?.address_details?.street_line && (
                                                                        <>{quoteListData?.order?.address_details?.street_line} </>
                                                                    )}
                                                                    {quoteListData?.order?.address_details?.apartment_name && (
                                                                        <>{quoteListData?.order?.address_details?.apartment_name} </>
                                                                    )}
                                                                    {quoteListData?.order?.address_details?.city && (
                                                                        <>{`${quoteListData?.order?.address_details?.city}, `}</>
                                                                    )}
                                                                    {quoteListData?.order?.address_details?.state && (
                                                                        <>
                                                                            {quoteListData?.order?.address_details?.state.includes("TN")
                                                                                ? "TN"
                                                                                : quoteListData?.order?.address_details?.state}{" "}
                                                                        </>
                                                                    )}
                                                                    {quoteListData?.order?.address_details?.pincode && (
                                                                        <>{quoteListData?.order?.address_details?.pincode}</>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Typography sx={{fontSize: "20px", lineHeight: "28px", fontWeight: 500, color: "#000"}}>
                                                    {PriceParam !== "price" && <span>Delivery on&nbsp;</span>}
                                                    {quoteListData?.order?.delivery_date ? (
                                                        <>
                                                            {new Date(quoteListData?.order?.delivery_date).toLocaleDateString("en-us", {
                                                                month: "long",
                                                                weekday: "long",
                                                                day: "numeric",
                                                                timeZone:
                                                                    quoteListData?.order?.order_timezone ||
                                                                    Intl.DateTimeFormat().resolvedOptions().timeZone
                                                            })}
                                                            &nbsp;at&nbsp;
                                                            {formatDateToAMPM(
                                                                quoteListData?.order?.delivery_date,
                                                                quoteListData?.order,
                                                                "hide"
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{mb: "6px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                                <Box sx={{width: "126px", height: "54px", position: "relative", bottom: PriceParam === "price" ? "16px" : "6px"}}>
                                                    {quoteListData?.order?.brand?.brand_logo ? (
                                                        <Box>
                                                            <img
                                                                src={imageUrl || quoteListData?.order?.brand?.brand_logo}
                                                                alt={quoteListData?.order?.brand?.brand_name}
                                                                style={styles?.image}
                                                                id="img"
                                                            />
                                                        </Box>
                                                    ) : null}
                                                    <Box>
                                                        <Typography sx={styles?.title}>
                                                            {quoteListData?.order?.brand?.brand_logo
                                                                ? ""
                                                                : quoteListData?.order?.brand?.brand_name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{minHeight: "max-content", minWidth: "max-content", mt: "4px"}}>
                                                    {PriceParam === "price" ? (
                                                        <Typography
                                                            sx={{
                                                                color: "#FF0000",
                                                                fontSize: "60px",
                                                                fontWeight: 700,
                                                                lineHeight: "33.89px",
                                                                textAlign: "right",
                                                                mb: "-8px"
                                                            }}
                                                        >
                                                            {moment
                                                                .tz(quoteListData?.order?.leave_by, quoteListData?.order?.order_timezone)
                                                                .format("h:mma")}
                                                        </Typography>
                                                    ) : (
                                                        <Typography sx={{fontSize: "20px", fontWeight: 700}}>
                                                            Bitetime {quoteListData?.order?.status === "Complete" && "catering"}{" "}
                                                            {quoteListData?.order?.status === "Quote Sent"
                                                                ? quoteListData?.order?.charged_by === "HOUSE_ACCOUNT"
                                                                    ? "invoice"
                                                                    : "quote"
                                                                : quoteListData?.order?.charged_by === "CREDIT_CARD" &&
                                                                  quoteListData?.order?.status === "Complete"
                                                                ? "receipt"
                                                                : quoteListData?.order?.charged_by === "HOUSE_ACCOUNT"
                                                                ? "invoice"
                                                                : "order"}
                                                            {/* {quoteListData?.order?.charged_by === "HOUSE_ACCOUNT" ? "quote" : "order"} */}
                                                            {/* admin & customer house acc -> invoice / credit card -> order / quote -> quote / template -> template */}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Stack>
                                    </Typography>
                                </Grid>
                                {!isCustomer && (
                                    <Grid item xs={12} mt={1} sx={{background: "#EEF7FF", borderRadius: "4px"}}>
                                        <Typography variant="subDetail1" fontSize="0.813em" color="#020A11" fontWeight={600}>
                                            <Stack sx={{display: "flex", flexDirection: "row", alignItems: "center", color: "#2196F3"}}>
                                                <Stack>
                                                    {" "}
                                                    <InfoOutlinedIcon sx={{fontSize: "1.213em"}} ml={1.5} />
                                                </Stack>
                                                <Stack ml={0.5}>
                                                    {" "}
                                                    {quoteListData?.order?.status}! This order doesn&lsquo;t have valid data!
                                                </Stack>
                                            </Stack>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <hr style={styles?.hr1} />
                            <Grid container sx={{pt: "14px"}}>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "210px", pl: "2px"}}
                                >
                                    <Typography sx={[styles?.subDetail, styles?.color]}>
                                        <Stack
                                            mb="12.12px"
                                            sx={{fontSize: "20px", fontWeight: 700, color: "rgba(0, 10, 18, 1)", lineHeight: "24.2px"}}
                                        >
                                            Order details
                                        </Stack>
                                    </Typography>

                                    <Grid
                                        container
                                        pb="12px"
                                        xs={12}
                                        sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            p={0}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "normal", mt: "2px"}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        color: "rgba(27, 28, 28, 1)",
                                                        lineHeight: "14px",
                                                        letterSpacing: "-0.5px"
                                                    }}
                                                >
                                                    ORDER DATE
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "15px",
                                                        fontWeight: 400,
                                                        color: "#000",
                                                        lineHeight: "20px",
                                                        pl: "7px",
                                                        letterSpacing: "-0.5px"
                                                    }}
                                                >
                                                    {quoteListData?.order?.created_at
                                                        ? new Date(quoteListData.order?.created_at).toLocaleDateString("en-us", {
                                                              month: "long",
                                                              weekday: "long",
                                                              year: "numeric",
                                                              day: "numeric",
                                                              timeZone:
                                                                  quoteListData.order.order_timezone ||
                                                                  Intl.DateTimeFormat().resolvedOptions().timeZone
                                                          })
                                                        : new Date().toLocaleDateString("en-us", {
                                                              month: "long",
                                                              weekday: "long",
                                                              year: "numeric",
                                                              day: "numeric",
                                                              timeZone:
                                                                  quoteListData.order.order_timezone ||
                                                                  Intl.DateTimeFormat().resolvedOptions().timeZone
                                                          })}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            <Box sx={{display: "flex", alignItems: "flex-start", justifyContent: "normal"}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(27, 28, 28, 1)",
                                                        letterSpacing: "-0.5px",
                                                        minWidth: "77px",
                                                        mt: "2px"
                                                    }}
                                                >
                                                    CUSTOMER
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: "15px",
                                                        fontWeight: 400,
                                                        color: "#000",
                                                        lineHeight: "21px",
                                                        letterSpacing: "-0.5px"
                                                    }}
                                                >
                                                    {truncate(name, {length: 30})}
                                                    {quoteListData?.order?.customer?.company?.company_name ? (
                                                        <>
                                                            <span style={{color: "rgba(190, 190, 190, 1)"}}> | </span>
                                                            {quoteListData?.order?.customer?.company?.company_name}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <br /> {quoteListData?.order?.customer?.phone}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex"}}>
                                            <Box sx={{display: "flex"}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        color: "rgba(27, 28, 28, 1)",
                                                        lineHeight: "14px",
                                                        minWidth: "77px",
                                                        mt: "2px"
                                                    }}
                                                >
                                                    KITCHEN
                                                </Typography>
                                                <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "#000"}}>
                                                    {quoteListData?.order?.kitchen_location ? (
                                                        <>
                                                            {`${quoteListData?.order?.kitchen_location?.address_details?.street_line}`}
                                                            <br />
                                                            {`${quoteListData?.order?.kitchen_location?.address_details?.city}, ${
                                                                quoteListData?.order?.kitchen_location?.address_details?.state?.includes(
                                                                    "TN"
                                                                )
                                                                    ? "TN"
                                                                    : quoteListData?.order?.kitchen_location?.address_details?.state
                                                            } ${quoteListData?.order?.kitchen_location?.address_details?.pincode}`}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            p={0}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            <Box sx={{display: "flex", alignItems: "flex-start"}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(27, 28, 28, 1)",
                                                        minWidth: "77px",
                                                        mt: "3px"
                                                    }}
                                                >
                                                    BRAND
                                                </Typography>
                                                <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "#000"}}>
                                                    {quoteListData?.order?.brand
                                                        ? truncate(quoteListData?.order?.brand?.brand_name, {length: 35})
                                                        : "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            p={0}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            <Box sx={{display: "flex", alignItems: "center"}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(27, 28, 28, 1)",
                                                        minWidth: "77px"
                                                    }}
                                                >
                                                    GUESTS
                                                </Typography>
                                                <Typography sx={{fontSize: "15px", fontWeight: 400, lineHeight: "20px", color: "#000"}}>
                                                    {new Intl.NumberFormat().format(quoteListData?.order?.no_of_guest)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} sx={{display: "flex", gap: "13px", minHeight: "210px"}}>
                                    <Divider
                                        orientation="vertical"
                                        sx={{height: "188px", ml: "4px", border: "0.7px solid rgba(230, 230, 230, 1)", mt: "6px"}}
                                    />
                                    <Box sx={{display: "flex", flexDirection: "column"}}>
                                        <Typography
                                            sx={{fontSize: "20px", fontWeight: 700, color: "rgba(0, 10, 18, 1)", lineHeight: "24.2px"}}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    minWidth: "585px",
                                                    position: "relative"
                                                }}
                                            >
                                                <Stack>Delivery details</Stack>
                                            </Box>
                                        </Typography>
                                        <Grid container xs={12}>
                                            <Grid item xs={6.5} sx={{display: "flex", flexDirection: "column", marginTop: "9px"}}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "normal",
                                                        mt: "2px",
                                                        maxWidth: "fit-content"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            width: "85px",
                                                            mt: "2px"
                                                        }}
                                                    >
                                                        DELIVERY DATE
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "9px"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.delivery_date
                                                            ? new Date(quoteListData.order.delivery_date).toLocaleDateString("en-us", {
                                                                  month: "long",
                                                                  weekday: "long",
                                                                  year: "numeric",
                                                                  day: "numeric",
                                                                  timeZone:
                                                                      quoteListData.order.order_timezone ||
                                                                      Intl.DateTimeFormat().resolvedOptions().timeZone
                                                              })
                                                            : new Date().toLocaleDateString("en-us", {
                                                                  month: "long",
                                                                  weekday: "long",
                                                                  year: "numeric",
                                                                  day: "numeric",
                                                                  timeZone:
                                                                      quoteListData.order.order_timezone ||
                                                                      Intl.DateTimeFormat().resolvedOptions().timeZone
                                                              })}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        mt: "4px",
                                                        maxWidth: "fit-content"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            width: "85px"
                                                        }}
                                                    >
                                                        ARRIVAL TIME
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "9px"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.delivery_date
                                                            ? formatDateToAMPM(
                                                                  quoteListData?.order?.delivery_date,
                                                                  quoteListData?.order,
                                                                  "hide"
                                                              )
                                                            : "-"}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{display: "flex", justifyContent: "normal", maxWidth: "fit-content"}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            mt: "4px",
                                                            width: "85px"
                                                        }}
                                                    >
                                                        LOCATION
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "10px",
                                                            width: "160px"
                                                        }}
                                                    >
                                                        {FormatPhoneNumber(
                                                            quoteListData?.order?.address_details?.location_name
                                                                ? `${truncate(
                                                                      FormatPhoneNumber(
                                                                          quoteListData?.order?.address_details?.location_name
                                                                      ),
                                                                      {length: 28}
                                                                  )}`
                                                                : "-"
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "normal",
                                                        mt: "3px",
                                                        maxWidth: "fit-content"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            pt: "3px",
                                                            width: "85px"
                                                        }}
                                                    >
                                                        ADDRESS
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "10px",
                                                            width: "220px"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.address_details ? (
                                                            <>
                                                                {quoteListData?.order?.address_details?.street_line && (
                                                                    <>
                                                                        {quoteListData?.order?.address_details?.street_line} <br />{" "}
                                                                    </>
                                                                )}
                                                                {quoteListData?.order?.address_details?.apartment_name && (
                                                                    <>
                                                                        {quoteListData?.order?.address_details?.apartment_name}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {quoteListData?.order?.address_details?.city && (
                                                                    <>{`${quoteListData?.order?.address_details?.city}, `}</>
                                                                )}
                                                                {quoteListData?.order?.address_details?.state && (
                                                                    <>
                                                                        {quoteListData?.order?.address_details?.state.includes("TN")
                                                                            ? "TN"
                                                                            : quoteListData?.order?.address_details?.state}{" "}
                                                                    </>
                                                                )}
                                                                {quoteListData?.order?.address_details?.pincode && (
                                                                    <>{quoteListData?.order?.address_details?.pincode}</>
                                                                )}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                        {/* {quoteListData?.order?.delivery_address} */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sx={{width: "250px", marginTop: "9px"}}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        mt: "3px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            minWidth: "60px"
                                                        }}
                                                    >
                                                        DRIVER
                                                    </Typography>
                                                    {PriceParam !== "price" ? (
                                                        <Typography
                                                            sx={{
                                                                fontSize: "15px",
                                                                fontWeight: 400,
                                                                lineHeight: "20px",
                                                                color: "#000",
                                                                pl: "1px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "14px",
                                                                minWidth: "max-content"
                                                            }}
                                                        >
                                                            {" "}
                                                            {quoteListData?.order?.carrier_name ? (
                                                                <>
                                                                    {quoteListData.order.carrier_name}
                                                                    {WithPermission({module: "Orders", permission: "update_permission"}) &&
                                                                        quoteListData?.order?.status !== "Cancelled" &&
                                                                        quoteListData?.order?.status !== "Complete" &&
                                                                        quoteListData?.order?.status !== "Draft" &&
                                                                        quoteListData?.order?.status !== "Quote Sent" &&
                                                                        quoteListData?.order?.status !== "ON_THE_WAY" &&
                                                                        quoteListData?.order?.status !== "QUOTE_REJECTED" &&
                                                                        quoteListData?.order?.status !== "Rejected" &&
                                                                        showAssignDriverButton && (
                                                                            <Typography
                                                                                onClick={() => handleDriverModal()}
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    fontSize: "11px",
                                                                                    fontWeight: 600,
                                                                                    lineHeight: "13.75px",
                                                                                    color: "#00AB3A",
                                                                                    height: "100%",
                                                                                    cursor: "pointer",
                                                                                    mt: "1px"
                                                                                }}
                                                                            >
                                                                                EDIT
                                                                            </Typography>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Unassigned
                                                                    {WithPermission({module: "Orders", permission: "update_permission"}) &&
                                                                        quoteListData?.order?.status !== "Cancelled" &&
                                                                        quoteListData?.order?.status !== "Complete" &&
                                                                        quoteListData?.order?.status !== "Draft" &&
                                                                        quoteListData?.order?.status !== "Quote Sent" &&
                                                                        quoteListData?.order?.status !== "ON_THE_WAY" &&
                                                                        quoteListData?.order?.status !== "QUOTE_REJECTED" &&
                                                                        quoteListData?.order?.status !== "Rejected" &&
                                                                        showAssignDriverButton && (
                                                                            <Typography
                                                                                onClick={() => handleDriverModal()}
                                                                                variant="outlined"
                                                                                className="assign"
                                                                                sx={{
                                                                                    fontSize: "11px",
                                                                                    fontWeight: 600,
                                                                                    lineHeight: "13.75px",
                                                                                    color: "#00AB3A",
                                                                                    height: "100%",
                                                                                    cursor: "pointer",
                                                                                    mt: "1px"
                                                                                }}
                                                                            >
                                                                                ASSIGN
                                                                            </Typography>
                                                                        )}
                                                                </>
                                                            )}
                                                        </Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Box>
                                                <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-start", mt: "4px"}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            width: "60px",
                                                            mt: "4px"
                                                        }}
                                                    >
                                                        CONTACT
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "2px",
                                                            minWidth: "max-content"
                                                        }}
                                                    >
                                                        {truncate(quoteListData?.order?.address_details?.contact_name ?? "-", {length: 30})}
                                                        {quoteListData?.order?.address_details?.contact && (
                                                            <>
                                                                <br />
                                                                {quoteListData?.order?.address_details?.contact}
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", mt: "6px"}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)",
                                                            width: "60px"
                                                        }}
                                                    >
                                                        DISTANCE
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            pl: "3px"
                                                        }}
                                                    >
                                                        {" "}
                                                        {quoteListData?.order?.location_distance > 0
                                                            ? `${parseFloat(metersToMiles(quoteListData?.order?.location_distance)).toFixed(
                                                                  1
                                                              )} miles`
                                                            : "0.0 miles"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{minHeight: "48px", mt: "2px", display: "flex", alignItems: "start"}}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    minWidth: "75px",
                                                    color: "rgba(27, 28, 28, 1)",
                                                    mt: "4px"
                                                }}
                                            >
                                                INSTRUCTIONS
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: 400,
                                                    lineHeight: "20px",
                                                    color: "#000",
                                                    ml: "13px",
                                                    maxWidth: "479px",
                                                    overflowWrap: "break-word",
                                                    wordBreak: "break-word"
                                                }}
                                            >
                                                {quoteListData?.order?.delivery_instructions?.length > 0
                                                    ? quoteListData?.order?.delivery_instructions
                                                    : "-"}
                                            </Typography>
                                        </Grid>{" "}
                                    </Box>
                                </Grid>
                            </Grid>
                            {PriceParam === "price" ? (
                                <Grid container xs={12}>
                                    <Box sx={{backgroundColor: "#fff", width: "905px"}}>
                                        <KitchenPdfComponent itemDetailSorted={itemDetailSorted} />

                                        {/* <Grid
                                            container
                                            sx={{
                                                backgroundColor: "#F3F3F3",
                                                "-webkit-print-color-adjust": "exact",
                                                "print-color-adjust": "exact",
                                                height: "29px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "4px 8px",
                                                pt: "6px"
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left"
                                                }}
                                            >
                                                <Typography sx={[styles?.detailTitle, styles?.color2]}>Prepared</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left"
                                                }}
                                            >
                                                <Typography sx={[styles?.detailTitle, styles?.color2]}>Finished</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left"
                                                }}
                                            >
                                                <Typography sx={[styles?.detailTitle, styles?.color2]}>Quantity</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={7}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left"
                                                }}
                                            >
                                                <Typography sx={[styles?.detailTitle, styles?.color2]}>Items</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "left"
                                                }}
                                            >
                                                <Typography sx={[styles?.detailTitle, styles?.color2]}>Packed out</Typography>
                                            </Grid>
                                        </Grid> */}

                                        {/* {itemDetailSorted.map((orderItem, index) => {
                                            let isTray = false;
                                            let isIndividual = false;
                                            let isCustom = false;
                                            if (orderItem?.item?.item_type === 0) {
                                                isCustom = true;
                                            }
                                            orderItem.orderItemsModifiers.forEach((mod) => {
                                                if (mod?.modifier?.modifier_name === "Tray packaging") isTray = true;

                                                if (mod?.modifier?.modifier_name === "Individual packaging") isIndividual = true;
                                            });
                                            const kitchenNotes = orderItem.kitchen_note ? orderItem.kitchen_note.split(",") : [];
                                            return (
                                                <Box
                                                    key={`${orderItem.order_item_id},${index}`}
                                                    sx={{borderBottom: "1px solid #f0f0f0", paddingBottom: "4px"}}
                                                >
                                                    <Grid
                                                        container
                                                        sx={{
                                                            paddingLeft: 0.5,
                                                            paddingTop: 1,
                                                            backgroundColor: getAlternatingColor(),

                                                            "-webkit-print-color-adjust": "exact",
                                                            "print-color-adjust": "exact"
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center",
                                                                paddingLeft: 1.1
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    height: "18px",
                                                                    width: "18px",
                                                                    borderRadius: "4px",
                                                                    border: "1px solid #ACACAC "
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center",
                                                                paddingLeft: 1.1
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    height: "18px",
                                                                    width: "18px",
                                                                    borderRadius: "4px",
                                                                    border: "1px solid #ACACAC "
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center",
                                                                paddingLeft: 1.7
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "17.5px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                {orderItem.quantity}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={PriceParam === "price" ? 6 : 7}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                                wordBreak: "break-word"
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                    lineHeight: "17.5px",
                                                                    color: "#000",
                                                                    display: "flex",
                                                                    gap: "4px",
                                                                    maxWidth: "360px",
                                                                    mb: "4px"
                                                                }}
                                                            >
                                                                {orderItem.item_name}
                                                            </Typography>
                                                            <Typography sx={{ml: "4px"}}>
                                                                {isTray && (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px"
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                height: "18px",
                                                                                width: "45px",
                                                                                backgroundColor: "#D9D9D9",
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                lineHeight: "18.62px",
                                                                                textAlign: "center",
                                                                                color: "#000"
                                                                            }}
                                                                        >
                                                                            TRAY
                                                                        </Typography>
                                                                        <span style={{fontWeight: "700", fontSize: "14px"}}>
                                                                            {" "}
                                                                            __________{" "}
                                                                        </span>
                                                                    </Box>
                                                                )}
                                                                {isIndividual && (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px"
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                height: "19px",
                                                                                width: "81px",
                                                                                backgroundColor: "#F4EE3B",
                                                                                fontSize: "14px",
                                                                                fontWeight: 700,
                                                                                lineHeight: "18.62px",
                                                                                textAlign: "center",
                                                                                color: "#000"
                                                                            }}
                                                                        >
                                                                            INDIVIDUAL
                                                                        </Typography>

                                                                        <span style={{fontWeight: "700", fontSize: "14px"}}>
                                                                            {" "}
                                                                            __________{" "}
                                                                        </span>
                                                                    </Box>
                                                                )}
                                                                {isCustom && (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            gap: "8px"
                                                                        }}
                                                                    >
                                                                        <span style={{fontWeight: "700", fontSize: "14px"}}>
                                                                            {" "}
                                                                            __________{" "}
                                                                        </span>
                                                                    </Box>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center"
                                                            }}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                                alignItems: "center",
                                                                paddingLeft: 1
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    height: "18px",
                                                                    width: "18px",
                                                                    borderRadius: "4px",
                                                                    border: "1px solid #ACACAC "
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {orderItem.orderItemsModifiers && orderItem.orderItemsModifiers.length > 0 && (
                                                        <Grid container>
                                                            {orderItem.orderItemsModifiers
                                                                .filter(
                                                                    (mod) => mod.modifier_group.display_name.toLowerCase() !== "packaging"
                                                                )
                                                                .map((mod, modIndex) => (
                                                                    <Grid
                                                                        className="bgColor"
                                                                        key={`${mod.order_item_modefier_id},${modIndex}`}
                                                                        container
                                                                        sx={{
                                                                            backgroundColor: getAlternatingColor(),
                                                                            height: "28px",
                                                                            marginTop: modIndex % 2 === 0 ? "0px" : "4px",
                                                                            // Ensure background color is printed
                                                                            "-webkit-print-color-adjust": "exact",
                                                                            "print-color-adjust": "exact"
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.6
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC"
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.5
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC"
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center"
                                                                            }}
                                                                        />
                                                                        <Grid
                                                                            item
                                                                            xs={PriceParam === "price" ? 6 : 7}
                                                                            sx={{display: "flex", alignItems: "center", paddingLeft: 1.5}}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "18.62px",
                                                                                    color: "#000"
                                                                                }}
                                                                            >
                                                                                <span style={{marginRight: "8px", color: "#000"}}>•</span>
                                                                                {mod.modifier_group.display_name}:
                                                                                {mod.quantity > 0 ? ` ${mod.quantity} x ` : ""}
                                                                                {mod.modifier_name}{" "}
                                                                                <span style={{fontWeight: "700"}}> __________ </span>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center"
                                                                            }}
                                                                        />
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.1
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC"
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                        </Grid>
                                                    )}

                                                    {kitchenNotes && kitchenNotes.length > 0 && (
                                                        <Grid container minHeight="max-content">
                                                            {kitchenNotes.map((mod, modIndex) => {
                                                                return (
                                                                    <Grid
                                                                        key={`${mod},${modIndex}`}
                                                                        container
                                                                        sx={{
                                                                            backgroundColor: getAlternatingColor(),

                                                                            "-webkit-print-color-adjust": "exact",
                                                                            "print-color-adjust": "exact"
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.6
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC "
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.5
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC "
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center"
                                                                            }}
                                                                        />
                                                                        <Grid
                                                                            item
                                                                            xs={PriceParam === "price" ? 6 : 7}
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.5,
                                                                                minHeight: "28px",
                                                                                height: "max-content"
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: "14px",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "18.62px",
                                                                                    color: "#000",
                                                                                    display: "inline-flex"
                                                                                }}
                                                                            >
                                                                                <span style={{marginRight: "8px", color: "#000"}}>•</span>
                                                                                <span style={{width: "450px"}}>{mod.trim()}</span>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center"
                                                                            }}
                                                                        />
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "left",
                                                                                alignItems: "center",
                                                                                paddingLeft: 1.1
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    height: "18px",
                                                                                    width: "18px",
                                                                                    borderRadius: "4px",
                                                                                    border: "1px solid #ACACAC "
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    )}
                                                    <Box sx={{pl: 29.5}}>
                                                        {orderItem.special_instruction && (
                                                            <Box
                                                                sx={{
                                                                    display: "-webkit-box",
                                                                    flexDirection: "row",
                                                                    marginBottom: "2px",
                                                                    width: "500px"
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: "#000",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "18.62px"
                                                                    }}
                                                                >
                                                                    Special instructions:&nbsp;{" "}
                                                                    {orderItem?.special_instruction.length > 0
                                                                        ? orderItem?.special_instruction
                                                                        : "-"}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                        {orderItem.kitchen_note && (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "18.62px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                Kitchen note: {orderItem.kitchen_note}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        })} */}

                                        {/* {quoteListData?.order?.tableware_cutlery === "true" ||
                                        quoteListData?.order?.tableware_cutlery === true ||
                                        quoteListData?.order?.tableware_plates === true ||
                                        quoteListData?.order?.serving_utensils === true ? (
                                            <Grid container padding="8px 12px" sx={{borderBottom: "1px solid #f0f0f0"}}>
                                                <Grid container>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={1} />
                                                    <Grid item xs={1} />
                                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                                lineHeight: "17.5px",
                                                                color: "#000"
                                                            }}
                                                            paddingLeft="220px"
                                                        >
                                                            Tableware
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "18.48px",
                                                                color: "#000"
                                                            }}
                                                            paddingLeft="220px"
                                                        >
                                                            Includes:&nbsp;
                                                            {getUtensils(quoteListData?.order)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : null} */}
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid container>
                                    <Grid
                                        container
                                        sx={{
                                            backgroundColor: "rgba(243, 243, 243, 1)",
                                            height: "30.4px",
                                            border: "1px solid #f0f0f0",
                                            borderRadius: "2.9px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "8.7px 11.6px ",
                                            width: "972px"
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(167, 167, 167, 1)"
                                                }}
                                            >
                                                {" "}
                                                Quantity
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={PriceParam === "price" ? 11 : 5}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 700,
                                                    lineHeight: "14px",
                                                    color: "rgba(167, 167, 167, 1)"
                                                }}
                                            >
                                                Items
                                            </Typography>
                                        </Grid>

                                        {PriceParam !== "price" && (
                                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={3}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(167, 167, 167, 1)"
                                                    }}
                                                >
                                                    {" "}
                                                    Dietary
                                                </Typography>
                                            </Grid>
                                        )}

                                        {PriceParam !== "price" && (
                                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={1.5}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(167, 167, 167, 1)"
                                                    }}
                                                >
                                                    {" "}
                                                    Price
                                                </Typography>
                                            </Grid>
                                        )}

                                        {PriceParam !== "price" && (
                                            <Grid item sx={{display: "flex", justifyContent: "flex-end", textAlign: "end"}} xs={1.5}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 700,
                                                        lineHeight: "14px",
                                                        color: "rgba(167, 167, 167, 1)"
                                                    }}
                                                >
                                                    {" "}
                                                    Total
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {itemDetailSorted?.map((orderItem, index) => {
                                        const itemTotal = orderItem?.orderItemsModifiers
                                            .map((item) => item.total_price)
                                            .reduce((partialSum, a) => partialSum + a, 0);
                                        const total = Number(orderItem.total_price) + Number(itemTotal);
                                        return (
                                            <Grid
                                                container
                                                key={orderItem.order_id}
                                                sx={{
                                                    padding: "0px 12px 8px 0px",
                                                    borderBottom: "1px solid rgba(230, 230, 230, 1)",
                                                    mt: "6px"
                                                }}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Grid item xs={1} textAlign="center" height="100%" paddingTop="8px">
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "17.5px",
                                                            color: "#000",
                                                            marginRight: "5px"
                                                        }}
                                                    >
                                                        {commaFormattedNumber(orderItem?.quantity)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={11} display="flex" flexDirection="column">
                                                    <Grid
                                                        container
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sx={{wordBreak: "break-word"}}
                                                        padding="8px 0px 4px 10px"
                                                    >
                                                        <Grid item xs={6} display="flex" alignItems="center" height="100%">
                                                            {PriceParam !== "price" && (
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 600,
                                                                        lineHeight: "17.5px",
                                                                        color: "#000",
                                                                        paddingBottom: "1px"
                                                                    }}
                                                                >
                                                                    {orderItem.item_name}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        {PriceParam !== "price" && (
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                className={styles.titleDivision}
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                                height="100%"
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "17.5px",
                                                                        color: "#000",
                                                                        textAlign: "right",
                                                                        mr: "19px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: "3px"
                                                                    }}
                                                                >
                                                                    {showDietaryForItem(orderItem)}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        {PriceParam !== "price" && (
                                                            <Grid
                                                                item
                                                                xs={1.5}
                                                                className={styles.titleDivision}
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                                height="100%"
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "17.5px",
                                                                        color: "#000",
                                                                        textAlign: "right",
                                                                        mr: "7px"
                                                                    }}
                                                                >
                                                                    {FormatCurrency(Number(orderItem?.price) / Number(orderItem?.quantity))}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            item
                                                            xs={1.5}
                                                            className={styles.titleDivision}
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                            height="100%"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                    lineHeight: "17.5px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                {FormatCurrency(Number(total).toFixed(2))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        // xs={PriceParam === "price" ? 5 : 7}
                                                        xs={12}
                                                        display="flex"
                                                        justifyContent="center"
                                                        sx={{wordBreak: "break-word"}}
                                                        paddingLeft={1.5}
                                                    >
                                                        {orderItem?.orderItemsModifiers && (
                                                            <Grid item xs={12}>
                                                                <Grid container display="flex" sx={{margin: 0}}>
                                                                    {orderItem?.orderItemsModifiers
                                                                        ?.sort((a, b) => {
                                                                            if (
                                                                                a.item_modifier_group_series_no !==
                                                                                b.item_modifier_group_series_no
                                                                            ) {
                                                                                return (
                                                                                    a.item_modifier_group_series_no -
                                                                                    b.item_modifier_group_series_no
                                                                                );
                                                                            } else {
                                                                                return a.series_no - b.series_no;
                                                                            }
                                                                        })
                                                                        ?.map((item) => {
                                                                            return (
                                                                                <React.Fragment key={item.order_item_id}>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={6}
                                                                                        sx={{paddingBottom: "4px", color: "#000"}}
                                                                                        display="flex"
                                                                                        pl="8px"
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                                fontWeight: 400,
                                                                                                lineHeight: "18.62px",
                                                                                                color: "#000",
                                                                                                display: "inline-flex"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{marginRight: "8px", color: "#000"}}
                                                                                            >
                                                                                                •
                                                                                            </span>
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: "14px",
                                                                                                fontWeight: 400,
                                                                                                lineHeight: "18.62px",
                                                                                                color: "#000"
                                                                                            }}
                                                                                        >
                                                                                            {item.modifier_group?.display_name}:&nbsp;
                                                                                            {item?.quantity > 0
                                                                                                ? `${commaFormattedNumber(
                                                                                                      item.quantity
                                                                                                  )} x `
                                                                                                : ""}{" "}
                                                                                            {item.modifier?.modifier_name}{" "}
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    {PriceParam !== "price" && (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={3}
                                                                                            className={styles.titleDivision}
                                                                                            display="flex"
                                                                                            justifyContent="flex-end"
                                                                                        >
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                    lineHeight: "18.62px",
                                                                                                    color: "#000",
                                                                                                    mr: "19px",
                                                                                                    display: "flex",
                                                                                                    alignItems: "flex-start",
                                                                                                    gap: "3px",
                                                                                                    mt: "3px"
                                                                                                }}
                                                                                            >
                                                                                                {showDietaryForModifier(item)}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    )}

                                                                                    {PriceParam !== "price" && (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={3}
                                                                                            className={styles.titleDivision}
                                                                                            display="flex"
                                                                                            justifyContent="flex-end"
                                                                                        >
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontSize: "14px",
                                                                                                    fontWeight: 400,
                                                                                                    lineHeight: "18.62px",
                                                                                                    color: "#000",
                                                                                                    mr: "108px"
                                                                                                }}
                                                                                            >
                                                                                                {item.modifier_group?.display_name ===
                                                                                                "Sides"
                                                                                                    ? ""
                                                                                                    : PriceParam !== "price" &&
                                                                                                      Boolean(item?.price)
                                                                                                    ? `+${FormatCurrency(item?.price)}`
                                                                                                    : ""}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            );
                                                                        })}
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>{" "}
                                                    {orderItem?.item?.customer_note && (
                                                        <Grid pl="10px">
                                                            <Typography
                                                                sx={{
                                                                    color: "#000",
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "18.62px",
                                                                    mt: "4px"
                                                                }}
                                                            >
                                                                Includes:&nbsp;
                                                                {orderItem?.item?.customer_note}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {orderItem?.special_instruction && (
                                                        <Grid item xs={7} pt="2px" pl="10px">
                                                            {orderItem?.item?.special_instruction && (
                                                                <Box sx={{display: "-webkit-box", flexDirection: "row"}}>
                                                                    <Typography
                                                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}
                                                                    >
                                                                        Includes:&nbsp;
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "18.62px"}}
                                                                    >
                                                                        &nbsp;{orderItem?.item?.special_instruction}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                            {orderItem?.special_instruction && (
                                                                <Box
                                                                    sx={{
                                                                        display: "-webkit-box",
                                                                        flexDirection: "row",
                                                                        marginBottom: "2px",
                                                                        width: "450px"
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: "#000",
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "18.62px"
                                                                        }}
                                                                    >
                                                                        Special instructions:&nbsp;{" "}
                                                                        {orderItem?.special_instruction.length > 0
                                                                            ? orderItem?.special_instruction
                                                                            : "-"}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    {quoteListData?.order?.tableware_cutlery === "true" ||
                                    quoteListData?.order?.tableware_cutlery === true ||
                                    quoteListData?.order?.tableware_plates === true ||
                                    quoteListData?.order?.serving_utensils === true ? (
                                        <Grid container padding="8px 12px">
                                            <Grid item xs={12} display="flex" justifyContent="space-between">
                                                {" "}
                                                <Typography
                                                    variant="h5"
                                                    sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "#000"}}
                                                    paddingLeft="75px"
                                                >
                                                    Tableware
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    sx={{fontSize: "14px", fontWeight: 600, lineHeight: "17.5px", color: "#000"}}
                                                >
                                                    INCLUDED
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} paddingTop="6px">
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        lineHeight: "18.48px",
                                                        color: "#000"
                                                    }}
                                                    paddingLeft="75px"
                                                >
                                                    Includes:&nbsp;
                                                    {getUtensils(quoteListData?.order)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                    {(quoteListData?.order?.tableware_cutlery === "true" ||
                                        quoteListData?.order?.tableware_plates === true) && (
                                        <hr style={{width: "905px", border: "0.6px solid rgba(230, 230, 230, 1)"}} />
                                    )}
                                </Grid>
                            )}
                            <Grid
                                container
                                padding="8px 0px 6.5px 0px"
                                borderBottom={PriceParam !== "price" ? 0 : 0}
                                sx={{display: "flex", flexDirection: "column"}}
                            >
                                {PriceParam === "price" &&
                                    (quoteListData?.order?.special_instructions?.length > 1 ||
                                        quoteListData?.order?.kitchen_note?.length > 0) && (
                                        <Grid container padding="8px 0px 8px 4px" sx={{display: "flex", flexDirection: "column"}}>
                                            {" "}
                                            {quoteListData?.order?.special_instructions?.length > 1 &&
                                                quoteListData?.order?.kitchen_note?.length > 0 && (
                                                    <Grid container sx={{display: "flex", flexDirection: "column"}}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                overflow: "hidden",
                                                                display: "flex",
                                                                alignItems: "flex-start",
                                                                flexDirection: "column",
                                                                gap: "3px"
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "14px",
                                                                    color: "#1B1C1C"
                                                                }}
                                                                paddingLeft={0}
                                                            >
                                                                SPECIAL INSTRUCTIONS
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "15px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20px",
                                                                    color: "#000",
                                                                    width: "100%",
                                                                    overflowWrap: "break-word",
                                                                    wordBreak: "break-word"
                                                                }}
                                                            >
                                                                {quoteListData?.order?.special_instructions?.length > 1
                                                                    ? quoteListData?.order?.special_instructions
                                                                    : "-"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container p="4px 0px" sx={{display: "flex", flexDirection: "column"}}>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "3px",
                                                                    width: "100%",
                                                                    maxWidth: "1290px",
                                                                    height: "auto",
                                                                    flex: 1
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "12px",
                                                                        fontWeight: 700,
                                                                        lineHeight: "14px",
                                                                        color: "#1B1C1C"
                                                                    }}
                                                                >
                                                                    KITCHEN NOTE
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "15px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "20px",
                                                                        color: "#000",
                                                                        width: "100%",
                                                                        wordBreak: "break-word"
                                                                    }}
                                                                >
                                                                    {quoteListData?.order?.kitchen_note}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {quoteListData?.order?.special_instructions?.length > 1 &&
                                                (quoteListData?.order?.kitchen_note?.length < 1 ||
                                                    quoteListData?.order?.kitchen_note === null) && (
                                                    <Grid container padding="0 16px 0 0">
                                                        <Grid item xs={12} sm={6} sx={{overflow: "hidden"}}>
                                                            <Typography sx={styles?.instructions} paddingLeft={0}>
                                                                Special instructions
                                                            </Typography>
                                                            <Typography sx={[styles?.detailTitle, styles?.color2]}>
                                                                {quoteListData?.order?.special_instructions?.length > 1
                                                                    ? quoteListData?.order?.special_instructions
                                                                    : "-"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {(quoteListData?.order?.special_instructions?.length < 1 ||
                                                quoteListData?.order?.special_instructions === null) &&
                                                quoteListData?.order?.kitchen_note?.length > 0 && (
                                                    <Grid container padding="0 16px 0 0">
                                                        <Grid item xs={12} sm={6}>
                                                            <Typography sx={styles?.instructions} paddingLeft={0}>
                                                                Kitchen notes
                                                            </Typography>
                                                            <Typography sx={[styles?.detailTitle, styles?.color2]} paddingLeft="5px">
                                                                {quoteListData?.order?.kitchen_note}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    )}
                                <Box className="page-break" />
                                {PriceParam !== "price" && (
                                    <Grid container>
                                        <Grid item xs={12} sm={6.4} className={styles.titleDivision}>
                                            <Grid container sx={{padding: "2px 0px"}}>
                                                <Grid item xs={12} sx={{overflow: "hidden"}} padding="0px 0 8px 2px">
                                                    <Typography
                                                        sx={{
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            lineHeight: "14px",
                                                            color: "rgba(27, 28, 28, 1)"
                                                        }}
                                                        paddingLeft={0}
                                                    >
                                                        SPECIAL INSTRUCTIONS
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "15px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                            color: "#000",
                                                            mt: "5px",
                                                            overflowWrap: "break-word",
                                                            wordBreak: "break-word"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.special_instructions?.length > 1
                                                            ? quoteListData?.order?.special_instructions
                                                            : "-"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={5.6} sx={{textAlign: "right", display: "flex", flexDirection: "column"}}>
                                            <Grid container spacing={4} display="flex" justifyContent="flex-end">
                                                <Grid item xs={6} spacing={2}>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px", color: "#000"}}
                                                    >
                                                        Order items
                                                    </Typography>
                                                    {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "20.44px",
                                                                color: "#000",
                                                                minWidth: "max-content",
                                                                ml: "-38px"
                                                            }}
                                                        >
                                                            Service fee {showMinimum ? "(Minimum)" : feeText}
                                                        </Typography>
                                                    )}{" "}
                                                    {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "20.44px",
                                                                color: "#000"
                                                            }}
                                                        >
                                                            Preferred pricing{" "}
                                                            {quoteListData?.order?.preferred_price_unit_type !== "DISCOUNT" &&
                                                            quoteListData?.order?.preferred_price_unit_type !== "" &&
                                                            quoteListData?.order?.preferred_price_unit_value
                                                                ? `(${Math.floor(
                                                                      Number(quoteListData?.order?.preferred_price_unit_value)
                                                                  )}%)`
                                                                : ""}
                                                        </Typography>
                                                    )}
                                                    {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                        quoteListData?.order?.price_type !== "Discount" && (
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000",
                                                                    mr: "1px"
                                                                }}
                                                            >
                                                                {quoteListData?.order?.price_type && quoteListData?.order?.price_type}{" "}
                                                                {quoteListData?.order?.price_type_unit === "percent" &&
                                                                    quoteListData?.order?.discount_or_fee_unit_value}
                                                                {quoteListData?.order?.price_type_unit === "percent" && "%"}{" "}
                                                                {quoteListData?.order?.discount_or_fee_description
                                                                    ? `(${quoteListData?.order?.discount_or_fee_description})`
                                                                    : null}
                                                            </Typography>
                                                        )}
                                                    {quoteListData?.order?.discount &&
                                                        quoteListData?.order?.discount_or_fee_unit_value > 0 &&
                                                        quoteListData?.order?.price_type === "Discount" && (
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000",
                                                                    minWidth: "max-content",
                                                                    ml: "-38px"
                                                                }}
                                                            >
                                                                {quoteListData?.order?.discount_or_fee_description
                                                                    ? quoteListData?.order?.discount_or_fee_description
                                                                    : "Discount or fee"}
                                                                &nbsp;
                                                                {quoteListData?.order?.discount_or_fee_unit_value &&
                                                                quoteListData?.order?.price_type_unit === "percent"
                                                                    ? `(${quoteListData.order.discount_or_fee_unit_value}%)`
                                                                    : null}
                                                            </Typography>
                                                        )}
                                                    <Typography
                                                        variant="h5"
                                                        sx={{fontSize: "14px", fontWeight: 400, lineHeight: "20.44px", color: "#000"}}
                                                        style={{paddingBottom: "1.902px"}}
                                                    >
                                                        Sales tax ({handleSalesTaxString()})
                                                    </Typography>
                                                    {quoteListData?.order?.advance_deposit_percent &&
                                                        quoteListData?.order?.advance_deposit_amount > 0 &&
                                                        (quoteListData?.order?.status === "Cancelled" ||
                                                            quoteListData?.order?.status === "Rejected") && (
                                                            <Typography
                                                                variant="h5"
                                                                color="#000"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                Advance payment
                                                            </Typography>
                                                        )}
                                                    <Typography
                                                        variant="h5"
                                                        // color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                        // color={
                                                        //     quoteListData?.order?.status === "Cancelled"
                                                        //         ? "#E10000"
                                                        //         : quoteListData?.order?.status === "Complete" &&
                                                        //           !quoteListData?.order?.is_payment_initiated
                                                        //         ? "#FFC107"
                                                        //         : (quoteListData?.order?.status !== "Complete" &&
                                                        //               quoteListData?.order?.is_payment_initiated) ||
                                                        //           isPartialAmountPaid()
                                                        //         ? "#FFC107"
                                                        //         : "#19B93D"
                                                        // }
                                                        // color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a" || quoteListData?.order?.status === "Complete" ? "green" : "yellow" }
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                            lineHeight: "20.44px",
                                                            color: paymentText(quoteListData?.order).color
                                                        }}
                                                    >
                                                        Total
                                                    </Typography>
                                                    {quoteListData?.order?.advance_deposit_percent > 0 &&
                                                        quoteListData?.order?.status === "Quote Sent" && (
                                                            <Typography
                                                                variant="h5"
                                                                color="#000"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                Deposit ({quoteListData?.order?.advance_deposit_percent}%)
                                                            </Typography>
                                                        )}
                                                    <>
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "20.44px",
                                                                color: "#000"
                                                            }}
                                                        >
                                                            Per person
                                                        </Typography>
                                                    </>
                                                    {(quoteListData?.order?.status === "Cancelled" ||
                                                        quoteListData?.order?.status === "Rejected") &&
                                                        quoteListData?.order?.charge_fee_amount !== null && (
                                                            <Typography
                                                                color="#E10000"
                                                                sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                            >
                                                                Cancelation fee
                                                            </Typography>
                                                        )}{" "}
                                                    {(quoteListData?.order?.is_full_refunded ||
                                                        quoteListData?.order?.is_partial_refunded) && (
                                                        <Typography
                                                            variant="h5"
                                                            color={paymentText(quoteListData?.order).color}
                                                            sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                        >
                                                            Refund amount
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={2.8} pr="12px" display="flex" flexDirection="column" alignItems="flex-end">
                                                    <Typography
                                                        variant="h5"
                                                        sx={{fontSize: "14px", fontWeight: 600, lineHeight: "20.44px", color: "#000"}}
                                                    >
                                                        {FormatCurrency(Number(subTotal).toFixed(2))}
                                                    </Typography>
                                                    {Number(quoteListData?.order?.service_fee_value) > 0 && (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "20.44px",
                                                                color: "#000"
                                                            }}
                                                        >
                                                            {quoteListData?.order?.service_fee_unit_value
                                                                ? FormatCurrency(quoteListData?.order?.service_fee_value)
                                                                : "$0.00"}
                                                        </Typography>
                                                    )}
                                                    {Number(quoteListData?.order?.preferred_price) > 0 && (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "20.44px",
                                                                color: "#000"
                                                            }}
                                                        >
                                                            {Number(quoteListData?.order?.preferred_price) > 0 && "-"}
                                                            {FormatCurrency(quoteListData?.order?.preferred_price)}
                                                        </Typography>
                                                    )}

                                                    {Number(quoteListData?.order?.price_type_value) > 0 &&
                                                        quoteListData?.order?.price_type !== "Discount" && (
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                {quoteListData?.order?.price_type === "Discount" &&
                                                                    Number(quoteListData?.order?.price_type_value) > 0 &&
                                                                    "-"}{" "}
                                                                {FormatCurrency(Number(quoteListData?.order?.price_type_value))}
                                                            </Typography>
                                                        )}
                                                    {quoteListData?.order?.discount &&
                                                        quoteListData?.order?.discount_or_fee_unit_value > 0 &&
                                                        quoteListData?.order?.price_type === "Discount" && (
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                -{FormatCurrency(quoteListData?.order?.price_type_value)}
                                                            </Typography>
                                                        )}
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "#000",
                                                            mt: "1px"
                                                        }}
                                                    >
                                                        {FormatCurrency(
                                                            quoteListData?.order?.tax_exempt ? "0.00" : quoteListData?.order?.sales_tax
                                                        )}
                                                    </Typography>
                                                    {quoteListData?.order?.advance_deposit_percent &&
                                                        quoteListData?.order?.advance_deposit_amount > 0 &&
                                                        (quoteListData?.order?.status === "Cancelled" ||
                                                            quoteListData?.order?.status === "Rejected") && (
                                                            <Typography
                                                                variant="h5"
                                                                color="#00ab3a"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "#000"
                                                                }}
                                                            >
                                                                {FormatCurrency(quoteListData?.order?.advance_deposit_amount)}
                                                            </Typography>
                                                        )}
                                                    <Typography
                                                        variant="h5"
                                                        // color={quoteListData?.order?.status === "Cancelled" ? "#E10000" : "#00ab3a"}
                                                        // color={
                                                        //     quoteListData?.order?.status === "Cancelled"
                                                        //         ? "#E10000"
                                                        //         : quoteListData?.order?.status === "Complete" &&
                                                        //           !quoteListData?.order?.is_payment_initiated
                                                        //         ? "#FFC107"
                                                        //         : (quoteListData?.order?.status !== "Complete" &&
                                                        //               quoteListData?.order?.is_payment_initiated) ||
                                                        //           isPartialAmountPaid()
                                                        //         ? "#FFC107"
                                                        //         : "#19B93D"
                                                        // }
                                                        sx={{
                                                            textDecoration:
                                                                quoteListData?.order?.status === "Cancelled" ||
                                                                quoteListData?.order?.status === "Rejected"
                                                                    ? "line-through"
                                                                    : "none",
                                                            ...styles?.pricing,
                                                            fontWeight: 700,
                                                            fontSize: "14px",
                                                            lineHeight: "20.44px",
                                                            color: paymentText(quoteListData?.order).color
                                                        }}
                                                    >
                                                        {FormatCurrency(finalTotalPrice)}
                                                        {/* {FormatCurrency(quoteListData?.order?.total_price)} */}
                                                    </Typography>

                                                    {quoteListData?.order?.advance_deposit_percent > 0 &&
                                                        quoteListData?.order?.status === "Quote Sent" && (
                                                            <Typography
                                                                variant="h5"
                                                                color="#00ab3a"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "20.44px",
                                                                    color: "rgba(0, 0, 0, 1)"
                                                                }}
                                                            >
                                                                {FormatCurrency(
                                                                    (quoteListData?.order?.advance_deposit_percent * finalTotalPrice) / 100
                                                                )}
                                                            </Typography>
                                                        )}

                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20.44px",
                                                            color: "#000"
                                                        }}
                                                    >
                                                        {quoteListData?.order?.no_of_guest > 0
                                                            ? FormatCurrency(
                                                                  finalTotalPrice / parseFloat(quoteListData?.order?.no_of_guest)
                                                              )
                                                            : FormatCurrency(0)}
                                                    </Typography>
                                                    {/* )} */}

                                                    {(quoteListData?.order?.status === "Cancelled" ||
                                                        quoteListData?.order?.status === "Rejected") &&
                                                        quoteListData?.order?.charge_fee_amount !== null && (
                                                            <Typography
                                                                sx={{fontSize: "14px", fontWeight: 700, lineHeight: "20.44px"}}
                                                                color="#E10000"
                                                            >
                                                                {FormatCurrency(parseFloat(quoteListData?.order?.charge_fee_amount))}
                                                            </Typography>
                                                        )}
                                                    {(quoteListData?.order?.is_full_refunded ||
                                                        quoteListData?.order?.is_partial_refunded) && (
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontSize: "14px",
                                                                fontWeight: 700,
                                                                lineHeight: "20.44px",
                                                                marginTop: "1px"
                                                            }}
                                                            color={paymentText(quoteListData?.order).color}
                                                        >
                                                            {quoteListData?.order?.advance_deposit_amount > 0
                                                                ? FormatCurrency(
                                                                      quoteListData?.order?.total_price -
                                                                          quoteListData?.order?.remaining_amount_for_refund -
                                                                          quoteListData?.order?.advance_deposit_amount
                                                                  )
                                                                : FormatCurrency(
                                                                      quoteListData?.order?.total_price -
                                                                          quoteListData?.order?.remaining_amount_for_refund
                                                                  )}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {isPartialAmountPaid() &&
                                                paymentText(quoteListData?.order).text === "PARTIAL PAYMENT" &&
                                                quoteListData?.order?.status !== "Cancelled" &&
                                                quoteListData?.order?.status !== "Rejected" && (
                                                    <Grid container display="flex" justifyContent="flex-end" pb="8px">
                                                        <Grid item xs={5} sx={{minWidth: "100px", padding: "0px 8px 4px 0px"}}>
                                                            {isPartialAmountPaid() && (
                                                                <>
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "20.44px",
                                                                            color: "#000"
                                                                        }}
                                                                    >
                                                                        Deposit (
                                                                        {removeDecimal(quoteListData?.order?.advance_deposit_percent)}%)
                                                                    </Typography>

                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "20.44px",
                                                                            color: "#000"
                                                                        }}
                                                                    >
                                                                        Remaining due
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={2.8}
                                                            sx={{
                                                                padding: "0px 12px 4px 32px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "flex-end"
                                                            }}
                                                        >
                                                            {isPartialAmountPaid() && (
                                                                <>
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "20.44px",
                                                                            color: "#000"
                                                                        }}
                                                                    >
                                                                        {partialAmountPaid()}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 400,
                                                                            lineHeight: "20.44px",
                                                                            color: "#000"
                                                                        }}
                                                                    >
                                                                        {handleRemainingAmount()}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {PriceParam !== "price" && (
                                <Grid container display="flex" flexDirection="column">
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        justifyContent="flex-end"
                                        padding="0px 12px 8px 0"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontSize: "26px",
                                                fontWeight: 900,
                                                lineHeight: "20.44px",
                                                color: paymentText(quoteListData?.order).color
                                            }}
                                        >
                                            {paymentText(quoteListData?.order).text}
                                        </Typography>
                                    </Grid>
                                    {quoteListData?.order?.charged_by === "HOUSE_ACCOUNT" && (
                                        <Typography
                                            sx={{
                                                textAlign: "right",
                                                mr: "14px",
                                                mt: "-10px",
                                                fontStyle: "italic",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "15px",
                                                color: "#000"
                                            }}
                                        >
                                            {" "}
                                            with house account
                                        </Typography>
                                    )}
                                    {quoteListData?.order?.charged_by === "CREDIT_CARD" && (
                                        <Typography
                                            sx={{
                                                textAlign: "right",
                                                mr: "14px",
                                                mt: "-10px",
                                                fontStyle: "italic",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "15px",
                                                color: "#000"
                                            }}
                                        >
                                            {" "}
                                            {quoteListData?.order?.card
                                                ? `with card ending in ${String(quoteListData.order.card).slice(-4)}`
                                                : ""}
                                        </Typography>
                                    )}
                                </Grid>
                            )}
                        </Box>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div className="footer-space" id="footer-space" />
                            </td>
                        </tr>
                    </tfoot>
                    <Box
                        id="footer"
                        sx={{
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: "10px"
                        }}
                    >
                        <img src={PriceParam === "price" ? logoDark : logo} alt="logo" height="19px" width="106px" />
                        <Typography sx={{fontSize: "14px", fontWeight: 400, lineHeight: "21px", color: "rgba(0, 10, 18, 1)", pr: "0px"}}>
                            {PriceParam === "price"
                                ? `Last edited ${moment
                                      .tz(quoteListData?.order?.updated_at, quoteListData?.order?.order_timezone)
                                      .format("dddd, MMMM D, YYYY [at] h:mma")}`
                                : "For help with your order, contact us at 1.844.986.2483 or hello@bitetime.com"}
                        </Typography>
                        {/* <div className="page-number" /> */}
                    </Box>
                </table>
            </Box>
        </>
    );
};

export default PDFComponent;
